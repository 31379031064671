import { ChoosePlanAlacarte } from '../app/shared/interfaces/choose-plan-alacarte';

export const hbomax: ChoosePlanAlacarte = {
  title: 'Gostaria de assinar a HBO Max?',
  description:
    'Comprando este adicional você tem acesso a todo o conteúdo HBO Max com pagamento mensal renovando todo dia X',
  left: {
    title: 'Assinar HBO Max',
    description: 'Assinatura à la carte apenas do HBO Max',
    url: '/alacarte/hbo',
    price: 'R$27,90/mês'
  },
  right: {
    title: 'Assinar pacote Multi + Full',
    description: 'Pacote com todos os conteúdos Multi +',
    url: '/planos/multi',
    price: 'R$119,90/mês'
  }
};

export const dgo: ChoosePlanAlacarte = {
  title: 'Gostaria de assinar a DGO?',
  description:
    'Assinando o pacote Multi + você tem acesso a todo o conteúdo multi + e parceiros, como Newco, DGO, HBO Max e Paramount+',
  left: {
    title: 'Assinar DGO',
    description: 'Assinatura à la carte apenas da DGO',
    url: '/alacarte/dtv',
    price: 'R$89,90/mês'
  },
  right: {
    title: 'Assinar pacote Multi + Full',
    description: 'Pacote com todos os conteúdos Multi +',
    url: '/planos/multi',
    price: 'R$119,90/mês'
  }
};

export const aLaCarte: any = {
  'hbo': {
    title: 'Assinatura à la carte HBO Max',
    image: '/alacarte_v3/image_logos/hbomax.svg',
    image_mobile: '/alacarte_v3/image_logos/hbomax_mobile.svg',
    description:
      'Mais de 12.000 horas de Filmes e Séries premiados e mais a Champions League para você!',
    price: 27.9,
    planId: 16,
  },
  'dtv': {
    title: 'Assinatura à la carte DGO',
    image: '/alacarte_v3/image_logos/dgo.svg',
    image_mobile: '/alacarte_v3/image_logos/dgo.svg',
    description:
      'Mais de 70 canais incluindo Globo, SporTV, ESPN, Disney e muito mais!',
    price: 89.9,
    planId: 15,
  }
};
