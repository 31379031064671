import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LibertadoresService {
  constructor(private http: Http) {}

  public getPhases(type?: string): Observable<any> {
    let url = 'v3/conmebol-libertadores/phases';

    if (type && type.trim().length > 0) url += `?type=${type}`;

    return this.http.get(url);
  }
  public getPhase(id_perfil: number, genre_id: number): Observable<any> {
    let payload: any = { id_perfil, genre_id };
    return this.http.post('v3/conmebol-libertadores/phase', payload);
  }

  public getMatches(id_perfil: number, type?: string): Observable<any> {
    let payload: any = { id_perfil };

    if (type && type.trim().length > 0) payload['type'] = type;

    return this.http.post(
      'v3/conmebol-libertadores/live-is-coming-matches',
      payload
    );
  }
  public getList(id_perfil: number, id: number = 10090): Observable<any> {
    let payload = {
      id_perfil,
      id,
      page: 1,
      size: 12,
      variance: 4
    };
    return this.http.post('v3/list', payload);
  }
}
