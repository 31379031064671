<div *ngFor="let phase of data!">
  <app-section *ngIf="phase && phase.data && phase.data.length > 0" [title]="phase.title" [properties]="{
    orientation: 'vertical',
    isLive: false,
    secondDescription: true,
    day: true,
    sportTitle: true,
    rentable: false,
    rentMovieTitle: false
  }" [data]="phase?.data ?? []" [isNSportsCard]="false" [isConmebolLibertadores]="true">
  </app-section>
</div>