import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Theme } from 'src/assets/theme/theme';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-section-libertadores',
  templateUrl: './section-libertadores.component.html',
  styleUrls: ['./section-libertadores.component.scss']
})
export class SectionLibertadoresComponent {
  private goToPageByClient: {[key: string]: any};
  public environment: any;

  constructor(public theme: Theme, public router: Router) {
    this.goToPageByClient = {
      'watch': () => window.open('https://play.watch.tv.br/welcome/find'),
      'vero': () => window.open('https://verointernet.com.br/minhavero'),
      'multilaser': () => this.router.navigateByUrl('planos/' + this.theme.clientRoute),
      'ligga': () => window.open('https://liggavc.com.br/para-voce/'),
      'giga': () => window.open('https://api.whatsapp.com/send?phone=552220410353'),
    };
    this.environment = environment;
   }

  public goTo(): void {
    this.goToPageByClient[this.theme.client]();
  }

  public getClienteName() {
    let clientname = '';

    switch (this.theme.client) {
      case 'vero':
        clientname = 'Vero Video';
        break;
      case 'giga':
        clientname = 'Giga+TV';
        break;
      case 'ligga':
        clientname = 'Ligga Play';
        break;
      case 'watch':
        clientname = 'Watch';
        break;
    }
    return clientname;
  }
}
