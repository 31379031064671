<div class="section">
  <div class="section--image" (click)="goWatch()">
    <div *ngIf="blocked" class="blocked"></div>
    <img [src]="channel?.white" alt="" />
  </div>
  <div class="section--content">
    <div class="section--content--shadow">
      <div class="section--content--shadow--cap"></div>
      <div class="section--content--shadow--fade"></div>
    </div>
    <div #guide class="section--content--programs" appScheduleSection>
       <div
        *ngFor="
          let program of channel?.programs
        "
        class="section--content--programs--container"
      >
        <app-tv-program-scheduled-card
          class="section--content--programs--program"
          [programs]="channel?.programs"
          [programId]="program.id"
          [censorship]="program.tvRating"
          [channelBrand]="channel?.white"
          [channelId]="channel?.id"
          (blockedChannelEmitter)="setBlockedChannel($event)"
          [profileAgeBracket]="profileAgeBracket"
        >
        </app-tv-program-scheduled-card>
      </div>
    </div>
  </div>
</div>
