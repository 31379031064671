<div class="section">
  <div class="section__texts">
    <div></div>
    <h3>Agora</h3>
  </div>
  <div class="section__cards">
    <swiper
      *ngIf="data?.length !== undefined"
      [slidesPerView]="'auto'"
      [navigation]="!checkMobile"
      (reachEnd)="onSwiper()"
    >
      <ng-template swiperSlide *ngFor="let program of data">
        <app-now-card
          *ngIf="program"
          [censorship]="program.censorship!"
          [program]="program"
        >
        </app-now-card>
      </ng-template>
    </swiper>
  </div>
</div>
