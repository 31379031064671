import { LibertadoresService } from './../../../services/libertadores.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { CheckDeviceService } from 'src/app/shared/services/check-device.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { Theme } from 'src/assets/theme/theme';
import { CardProperties } from 'src/app/shared/interfaces/card-default';
import { Device } from 'src/app/helpers/device';
import { CardData } from 'src/app/shared/interfaces/card';
import { HomeBnnerList } from 'src/app/shared/interfaces/matches';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-sulamericana-section',
  templateUrl: './sulamericana-section.component.html',
  styleUrls: ['./sulamericana-section.component.scss']
})
export class SulamericanaSectionComponent implements OnInit {
  public checkMobile: boolean = false;

  @Input() public hasExploreButton: boolean = true;
  @Input() public hasNavigation: boolean = true;
  @Input() public allowTouchMove: boolean = true;
  @Input() public viewMore: string = 'sulamericana';
  @Input() public data: any = [];
  @Input() public title: string = '';
  @Input() public description: string = '';

  public dataCards?: HomeBnnerList;
  public bannerImg: string =
    'https://watchbr-resources.s3.amazonaws.com/conmebol/sulamericana/images/sulamericana_bg.svg';
  public logoImg: string =
    'https://watchbr-resources.s3.amazonaws.com/conmebol/sulamericana/images/sulamericana_paramount_logo.svg';
  public cardProperties: CardProperties = {
    orientation: 'horizontal',
    isLive: true,
    secondDescription: true,
    day: true,
    sportTitle: true,
    rentable: false,
    rentMovieTitle: false
  };
  public isMobile: boolean = false;
  public id_perfil: number = 0;
  public matchesSulamericana?: CardData[];
  public orientation?: string;
  public isLoaded?: boolean = false;
  public isApp = environment.app;
  public screen_x = 0;

  constructor(
    public router: Router,
    public theme: Theme,
    public checkDevice: CheckDeviceService,
    public profileService: ProfileService,
    public sulamericanaService: LibertadoresService,
    private cd: ChangeDetectorRef
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isMobile = Device.isMobile();
  }

  @HostListener('window: resize', ['$event'])
  public onResize() {
    this.screen_x = this.isApp ? 768 : window.innerWidth;
  }

  ngOnInit(): void {
    const isLogged = parseInt(this.profileService.selectedProfile);

    if (window) this.screen_x = this.isApp ? 768 : window.innerWidth;

    if (isLogged) {
      this.id_perfil = isLogged;
      this.setData();
    }
  }

  public goTo(path: string) {
    this.router.navigateByUrl(path);
  }
  public viewMoreClick(): void {
    this.router.navigateByUrl(this.viewMore);
  }

  public setData() {
    this.dataCards = {
      PageCustomBanner: '',
      PageCustomDescription: this.description,
      PageCustomIcon: '',
      PageCustomTitle: this.title,
      current_page: 1,
      list: [],
      page_size: 12,
      pages: 1,
      total: 1
    };
    this.matchesSulamericana = this.data;
    if (this.data.length > 0)
      this.cardProperties.orientation = this.data[0].orientation;
    this.isLoaded = true;
    this.cd.detectChanges();
  }
}
