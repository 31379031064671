<div class="menu-steps-container">
  <div
    *ngFor="let menuStepAction of menuStepActions; let menuStepActionIndex = index"
    class="step"
    [ngClass]="{
      success: menuStepAction.success,
      activate: currentStepIndex >= menuStepActionIndex
    }"
    (click)="changeStep(menuStepAction, menuStepActionIndex)"
  >
    <div class="icon-container">
      <img
        [src]="menuStepAction.success ? menuStepAction.successIconSource : menuStepAction.defaultIconSource"
        [alt]="menuStepAction.iconAlternativeText"
      />
    </div>
    <span [innerHTML]="menuStepAction.label"></span>
  </div>
</div>
