import { Component, Input, OnInit } from '@angular/core';
import { MenuStepAction } from '@src/app/shared/interfaces/form-multi-step';
import { FormMultiStepService } from '@src/app/shared/services/form-multi-step.service';

@Component({
  selector: 'app-step-menu-action',
  templateUrl: './step-menu-action.component.html',
  styleUrls: ['./step-menu-action.component.scss']
})
export class StepMenuActionComponent implements OnInit {
  @Input() currentStepIndex: number;
  public menuStepActions: MenuStepAction[];

  constructor(private formMultiStepService: FormMultiStepService) {
    this.menuStepActions = [];
    this.currentStepIndex = 0;
  }

  ngOnInit(): void {
    this.menuStepActions = this.formMultiStepService.menuStepActionsValues;
  }

  public changeStep(stepAction: MenuStepAction, newStep: number) {
    this.formMultiStepService.changeStep(stepAction, newStep);
  }
}
