import { Component, Input, OnInit } from '@angular/core';
import { BoxSelector } from 'src/app/shared/interfaces/choose-plan-alacarte';

@Component({
  selector: 'app-selector-box',
  templateUrl: './selector-box.component.html',
  styleUrls: ['./selector-box.component.scss']
})
export class SelectorBoxComponent implements OnInit {
  @Input() side?: string;
  @Input() selectedSide?: string;
  @Input() data?: BoxSelector;

  constructor() {}

  ngOnInit(): void {}
}
