import { Component, OnInit } from '@angular/core';
import { Theme } from '@src/assets/theme/theme';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-modal-first-access',
  templateUrl: './modal-first-access.component.html',
  styleUrls: ['./modal-first-access.component.scss']
})
export class ModalFirstAccessComponent implements OnInit {

  private environment: any;
  constructor(public theme: Theme) { 
    this.environment = environment;
  }

  ngOnInit(): void {
  }
  public showModal: boolean = true;
  public cancellationConfirmed: boolean = false;
  public checkMobile: boolean = false;
  
  public closeModal(): void {
    this.showModal = false;
  }

  public getFirstAccessImage(){
    return this.theme.client !== 'ligga' ?
        './../../../../assets/first-access/first-access.png' :
        this.environment.bucket + 'bg/first-access-bg.svg';
  }
}
