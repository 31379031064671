import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { checkConnection } from '../interfaces/check-connection';
import { Http } from './http.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CheckConnectionService {
  private selectedProfile: string = ''

  public allowChange          : boolean = true
  public checkConnectionStatus: boolean = true
  public connectionState      : BehaviorSubject<number> = new BehaviorSubject<number>(0)

  public connectionStates: any = {
    default                    : 0,
    isActiveOnAnotherDevice    : 1,
    wasConnectedToAnotherDevice: 2,
  }

  constructor(private http: Http, private loginService: LoginService) {
    this.selectedProfile = localStorage.getItem('id_perfil')!;
  }

  public getConnectionState(): Observable<number> {
    this.checkConnection();
    return this.connectionState;
  }

  /**
   * CHECK CONNECTION
   */
  public checkConnection(): void {
    let connectionStateValue = this.connectionStates.default
    this.selectedProfile     = localStorage.getItem('id_perfil')!

    if (!this.selectedProfile || !this.loginService.token || !this.checkConnectionStatus) {
      return
    }

    this.checkConnectionValue().subscribe((response) => {
      if (response.success.conexao_valida === 0) {
        connectionStateValue = this.allowChange 
          ? this.connectionStates.isActiveOnAnotherDevice
          : this.connectionStates.wasConnectedToAnotherDevice
      }

      this.connectionState.next(connectionStateValue)
      this.allowChange = false
    })

  }

  /**
   * CONNECT MY PROFILE (CHOOSE)
   */
  public connectMyProfile(): Observable<checkConnection> {
    return this.http.post('/profile/choose', {
      id_perfil: localStorage.getItem('id_perfil'),
      token    : localStorage.getItem('deviceUuid')
    });
  }

  /**
   * CHECK CONNECTION VALUE
   * @private
   */
  private checkConnectionValue(): Observable<checkConnection> {
    return this.http.post('/profile/checkconnection', {
      id_perfil: localStorage.getItem('id_perfil'),
      token    : localStorage.getItem('deviceUuid')
    });
  }

  /**
   * DISCONNECT PROFILE
   * @param id_perfil
   */
  public disconnectMyProfile(id_perfil?: number): Observable<checkConnection> {
    return this.http.post('/profile/unconect', {
      id_perfil: id_perfil ?? localStorage.getItem('id_perfil') ?? this.selectedProfile,
      token    : localStorage.getItem('deviceUuid')
    });
  }

}
