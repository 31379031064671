export enum GeolocationModalCallAction {
  CloseModal = 'callCloseModal',
  NoAllowLocation = 'callNoAllowLocation',
  RequestLocationPermission = 'callRequestLocationPermission'
}

export enum GeolocationLocationPermission {
  prompt = 'prompt',
  granted = 'granted',
  denied = 'denied'
}
