import { DetailsService } from './../../services/details.service';
import { Component, Input, OnInit } from '@angular/core';
import { CardData } from '../../interfaces/card';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss']
})
export class FavoriteButtonComponent {
  public environment: any;
  @Input() public movieSerie: any = {};
  @Input() public idPerfil: number = 0;
  @Input() public type: string = '';
  @Input() public dataListFiltered: CardData[] = [];
  @Input() public dataList: CardData[] = [];
  @Input() public inBanner: boolean = false;


  constructor(private favoriteService: DetailsService, private router: Router) {
    this.environment = environment;
  }

  public toggleFavoriteState() {
    if (!this.movieSerie.type && this.movieSerie.tipo)
      this.movieSerie.type = this.movieSerie.tipo;

    this.movieSerie.favorite = !this.movieSerie.favorite;
    this.favoriteService
      .changeFavoriteState(
        this.idPerfil,
        this.movieSerie.id,
        this.movieSerie.type || this.type
      )
      .subscribe((result) => {
        if (result.action === 'deleted') {
          this.removeElementFromArray(this.dataList, this.movieSerie.id);
          this.removeElementFromArray(
            this.dataListFiltered,
            this.movieSerie.id
          );
        }
      });
  }

  private async removeElementFromArray(
    dataList: CardData[],
    movieSerieId: number
  ) {
    for (let index = 0; index < dataList.length; index++) {
      if (dataList[index].id === movieSerieId) {
        dataList.splice(index, 1);
        break;
      }
    }
  }

  public getFavoriteIcon(){
    let iconPath = environment.bucket + 'icons/app/';

    if(!environment.app){
      return !this.movieSerie.favorite
      ? 'assets/icons/star.svg'
      : 'assets/icons/star-black.svg'
    }

    if(this.router.url == '/home'){
      return !this.movieSerie.favorite
      ? iconPath + 'banner/add-my-list-home-icon-app.svg'
      : 'assets/icons/star-black.svg'

    }
    return !this.movieSerie.favorite
    ? iconPath + 'title/add-my-list-title-icon-app.svg'
    : iconPath + 'title/add-my-list-title-icon-app.svg'

  }
}
