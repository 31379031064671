import { Injectable } from '@angular/core';
import { Http } from './http.service';
import { map, Observable } from 'rxjs';
import { ProfileService } from './profile.service';
import { ContentCheck } from '../interfaces/content-check';

@Injectable({
  providedIn: 'root'
})
export class CheckContentService {

  constructor(
    private Http: Http,
    private profileService: ProfileService
  ) { }

  public checkParamount(
    entry_id: string | undefined,
    id: number | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/check', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "paramount",
        id: id
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }

  public startParamount(
    entry_id: string | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/start', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "paramount"
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }

  public checkGlobo(
    entry_id: string | undefined,
    id: number | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/check', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "GLOBO",
        id: id
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }

  public startGlobo(
    entry_id: string | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/start', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "GLOBO"
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }

  public checkVubiquitySony(
    entry_id: string | undefined,
    id: number | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/check', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "VUBIQUITY-SONY",
        id: id
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }

  public startVubiquitySony(
    entry_id: string | undefined
  ): Observable<ContentCheck> {
    return this.Http
      .post<any, ContentCheck>('/v3/content/limit/start', {
        id_perfis: this.profileService.selectedProfile,
        entry_id: entry_id,
        content_type: "VUBIQUITY-SONY"
      })
      .pipe(
        map((response: ContentCheck) => {
          return response;
        })
      );
  }
}
