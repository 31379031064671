<div class="container" *ngIf="theme.whatsappButton" [class.mini]='mini'>
  <a
    *ngIf="!isLargeButton"
    class="floating-button"
    [class.not-fixed]="notFixed"
    href="https://api.whatsapp.com/send/?phone=551131985805&amp;text=Oi%2C+gostaria+de+ajuda&amp;app_absent=0"
    target="_blank"
  >
    <svg
      width="35"
      height="44"
      style="margin-top: 7px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32V31.9345C0.0540893 31.8339 0.100531 31.7294 0.138933 31.6219C0.728742 29.4632 1.29234 27.2966 1.91623 25.149C2.16722 24.2833 2.20327 23.4917 1.66589 22.7295C1.60035 22.6345 1.57217 22.5106 1.52433 22.4018C0.148108 19.2824 -0.232647 16.045 0.450877 12.7027C1.21894 8.95286 3.09191 5.83539 6.10846 3.46829C9.39435 0.890817 13.1593 -0.24555 17.3345 0.0441124C20.0535 0.233507 22.5484 1.11101 24.8126 2.61831C27.0022 4.06709 28.7964 6.03852 30.0331 8.35453C31.1586 10.4281 31.7935 12.7321 31.889 15.0895C32.0424 18.6074 31.1459 21.8481 29.1871 24.7886C28.0694 26.4713 26.6326 27.9185 24.9581 29.0483C22.9252 30.4305 20.596 31.3158 18.1583 31.633C16.5694 31.8515 14.9566 31.8294 13.3743 31.5675C11.8198 31.3053 10.3459 30.7929 8.91333 30.1355C8.57745 29.9871 8.2038 29.9463 7.84381 30.0189C6.22314 30.3964 4.61755 30.8341 3.00541 31.247C2.0047 31.4967 1.00333 31.747 0 32ZM3.69941 28.3583C3.77898 28.3547 3.85822 28.3459 3.93665 28.332C5.26372 27.988 6.59604 27.6636 7.91328 27.2835C8.49064 27.117 8.98149 27.1681 9.50183 27.4637C12.3545 29.0831 15.4058 29.5864 18.6157 28.9264C22.5052 28.1263 25.487 25.9918 27.5192 22.5906C29.1576 19.8532 29.6877 16.8648 29.1576 13.7323C28.552 10.1535 26.7767 7.23586 23.8218 5.10599C20.8072 2.93352 17.4321 2.15104 13.7609 2.78542C10.4114 3.36409 7.66621 5.00376 5.56911 7.67101C3.76495 9.96864 2.79701 12.594 2.71444 15.5351C2.6489 17.8151 3.11943 19.9744 4.23221 21.9575C4.84954 23.0591 5.00486 24.1097 4.54284 25.3148C4.17388 26.2821 3.98252 27.3149 3.70138 28.3563L3.69941 28.3583Z"
        fill="white"
      ></path>
      <path
        d="M24.022 20.1999C23.9755 21.7727 22.9827 22.9333 21.3875 23.3567C20.4701 23.5998 19.5703 23.4733 18.6751 23.261C16.3604 22.7105 14.4075 21.4974 12.6531 19.9344C11.1045 18.5543 9.84235 16.9376 8.80298 15.1504C7.97134 13.7185 7.68234 12.2171 8.30951 10.6214C8.56747 9.94715 8.99661 9.35177 9.55466 8.89386C10.1707 8.39449 10.887 8.51442 11.581 8.71561C11.6937 8.74838 11.8018 8.90041 11.8543 9.02231C12.3209 10.1095 12.7875 11.1967 13.2239 12.299C13.3976 12.7381 13.1178 13.0999 12.8799 13.4354C12.6597 13.7454 12.4002 14.0252 12.1636 14.3247C11.9748 14.5619 11.9454 14.8273 12.098 15.0869C13.3904 17.3059 15.2018 18.9318 17.6115 19.8624C18.0545 20.0334 18.2766 19.9508 18.5761 19.5773C18.9366 19.1271 19.2931 18.6742 19.6653 18.2338C19.993 17.8465 20.1896 17.8111 20.6339 18.0248C21.5855 18.4835 22.5344 18.9423 23.486 19.3958C23.8628 19.5701 24.142 19.7962 24.022 20.1999Z"
        fill="white"
      ></path>
    </svg>
  </a>

  <a
    *ngIf="isLargeButton"
    class="large-button"
    href="https://api.whatsapp.com/send/?phone=551131985805&amp;text=Oi%2C+gostaria+de+ajuda&amp;app_absent=0"
    target="_blank"
  >
    <svg
      width="35"
      height="44"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32V31.9345C0.0540893 31.8339 0.100531 31.7294 0.138933 31.6219C0.728742 29.4632 1.29234 27.2966 1.91623 25.149C2.16722 24.2833 2.20327 23.4917 1.66589 22.7295C1.60035 22.6345 1.57217 22.5106 1.52433 22.4018C0.148108 19.2824 -0.232647 16.045 0.450877 12.7027C1.21894 8.95286 3.09191 5.83539 6.10846 3.46829C9.39435 0.890817 13.1593 -0.24555 17.3345 0.0441124C20.0535 0.233507 22.5484 1.11101 24.8126 2.61831C27.0022 4.06709 28.7964 6.03852 30.0331 8.35453C31.1586 10.4281 31.7935 12.7321 31.889 15.0895C32.0424 18.6074 31.1459 21.8481 29.1871 24.7886C28.0694 26.4713 26.6326 27.9185 24.9581 29.0483C22.9252 30.4305 20.596 31.3158 18.1583 31.633C16.5694 31.8515 14.9566 31.8294 13.3743 31.5675C11.8198 31.3053 10.3459 30.7929 8.91333 30.1355C8.57745 29.9871 8.2038 29.9463 7.84381 30.0189C6.22314 30.3964 4.61755 30.8341 3.00541 31.247C2.0047 31.4967 1.00333 31.747 0 32ZM3.69941 28.3583C3.77898 28.3547 3.85822 28.3459 3.93665 28.332C5.26372 27.988 6.59604 27.6636 7.91328 27.2835C8.49064 27.117 8.98149 27.1681 9.50183 27.4637C12.3545 29.0831 15.4058 29.5864 18.6157 28.9264C22.5052 28.1263 25.487 25.9918 27.5192 22.5906C29.1576 19.8532 29.6877 16.8648 29.1576 13.7323C28.552 10.1535 26.7767 7.23586 23.8218 5.10599C20.8072 2.93352 17.4321 2.15104 13.7609 2.78542C10.4114 3.36409 7.66621 5.00376 5.56911 7.67101C3.76495 9.96864 2.79701 12.594 2.71444 15.5351C2.6489 17.8151 3.11943 19.9744 4.23221 21.9575C4.84954 23.0591 5.00486 24.1097 4.54284 25.3148C4.17388 26.2821 3.98252 27.3149 3.70138 28.3563L3.69941 28.3583Z"
        fill="white"
      ></path>
      <path
        d="M24.022 20.1999C23.9755 21.7727 22.9827 22.9333 21.3875 23.3567C20.4701 23.5998 19.5703 23.4733 18.6751 23.261C16.3604 22.7105 14.4075 21.4974 12.6531 19.9344C11.1045 18.5543 9.84235 16.9376 8.80298 15.1504C7.97134 13.7185 7.68234 12.2171 8.30951 10.6214C8.56747 9.94715 8.99661 9.35177 9.55466 8.89386C10.1707 8.39449 10.887 8.51442 11.581 8.71561C11.6937 8.74838 11.8018 8.90041 11.8543 9.02231C12.3209 10.1095 12.7875 11.1967 13.2239 12.299C13.3976 12.7381 13.1178 13.0999 12.8799 13.4354C12.6597 13.7454 12.4002 14.0252 12.1636 14.3247C11.9748 14.5619 11.9454 14.8273 12.098 15.0869C13.3904 17.3059 15.2018 18.9318 17.6115 19.8624C18.0545 20.0334 18.2766 19.9508 18.5761 19.5773C18.9366 19.1271 19.2931 18.6742 19.6653 18.2338C19.993 17.8465 20.1896 17.8111 20.6339 18.0248C21.5855 18.4835 22.5344 18.9423 23.486 19.3958C23.8628 19.5701 24.142 19.7962 24.022 20.1999Z"
        fill="white"
      ></path>
    </svg>

    <p>Fale conosco</p>
  </a>
</div>
