import { Component, Input, OnInit } from '@angular/core';
import { Faq } from '../../interfaces/faq';
import { FaqService } from '../../services/faq.service';
import { CheckDeviceService } from '../../services/check-device.service';

@Component({
  selector: 'app-promo-faq',
  templateUrl: './promo-faq.component.html',
  styleUrls: ['./promo-faq.component.scss']
})
export class PromoFaqComponent implements OnInit {
  @Input() auxiliarText: string = '';
  public questions: Faq[] = [];

  constructor(
    private faqService: FaqService,
    private checkDeviceService: CheckDeviceService
  ) {}

  ngOnInit(): void {
    this.getFaqQuestions();
  }

  public getFaqQuestions(): void {
    this.faqService
      .getFaqQuestions(this.checkDeviceService.isMobile())
      .subscribe({
        next: (questions: any) => {
          this.questions = questions;
        }
      });
  }
}
