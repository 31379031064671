<div class="container" [ngClass]="{ 'mb-app': (checkMobile || environment.app) && pageType != getPageType().default }">
  <div class="selectors" *ngIf="!checkMobile && pageType == getPageType().default">
    <mat-tab-group (selectedIndexChange)="seasonToggle($event)">
      <mat-tab *ngFor="let series of seriesSeasons; let i = index">
        <ng-template mat-tab-label
          ><a [class.active]="seasonSelectedIndex === i">{{
            series?.title!
          }}</a></ng-template
        >
      </mat-tab>
    </mat-tab-group>
    <!-- <a

      [ngClass]="i === seasonSelectedIndex ? 'active' : ''"
      (click)="seasonToggle(i)"
      >{{ series?.title }}
    </a> -->
  </div>
  <div class="dropdown" *ngIf="checkMobile && pageType == getPageType().default">
    <div class="dropdown__toggle" (click)="dropdownToggle()">
      <p>{{ seasonSelected }}</p>
      <a class="icon">
        <img
          src="{{ environment.imageService }}/icons/arrow-down.svg"
          alt="arrow-down"
        />
      </a>
    </div>

    <div
      class="dropdown__menu"
      [ngClass]="dropdownActive ? 'dropdown__active' : ''"
    >
      <ul>
        <li
          *ngFor="let series of seriesSeasons; let i = index"
          (click)="seasonToggle(i)"
        >
          {{ series?.title }}
        </li>
      </ul>
    </div>
  </div>
  <div class="selectors" *ngIf="pageType != getPageType().default">
    <h4 [class.px-app]="checkMobile || environment.app" *ngIf="pageType == getPageType().chapters" class="chapters-title">Capítulos:</h4>
    <h4 [class.px-app]="checkMobile || environment.app" *ngIf="pageType == getPageType().classes" class="chapters-title">Aulas:</h4>
    <mat-tab-group (selectedIndexChange)="episodePageToggle($event)" *ngIf="pageType != getPageType().default">
      <mat-tab *ngFor="let v of getEpisodesPages(); let i = index">
        <ng-template mat-tab-label>
          <a [class.active]="seasonSelectedIndex === i" [class.p-app]="checkMobile || environment.app">
            {{ (i * getPageTypeConfig().byPage) + 1 }}-{{ (i * getPageTypeConfig().byPage) + v }}
          </a>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <hr class="line" />
</div>
