import { Injectable } from '@angular/core';
import { Http } from './http.service';
import { Observable } from 'rxjs';
import { CieloCheckPaymentParams, CieloDefaultReturn } from '../interfaces/cielo';

@Injectable({
  providedIn: 'root'
})
export class CieloPaymentService {
  private readonly cieloCheckCardNumberEndpoint: string = 'v3/cielo/check-card-number';
  private readonly cieloCheckPaymentEndpoint: string = 'v3/cielo/make-payment';

  constructor(private http: Http) {}

  public checkCardNumber(cardNumber: string): Observable<CieloDefaultReturn> {
    return this.http.post(this.cieloCheckCardNumberEndpoint, { cardNumber });
  }

  public makePayment(payload: CieloCheckPaymentParams): Observable<CieloDefaultReturn> {
    return this.http.post(this.cieloCheckPaymentEndpoint, payload);
  }
}
