import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@src/environments/environment';
import { PromoCodeService } from '../../services/promo-code.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promo-code-header',
  templateUrl: './promo-code-header.component.html',
  styleUrls: ['./promo-code-header.component.scss']
})
export class PromoCodeHeaderComponent implements OnInit {
  @Input() public countDays: string | number = 7;
  @Input() public planId: string = '28';
  @Input() public cupomName: string = 'funcionario';
  @Input() public btnText: string = 'ATIVE AGORA';
  public environment = environment;
  public code: string = '';
  public errorMessage: string = '';
  public disableBtn: boolean = true;

  constructor(private promoCodeService: PromoCodeService, private router: Router) {}

  ngOnInit(): void {
    sessionStorage.removeItem('id_plano');
    sessionStorage.removeItem('cupom');
    sessionStorage.removeItem('cupomByUrl');
    sessionStorage.removeItem('pCodeType');
    sessionStorage.removeItem('pCode');
  }
  public sendCode() {
    if (this.disableBtn) return;

    const code = this.code.toUpperCase();
    this.disableBtn = true;

    this.promoCodeService.validateMGMCode(code).subscribe({
      next: (result) => {
        if (!result.success) {
          this.errorMessage = result.message;
          return;
        }
        sessionStorage.setItem('id_plano', this.planId);
        sessionStorage.setItem('cupom', this.cupomName);
        sessionStorage.setItem('cupomByUrl', 'true');
        sessionStorage.setItem('pCodeType', 'mgm_code');
        sessionStorage.setItem('pCode', code);
        return this.router.navigateByUrl('/login/voucher');
      },
      error: (error) => console.log(error),
      complete: () => {
        this.disableBtn = false
      }
    });
  }
}
