<footer [class.desktop]="!checkMobile" [class.padding-logged]="isLogged">
  <div class="footer-content">
    <div class="row first-row">
      <div class="copyright" *ngIf="!checkMobile || (!isLogged && isHome)">
        <p>
          ©{{currentYear()}} Watch Brasil. Todos os direitos reservados.
        </p>
        <p>
          ©{{currentYear()}} Warner Bros. Entertainment Inc. Todos os direitos reservados.
        </p>
        <p>
          Verifique A Classificação Indicativa. © 2024 WarnerMedia Direct Latin America, LLC. Todos os direitos reservados. Max é usado sob licença.
        </p>
        <p>
          ©{{currentYear()}} Globo Comunicação e Participações S.A. Todos os direitos reservados. Big Brother Brasil é usado sob licença. As marcas registradas GLOBO®, TV GLOBO®, GLOBO NEWS®, CANAL BRASIL® SPORTV®, MULTISHOW®, OFF®, GNT®, BIS®, GLOOBINHO®, GLOOB®, VIVA®, MODO VIAGEM®, PREMIERE® e COMBATE® são propriedades da Globo Comunicação e Participações S.A.
        </p>
        <p>
          ©{{currentYear()}} NBCUniversal. Todos os direitos reservados. As marcas Universal, Studio Universal e USA Network são propriedades da NBCUniversal.
        </p>
        <p>
          ©{{currentYear()}} Telecine Programação De Filmes Ltda. Todos os direitos reservados. As marcas registradas TELECINE® e MEGAPIX® são propriedades da Telecine Programação De Filmes Ltda.
        </p>
        <p>
          Paramount+ ©{{currentYear()}} Paramount. Todos os direitos reservados.
        </p>
      </div>
      <div class="apps-store" *ngIf="checkMobile">
        <h3>Para uma melhor experiência use o App</h3>
        <div class="icons">
          <a (click)="goToGooglePlay()">
            <img [src]="environment.bucket + 'icons/google-play.svg'" alt="google-play"/>
          </a>
          <a (click)="goToPlayStore()">
            <img [src]="environment.bucket + 'icons/app-store.svg'" alt="app-store"/>
          </a>
        </div>
      </div>

      <div class="brand">
        <div *ngIf="checkMobile">
          <div class="whatsapp">
            <app-whatsapp-button [mini]="true" [isLargeButton]="!checkMobile"></app-whatsapp-button>
          </div>
        </div>
        <div class="logo-copy">
          <img class="img"  [ngClass]="{'voa-logo': theme.client === 'voa'}"
           *ngIf="!['giga'].includes(theme.client)" src="{{ theme.logo }}" [alt]="theme.title"/>
          <img class="img-copy" *ngIf="['giga'].includes(theme.client)" src="{{ theme.logoCopy }}" [alt]="theme.title"/>
          <div style="margin-top: 13px">
            <p>{{ theme.footer.copyright }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row second-row">
      <div class="social-media" *ngIf="!checkMobile">
        <ul>
          <li class="social-icon" *ngIf="theme.socialMedia.linkFace">
            <a (click)="goToFacebook()">
              <img class="logo" [src]="environment.bucket + 'icons/facebook.svg'" alt="facebook" />
            </a>
          </li>
          <li class="social-icon" *ngIf="theme.socialMedia.linkInsta">
            <a (click)="goToInstagram()">
              <img class="logo" [src]="environment.bucket + 'icons/instagram.svg'" alt="instagram" />
            </a>
          </li>
          <li class="social-icon" *ngIf="theme.socialMedia.linkLinkedin">
            <a (click)="goToLinkedin()">
              <img class="logo" [src]="environment.bucket + 'icons/linkedin.svg'" alt="linkedin" />
            </a>
          </li>
          <li class="social-icon" *ngIf="theme.socialMedia.linkYoutube">
            <a (click)="goToYoutube()">
              <img class="logo" [src]="environment.bucket + 'icons/youtube.svg'" alt="youtube" />
            </a>
          </li>
          <li class="social-icon" *ngIf="theme.socialMedia.linkTwitter">
            <a (click)="goToTwitter()">
              <img class="logo" [src]="environment.bucket + 'icons/twitter.svg'" alt="twitter" />
            </a>
          </li>
          <li class="social-icon" *ngIf="theme.socialMedia.linkTiktok">
            <a (click)="goToTiktok()">
              <img class="logo" [src]="environment.bucket + 'icons/tiktok.svg'" alt="tiktok"/>
            </a>
          </li>
        </ul>
      </div>

      <div class="whatsapp" *ngIf="!checkMobile">
        <app-whatsapp-button [isLargeButton]="!checkMobile" ></app-whatsapp-button>
      </div>

      <div class="apps-store" *ngIf="!checkMobile">
        <div class="icons">
           <a (click)="goToGooglePlay()">
            <img [src]="environment.bucket + 'icons/google-play.svg'" alt="google-play" />
          </a>
          <a (click)="goToPlayStore()">
            <img [src]="environment.bucket + 'icons/app-store.svg'" alt="app-store" />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="links">
        <ul>
          <li *ngIf="!['vero','giga'].includes(theme.client)">
            <a (click)="goTo()" >{{ theme.footer.mainButtonText }}</a>
          </li>
          <li *ngIf="theme.client === 'giga'">
            <span >{{ theme.footer.mainButtonText }}</span>
          </li>
          <li>
            <a routerLink="/termos">Termos de uso e privacidade</a>
          </li>
          <li *ngIf="['vero', 'voa'].includes(theme.client)">
            <a (click)="goToFeedBack()" target="_blank">Enviar feedback</a>
          </li>
          <li *ngIf="environment.client === 'ligga'">
            <a (click)="goToLigga()">Enviar feedback</a>
          </li>
          <li *ngIf="theme.client === 'watch' && !profile.kids && profileService.selectedProfile && !checkMobile">
            <a routerLink="/feedback">Enviar feedback</a>
          </li>
          <li *ngIf="!['watch'].includes(theme.client)">
            <img [src]="theme.footer.streaming" alt="logo streaming by watch" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
