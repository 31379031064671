<div class="dropdown">
  <p class="favorite-title" *ngIf="checkMobile">Minha Lista</p>
  <div class="dropdown__button" (click)="toggleDropdown()">
    <p class="title">{{ itemSelected }}</p>
    <a class="icon">
      <img [ngClass]="active ? 'icon__active' : ''" src="assets/icons/arrow-down.svg" alt="arrow-down" />
    </a>
  </div>
  <div class="close" (click)="toggleDropdown()" [ngClass]="active ? 'close__active' : ''"></div>
  <div class="menu" [ngClass]="active ? 'menu__active' : ''">
    <ul>
      <li><a (click)="selectCategorie('Todos')">Todos</a></li>
      <li><a (click)="selectCategorie('Aventura')">Aventura</a></li>
      <li><a (click)="selectCategorie('Ação')">Ação</a></li>
      <li><a (click)="selectCategorie('Comédia')">Comédia</a></li>
      <li><a (click)="selectCategorie('Cursos')">Cursos</a></li>
      <li><a (click)="selectCategorie('Drama')">Drama</a></li>
      <li><a (click)="selectCategorie('Documentário')">Documentário</a></li>
      <li><a (click)="selectCategorie('Familia')">Familia</a></li>
      <li><a (click)="selectCategorie('Ficção Científica')">Ficção Científica</a></li>
      <li><a (click)="selectCategorie('Infantil')">Infantil</a></li>
      <li><a (click)="selectCategorie('Novelas')">Novelas</a></li>
      <li><a (click)="selectCategorie('Policial')">Policial</a></li>
      <li><a (click)="selectCategorie('Romance')">Romance</a></li>
      <li><a (click)="selectCategorie('Reality Show')">Reality Show</a></li>
      <li><a (click)="selectCategorie('Suspense')">Suspense</a></li>
      <li><a (click)="selectCategorie('Terror')">Terror</a></li>
      <li *ngFor="let categorie of categories"><a (click)="selectCategorie(categorie.title!)">{{categorie.title}}</a>
      </li>
    </ul>
  </div>
</div>
