<div *ngIf="isEPGListFirstLoading" class="loading">
  <app-loading></app-loading>
</div>

<div class="now">
  <app-most-watched-channels *ngIf="canLoadWatchedChannelsRecently"></app-most-watched-channels>
  <img *ngIf="!['watch'].includes(theme.client) && !isApp" src={{theme.streamingBy}} alt="logo streaming by watch" class="logo-streaming-by">

  <div class="now--day-channel" *ngIf="this.theme.header.americanet && this.isAmericaNet">
    <div class="title">
      <h3>Canais</h3>
    </div>
    <div class="cards">
      <app-card [properties]="cardProperties" [data]="{
          imageUrl:
            'https://watchbr-resources.s3.amazonaws.com/marketplace/xpeed.png'
        }" [route]="'/series/10027/curso'"></app-card>
    </div>
  </div>

  <div class="now--day-grid">
    <div class="grade">
      <h3>Grade de programação</h3>
    </div>
    <div #channelsFilter class="now--day-grid--channels" (selectstart)="(false)">
      <p (click)="openChannels()">{{ this.selectedCategoryName }}</p>
      <ul class="now--day-grid--channels--list">
        <ng-template ngFor let-category [ngForOf]="channelsCategories">
          <li>
            <a (click)="filterChannelsByCategory(category.genre_id, category.name, true)">{{
              category.name
              }}</a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
  <div class="now--schedule-guide">
    <div #timeline
      (triggerUpdateEPGList)="restartEPGList($event)"
      [appTimeline]="horizontalTimelineCurrentSlideX"
      [class.mobile]="isMobile"
      class="now--schedule-guide--timeline"
    >
      <div>
        <p class="counter" [class.mobile]="isMobile" (click)="goToNow()">
          {{
          timer
          .getHours()
          .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
          }}:{{
          timer
          .getMinutes()
          .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
          }}
        </p>
      </div>
    </div>
    <div #schedulesCarousel class="now--schedule-guide--schedules-wrapper">
      <div #next class="now--schedule-guide--schedules-wrapper--button-next" [class.hide]="!this.showRightButton" (click)="goNext()"></div>
      <div #prev class="now--schedule-guide--schedules-wrapper--button-prev" (click)="goBack()"></div>
      <div #schedules class="now--schedule-guide--schedules-wrapper--schedules">
        <ng-template ngFor let-schedule [ngForOf]="timesRange" let-i="index">
          <div class="now--schedule-guide--schedules-wrapper--schedules--schedule" [id]="'schedule-' + i">
            <p>
              <span>
                {{
                schedule
                .getHours()
                .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
                }}:{{
                schedule
                .getMinutes()
                .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
                }}
              </span>
            </p>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="category-name">{{ selectedCategoryName }}</div>

    <div *ngIf="!isUpdateEPGListLoading; else loadingEPG" class="search-results">
      <ng-template ngFor let-channel [ngForOf]="epgList">
        <div>
          <app-tv-program-scheduled-section
            [channel]="channel"
            [currentScheduleIndex]="currentScheduleIndex"
            [profileAgeBracket]="profileAgeBracket"
          ></app-tv-program-scheduled-section>
        </div>
      </ng-template>
    </div>

    <ng-template #loadingEPG>
      <div class="spinner">
        <app-loading></app-loading>
      </div>
    </ng-template>

  </div>
  <div *ngIf="isEPGListFirstLoading" class="spinner">
    <app-loading></app-loading>
  </div>
  <app-regional-channels-epg-notification *ngIf="hasGloboRegionalChannels"></app-regional-channels-epg-notification>
</div>
