<div class="background">
  <div class="content">
    <ng-container #watch *ngIf="theme.client === 'watch'">
      <div class="logo1">
        <img [class.active]="activeLogo1"
          [src]="environment.bucket + 'logos/logo-watch-2.svg'" />
      </div>
      <div class="logo2">
        <img [class.active]="activeLogo2"
          [src]="environment.bucket + 'logos/logo-watch.svg'" />
      </div>
    </ng-container>

    <ng-container #watch *ngIf="theme.client === 'vero'">
      <div class="logo2">
        <img [class.active]="activeLogo2" class="vero"
          [src]="environment.bucket + 'icons/app/header/logo-vero-app.svg'" />
      </div>
    </ng-container>
    <ng-container #multi *ngIf="theme.client === 'multilaser'">
      <img height="44px" [src]="environment.bucket + 'logos/logo-multi.svg'" />
    </ng-container>
    <ng-container #multi *ngIf="theme.client === 'giga'">
      <img height="44px" [src]="environment.bucket + 'logos/logo_giga.svg'" />
    </ng-container>
    <ng-container #multi *ngIf="theme.client === 'voa'">
      <img height="87 px" [src]="environment.bucket + 'logos/logo-voa.svg'" />
    </ng-container>
    <ng-container #ligga *ngIf="theme.client === 'ligga'">
      <img height="44px" [src]="environment.bucket + 'logos/logo_ligga.svg'" />
    </ng-container>
    <div class="progress-bar">
      <div class="bar" [class.active]="activeProgressBar" *ngIf="activeLogo2">
        <div [class.active]="activeProgressBar" class="fill"></div>
      </div>
    </div>
    <div class="copyright" [class.active]="activeLogo1 || activeLogo2">
      <div class="streaming-by" *ngIf="['giga','ligga','vero', 'voa'].includes(theme.client)">
        <svg width="102" height="19" viewBox="0 0 102 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M81.8672 2C81.0725 2 80.4266 2.64586 80.4266 3.44064C80.4266 4.23542 81.0725 4.88128 81.8672 4.88128C82.662 4.88128 83.3079 4.23542 83.3079 3.44064C83.3079 2.64586 82.662 2 81.8672 2ZM82.647 3.56948L81.5611 4.18857C81.4573 4.25717 81.3201 4.18355 81.3201 4.05973V2.82323C81.3201 2.69941 81.4573 2.62578 81.5611 2.69438L82.647 3.31348C82.739 3.37539 82.739 3.51091 82.647 3.57115V3.56948Z" fill="white"/>
          <path d="M81.8672 7.7224C81.8672 7.7224 81.8589 7.7224 81.8555 7.7224C80.793 7.72407 79.7992 8.0286 78.9592 8.55399C78.9425 8.5657 78.9241 8.57574 78.9073 8.58745C78.8856 8.60083 78.8638 8.61255 78.8404 8.62091C78.8053 8.63263 78.7701 8.63765 78.7316 8.63765C78.6865 8.63765 78.6413 8.62593 78.6011 8.60418C78.5693 8.58745 78.5375 8.57239 78.5074 8.55566C78.4121 8.50546 78.3184 8.45527 78.2263 8.40005C77.8281 8.16914 77.4533 7.90143 77.1086 7.60193C76.4008 6.98785 75.8119 6.23992 75.3835 5.39662C75.3735 5.37821 75.3651 5.35981 75.3551 5.3414C75.34 5.30459 75.3317 5.26276 75.3317 5.21926C75.3283 5.02516 75.4956 4.86621 75.6897 4.86621H76.8526C76.958 4.86621 77.0534 4.91306 77.117 4.98835C77.1354 5.02182 77.1555 5.05529 77.1755 5.08708C77.4617 5.55558 77.8164 5.9789 78.2263 6.34032C78.3351 6.43736 78.4489 6.52939 78.566 6.61807C79.4863 7.31078 80.6291 7.72072 81.8689 7.72072L81.8672 7.7224Z" fill="white"/>
          <g opacity="0.8">
          <path d="M86.6276 7.60192C86.2779 7.90477 85.8997 8.17416 85.4965 8.40674C85.4028 8.46196 85.3057 8.51383 85.2087 8.56402L85.1434 8.59748C85.0983 8.62091 85.0481 8.63597 84.9962 8.63597C84.9343 8.63597 84.8724 8.61421 84.8188 8.58075L84.7552 8.54059C83.9203 8.02357 82.9348 7.72407 81.8807 7.72239C81.8756 7.72239 81.8723 7.72239 81.8689 7.72239C80.6291 7.72239 79.4863 7.31245 78.566 6.61974C79.5566 6.11443 80.6793 5.82831 81.8689 5.82831C83.0586 5.82831 84.1813 6.11443 85.1719 6.61974C85.2823 6.67663 85.3911 6.73519 85.4982 6.7971C85.9014 7.02968 86.2795 7.29907 86.6293 7.60192H86.6276Z" fill="white"/>
          </g>
          <path d="M88.4045 5.21422C88.4045 5.25939 88.3962 5.3029 88.3794 5.34138C88.3694 5.35979 88.361 5.37819 88.351 5.3966C87.9226 6.2399 87.3337 6.98783 86.6259 7.6019C86.2762 7.29905 85.898 7.02966 85.4948 6.79709C85.3877 6.73518 85.279 6.67661 85.1685 6.61972C85.2806 6.53606 85.3894 6.44738 85.4948 6.35368C85.9098 5.98892 86.2695 5.56225 86.559 5.08873C86.579 5.05526 86.5991 5.02347 86.6175 4.99001C86.6811 4.91471 86.7765 4.86786 86.8819 4.86786H88.0565C88.2472 4.86786 88.4028 5.02347 88.4028 5.21422H88.4045Z" fill="white"/>
          <path d="M86.4921 11.1743V11.7967C86.4921 12.3221 86.0671 12.7471 85.5418 12.7471H84.5914C84.4107 12.7471 84.2634 12.8943 84.2634 13.075V17.2062C84.2634 17.3869 84.1162 17.5342 83.9355 17.5342H82.3626C82.1819 17.5342 82.0347 17.3869 82.0347 17.2062V13.075C82.0347 12.8943 81.8875 12.7471 81.7067 12.7471H80.7564C80.231 12.7471 79.806 12.3221 79.806 11.7967V11.1743C79.806 10.9936 79.9532 10.8463 80.1339 10.8463H86.1659C86.3466 10.8463 86.4938 10.9936 86.4938 11.1743H86.4921Z" fill="white"/>
          <path d="M99.3458 11.1776V17.1962C99.3458 17.3752 99.2019 17.5191 99.0229 17.5191H98.2348C97.9286 17.5191 97.6509 17.3953 97.4501 17.1945C97.2493 16.9937 97.1255 16.7159 97.1255 16.4097V15.6217C97.1255 15.4426 96.9816 15.2987 96.8025 15.2987H95.2264C95.0473 15.2987 94.9034 15.4426 94.9034 15.6217V16.4097C94.9034 17.0238 94.4065 17.5208 93.7924 17.5208H93.0043C92.8253 17.5208 92.6814 17.3769 92.6814 17.1978V11.1793C92.6814 11.0002 92.8253 10.8563 93.0043 10.8563H93.7924C94.0986 10.8563 94.3764 10.9801 94.5772 11.1809C94.7779 11.3817 94.9018 11.6595 94.9018 11.9657V12.7538C94.9018 12.9328 95.0457 13.0767 95.2247 13.0767H96.8009C96.9799 13.0767 97.1238 12.9328 97.1238 12.7538V11.9657C97.1238 11.6595 97.2476 11.3817 97.4484 11.1809C97.6492 10.9801 97.9269 10.8563 98.2331 10.8563H99.0212C99.2003 10.8563 99.3442 11.0002 99.3442 11.1793L99.3458 11.1776Z" fill="white"/>
          <path d="M88.8397 13.4097C88.6389 13.6105 88.5151 13.8882 88.5151 14.1944C88.5151 14.5006 88.6389 14.7784 88.8397 14.9791C89.0405 15.1799 89.3182 15.3037 89.6244 15.3037H90.7354C91.0416 15.3037 91.3194 15.4276 91.5202 15.6284C91.721 15.8291 91.8448 16.1069 91.8448 16.4131V17.1978C91.8448 17.3769 91.6992 17.5208 91.5219 17.5208L89.6228 17.5241C89.2329 17.5241 88.8598 17.4572 88.5117 17.335C87.5647 17.0004 86.8151 16.2491 86.4788 15.3021C86.3566 14.954 86.2897 14.5809 86.2897 14.1911C86.2897 13.8012 86.3566 13.4281 86.4788 13.08C86.6461 12.6065 86.9172 12.1815 87.2652 11.8352C87.6132 11.4871 88.0365 11.2161 88.5101 11.0487C88.8581 10.9266 89.2312 10.8597 89.6211 10.8597H91.5202C91.6992 10.8597 91.8431 11.0036 91.8431 11.1826V11.9707C91.8431 12.5848 91.3462 13.0817 90.7321 13.0817H89.6211C89.3149 13.0817 89.0371 13.2055 88.8363 13.4063L88.8397 13.4097Z" fill="white"/>
          <path d="M81.1077 17.2263C81.1077 17.3953 80.9689 17.5342 80.7999 17.5342H79.5048C79.0631 17.5342 78.6799 17.2765 78.4992 16.9034L78.4808 16.8632V16.8599H78.4791L78.4758 16.8532L78.3888 16.6624L77.7663 15.3038L77.4534 14.6211C77.3832 14.4671 77.164 14.4671 77.0937 14.6211L76.7808 15.3038L76.1584 16.6624L76.0714 16.8532L76.068 16.8599V16.8649L76.0496 16.9034C75.9593 17.0908 75.8187 17.2497 75.6447 17.3601C75.4707 17.4706 75.2649 17.5358 75.044 17.5358H73.7673C73.6084 17.5358 73.4678 17.4221 73.4444 17.2664C73.4411 17.238 73.4394 17.2112 73.4411 17.1845C73.4444 17.146 73.4561 17.1108 73.4728 17.0757L76.2805 11.4503C76.4646 11.0889 76.841 10.843 77.2744 10.843C77.7078 10.843 78.0826 11.0906 78.2683 11.4503L78.28 11.4738L81.0743 17.074C81.096 17.1192 81.1077 17.1694 81.1094 17.2196V17.228L81.1077 17.2263Z" fill="white"/>
          <path d="M75.4338 11.1642C75.4338 11.2144 75.4221 11.2646 75.3987 11.3115L72.5642 16.9419C72.5124 17.0372 72.4488 17.1226 72.3718 17.1979C72.171 17.3986 71.8916 17.5241 71.5837 17.5241C71.3595 17.5241 71.1504 17.4572 70.9763 17.3434C70.9111 17.3016 70.8509 17.2514 70.7956 17.1979C70.7136 17.1159 70.6451 17.0205 70.5915 16.9168L70.0527 15.8375C69.9758 15.6819 69.7532 15.6819 69.6746 15.8375L69.1191 16.9402C69.0672 17.0356 69.0019 17.1209 68.9267 17.1962C68.8714 17.2514 68.8112 17.2999 68.7459 17.3418C68.5719 17.4555 68.3628 17.5225 68.1386 17.5225C67.8307 17.5225 67.5529 17.397 67.3505 17.1962C67.2685 17.1142 67.1999 17.0188 67.1463 16.9151L64.3102 11.2445C64.2985 11.2194 64.2902 11.191 64.2902 11.1626C64.2902 10.9835 64.4357 10.838 64.6148 10.838H65.9115C66.1357 10.838 66.3449 10.9049 66.5189 11.0187C66.5841 11.0605 66.6444 11.1107 66.6996 11.1642C66.7816 11.2462 66.8502 11.3399 66.9037 11.4453L67.9512 13.5402C68.0298 13.6958 68.2507 13.6958 68.3293 13.5402L68.6171 12.9663L67.7755 11.2814C67.6734 11.0772 67.8223 10.8396 68.0482 10.8396H69.3567C69.4621 10.8396 69.5642 10.8547 69.6595 10.8815C69.8453 10.9333 70.0109 11.0337 70.1448 11.1659C70.2267 11.2479 70.2953 11.3416 70.3489 11.447L71.3963 13.5419C71.4733 13.6975 71.6958 13.6975 71.7745 13.5419L72.0104 13.0684L72.8202 11.447C72.9089 11.273 73.0428 11.1257 73.2051 11.0203C73.3808 10.9066 73.5882 10.8396 73.8125 10.8396H75.1092C75.2882 10.8396 75.4338 10.9852 75.4338 11.1642Z" fill="white"/>
          <path d="M100.89 11.3834C100.88 11.3784 100.88 11.3717 100.89 11.3684C100.964 11.3249 101.004 11.2563 101.004 11.1542C101.004 10.9199 100.855 10.8413 100.597 10.8413H100.193C100.174 10.8413 100.162 10.853 100.162 10.8714V11.9189C100.162 11.9373 100.174 11.949 100.193 11.949H100.613C100.837 11.949 101.022 11.862 101.022 11.6327C101.022 11.5206 100.982 11.4403 100.892 11.3868L100.89 11.3834ZM100.46 11.1073C100.46 11.1073 100.465 11.094 100.472 11.094H100.597C100.673 11.094 100.713 11.1241 100.713 11.181C100.713 11.2379 100.673 11.268 100.597 11.268H100.472C100.472 11.268 100.46 11.263 100.46 11.2563V11.1073ZM100.613 11.693H100.474C100.474 11.693 100.462 11.688 100.462 11.6813V11.524C100.462 11.524 100.467 11.5106 100.474 11.5106H100.614C100.688 11.5106 100.725 11.5407 100.725 11.5993C100.725 11.6578 100.686 11.693 100.613 11.693Z" fill="white"/>
          <path d="M101.998 11.9121L101.805 11.5039C101.805 11.5039 101.805 11.4922 101.812 11.4888C101.929 11.4386 102 11.3315 102 11.1927C102 10.9818 101.856 10.8396 101.64 10.8396H101.166C101.148 10.8396 101.136 10.8513 101.136 10.8697V11.9172C101.136 11.9356 101.148 11.9473 101.166 11.9473H101.404C101.422 11.9473 101.434 11.9356 101.434 11.9172V11.534C101.434 11.534 101.439 11.5223 101.446 11.5223H101.503C101.503 11.5223 101.514 11.5223 101.518 11.5323L101.688 11.9255C101.695 11.9406 101.707 11.9473 101.723 11.9473H101.974C101.996 11.9473 102.006 11.9356 101.996 11.9121H101.998ZM101.59 11.2947H101.446C101.446 11.2947 101.434 11.2897 101.434 11.283V11.1073C101.434 11.1073 101.439 11.0939 101.446 11.0939H101.59C101.658 11.0939 101.703 11.1358 101.703 11.196C101.703 11.2562 101.658 11.2964 101.59 11.2964V11.2947Z" fill="white"/>
          <path d="M0.00334715 16.2325L0.612399 15.7673C0.942023 16.4516 1.48247 16.7813 2.09152 16.7813C2.82607 16.7813 3.27449 16.3412 3.27449 15.8008C3.27449 15.446 3.0553 15.1415 2.61524 14.8972C2.49644 14.8303 2.27725 14.7282 1.96436 14.5843C1.65147 14.4404 1.42391 14.3133 1.27165 14.2213C0.65423 13.8247 0.341337 13.3344 0.341337 12.7421C0.341337 12.2686 0.510332 11.8721 0.848322 11.5592C1.18631 11.2379 1.63473 11.0773 2.18355 11.0773C2.95323 11.0773 3.55225 11.3902 3.9923 12.0076L3.43512 12.4978C3.1055 12.041 2.69054 11.8135 2.19192 11.8135C1.61633 11.8135 1.17795 12.1598 1.17795 12.6919C1.17795 13.0299 1.35531 13.3093 1.71003 13.5285C1.82046 13.5955 2.06475 13.7226 2.44457 13.9084C2.83276 14.0941 3.10382 14.2296 3.24772 14.3233C3.83167 14.678 4.12616 15.1599 4.12616 15.7606C4.12616 16.2843 3.93207 16.716 3.54221 17.0456C3.16238 17.3753 2.6805 17.5359 2.09654 17.5359C1.20974 17.5359 0.398226 17.0791 0 16.2341L0.00334715 16.2325Z" fill="white"/>
          <path d="M6.82339 11.9473H4.96445V11.186H9.46875V11.9473H7.60981V17.4321H6.82339V11.9473Z" fill="white"/>
          <path d="M14.1437 17.4321L12.4705 14.8537H11.5921V17.4321H10.814V11.186H12.8938C13.5113 11.186 13.9848 11.3717 14.3228 11.7348C14.6608 12.0979 14.8298 12.5296 14.8298 13.0282C14.8298 13.4431 14.7026 13.8146 14.4416 14.1526C14.1805 14.4906 13.8158 14.7098 13.3423 14.8035L15.1175 17.4321H14.1454H14.1437ZM11.5904 11.9372V14.1091H12.7232C13.6016 14.1091 14.0249 13.6272 14.0249 13.0098C14.0249 12.3924 13.62 11.9356 12.9005 11.9356H11.5904V11.9372Z" fill="white"/>
          <path d="M16.6117 17.4321V11.186H20.44V11.9306H17.3881V13.8832H20.2108V14.6278H17.3881V16.6909H20.4484V17.4354H16.6117V17.4321Z" fill="white"/>
          <path d="M26.037 17.4321L25.3777 15.7672H22.9766L22.3174 17.4321H21.4557L24.0007 11.186H24.3721L26.8987 17.4321H26.037ZM24.178 12.6233L23.2477 15.0243H25.1067L24.1763 12.6233H24.178Z" fill="white"/>
          <path d="M28.2423 17.4321V11.186H28.5803L31.3193 15.2101L34.0333 11.186H34.3796V17.4321H33.5932V13.1135L31.4047 16.3428H31.2106L29.022 13.1135V17.4321H28.2439H28.2423Z" fill="white"/>
          <path d="M36.4076 11.186H37.1856V17.4321H36.4076V11.186Z" fill="white"/>
          <path d="M43.405 11.186H44.183V17.4321H43.8116L39.9916 12.9094V17.4321H39.2136V11.186H39.5265L43.4066 15.7338V11.186H43.405Z" fill="white"/>
          <path d="M46.7112 16.6122C46.1106 16.0031 45.806 15.2351 45.806 14.3132C45.806 13.3912 46.1189 12.6065 46.7531 11.9974C47.3872 11.38 48.1485 11.0755 49.0437 11.0755C49.5674 11.0755 50.066 11.1859 50.5312 11.3968C50.9964 11.6076 51.3762 11.8954 51.6556 12.2417L51.0901 12.7404C50.558 12.1397 49.882 11.8351 49.0537 11.8351C48.361 11.8351 47.7771 12.0711 47.3119 12.5362C46.8468 13.0014 46.6108 13.6004 46.6108 14.3199C46.6108 15.0394 46.8384 15.6133 47.3036 16.0784C47.7687 16.5436 48.3426 16.7711 49.0286 16.7711C49.7983 16.7711 50.5496 16.4248 51.0834 15.8576V14.5558H48.9366V13.8296H51.8279V16.1956C51.0583 17.0824 50.1213 17.5224 48.9969 17.5224C48.0749 17.5224 47.3153 17.2179 46.7146 16.6088L46.7112 16.6122Z" fill="white"/>
          <path d="M58.3736 16.3612C58.3736 16.649 58.2732 16.9017 58.0657 17.1142C57.8633 17.3267 57.5805 17.4321 57.2174 17.4321H55.6764V13.6974H57.1521C57.4751 13.6974 57.7277 13.7928 57.9051 13.9802C58.0875 14.1676 58.1778 14.3851 58.1778 14.6328C58.1778 14.9708 57.9804 15.2753 57.6725 15.3907C58.1326 15.5179 58.3753 15.9111 58.3753 16.3612H58.3736ZM56.175 14.1676V15.2084H57.0702C57.4383 15.2084 57.6608 14.9507 57.6608 14.6629C57.6608 14.4002 57.4433 14.1676 57.0852 14.1676H56.175ZM57.1354 16.9569C57.3529 16.9569 57.5303 16.8966 57.6558 16.7695C57.788 16.6423 57.8532 16.4917 57.8532 16.3244C57.8532 15.9663 57.5855 15.6668 57.1153 15.6668H56.175V16.9552H57.1354V16.9569Z" fill="white"/>
          <path d="M61.8505 13.6974L60.5722 16.0868V17.4304H60.0669V16.0868L58.8036 13.6974H59.3641L60.3196 15.5865L61.29 13.6974H61.8505Z" fill="white"/>
          </svg>
      </div>
      <h3>{{ theme.splash.copyrightTitle }}</h3>
      <p>{{ theme.splash.copyrightSubtitle }}</p>
    </div>
  </div>
  <div class="fade"></div>
</div>
