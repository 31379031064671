import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit{
  @Input() public error: boolean | undefined;
  @Input() public disabled: boolean = false;
  @Input() public value: string | undefined;
  @Output() public checked = new EventEmitter();

  @Input() control: FormControl;

  constructor() {
    this.error = false;
    this.control = new FormControl('');
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this.control.setValue(value, { emitEvent: false });
    });
  }

  public onChange(changes: any): void {
    this.error = false;
    this.checked.emit(changes?.target.checked)
  }
}
