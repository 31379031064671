<div
  class="section-full"
  [class.full-image]="(isXpeed) && watchService.checkAppleDevice()
  "
>
<!-- watchService.checkAppleDevice() -->
  <div
    class="section-full__left"
    [class.xpeed]="isXpeed"
    *ngIf="!environment.app || (environment.app)"
  >
    <img src="{{ sectionLogoChannel }}" />
    <div class="section-full__left-content">
      <h3 *ngIf="isNovelas">{{sectionTitle}}</h3>
      <p *ngIf="!environment.app">
        {{ sectionDescription }}
      </p>

      <app-button
        *ngIf="sectionButtonText !== ''"
        [type]="'button'"
        [text]="sectionButtonText"
        [classList]="'btn-secondary'"
        (click)="goTo()"
      ></app-button>
    </div>
  </div>


  <div
    class="section-full__right"
    [class.xpeed]="isXpeed"
    *ngIf="!environment.app || (environment.app)"
  >
    <div class="section-full__right-content">
      <img src="{{ sectionBackgroundImg }}" />
    </div>
  </div>
  <div
    class="nsportsApp"
    *ngIf="environment.app"
    style="background-image: url('/assets/nsports/background-app.png')"
  >
    <img src="{{ sectionLogoChannel }}" />
    <div class="button">
      <app-button
        *ngIf="sectionButtonText !== ''"
        type="button"
        [text]="sectionButtonText"
        classList="btn-secondary"
        (click)="goTo()"
      ></app-button>
    </div>
  </div>
</div>
