import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  StepForm,
  StepInput
} from '@src/app/shared/interfaces/form-multi-step';
import { GenericObject, ToastifyStatus } from '@src/app/shared/interfaces/generic';
import { FormMultiStepService } from '@src/app/shared/services/form-multi-step.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss']
})
export class FormSliderComponent implements OnInit, OnDestroy {
  @Input() currentStepIndex: number;
  @Input() formValues: GenericObject;
  public toastifyStatus: ToastifyStatus;
  public formSliderSteps: StepForm[];
  public backendErrorMessage: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formMultiStepService: FormMultiStepService,
    private cd: ChangeDetectorRef
  ) {
    this.currentStepIndex = 0;
    this.formValues = {};
    this.formSliderSteps = [];
    this.toastifyStatus = 'error';
    this.backendErrorMessage = '';
  }

  ngOnInit(): void {
    this.formSliderSteps = this.formMultiStepService.formSteps;

    this.formMultiStepService.backendErrorMessageObservable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (newBackendErrorMessage: string) => {
          this.backendErrorMessage = newBackendErrorMessage;
        }
      });

    this.formMultiStepService.toastifyStatusObservable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (newToastifyStatus: ToastifyStatus) => {
          this.toastifyStatus = newToastifyStatus;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setStepFormValue(key: string) {
    if (key in this.formValues) {
      const input = this.formMultiStepService.getInputByName(
        key,
        true
      ) as FormControl;

      this.cd.detectChanges();

      this.formMultiStepService.setInputByName(
        key,
        'value',
        input?.value ?? '',
        this.formValues
      );

      this.cd.detectChanges();
    }
  }

  public isInputInvalid(inputName: string): boolean {
    const input = this.formMultiStepService.getInputByName(
      inputName,
      true
    ) as FormControl;

    if (input) {
      return input.invalid && input.touched;
    }

    return true;
  }

  public getErrorMessage(inputName: string): string {
    const input = this.formMultiStepService.getInputByName(
      inputName,
      true
    ) as FormControl;

    if (input) {
      for (const errorType in input.errors) {
        if (input.errors.hasOwnProperty(errorType)) {
          const errorConfig = this.getErrorConfig(inputName, errorType);

          return errorConfig ?? '';
        }
      }
    }

    return '';
  }

  public getErrorConfig(inputName: string, errorType: string): any {
    const inputsWithError =
      this.formMultiStepService.currentStepForm.inputs.find((inputs) => {
        return inputs.find((input) => input.name === inputName);
      });

    if (inputsWithError) {
      const input = inputsWithError.find((input) => input.name === inputName);

      return input?.errorMessage && input.errorMessage[errorType];
    }

    return null;
  }

  public getInputControl(formGroup: FormGroup, inputName: string) {
    return formGroup.get(inputName) as FormControl;
  }

  public getInputControlClasses({ width, isDisabled, classList }: StepInput) {
    let className = !!classList ? classList : '';

    className += !!isDisabled ? ' disabled' : '';
    className += !!width ? ' dynamic-width custom-width-' + width : '';

    return className;
  }
}
