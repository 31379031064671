import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { buildUrlImage } from 'src/utils/buildUrlImage';
import { MainStructure } from '../../interfaces/main-structure';
import { Movie } from '../../interfaces/movie';
import { CheckDeviceService } from '../../services/check-device.service';
import { Theme } from 'src/assets/theme/theme';
import { BannerSecondaryTypes } from '../../interfaces/banners';
import { LoginService } from '../../services/login.service';
import { environment } from '@src/environments/environment';
import { PlanAccessControlService } from 'src/app/shared/services/plan-access-control.service';
import { getCookie, setCookie } from '../../utils';
import { BannerToasterOptions } from '../../interfaces/generic';
@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss']
})
export class HomeCarouselComponent implements OnInit, OnChanges {
  public screen_x: number;
  public checkMobile: boolean = false;
  public bannerSecondaryTypes = BannerSecondaryTypes;
  public isLogged?: boolean;
  public isApp: boolean = false;
  public canShowGeolocationToaster: boolean = false;
  public canShowToasterHBOAcquired: boolean = true;
  public hboBannerToasterOptions: BannerToasterOptions = {
    icon: {
      name: 'Plus Icon',
      source: '/assets/icons/plus_icon.svg'
    },
    type: 'success',
    description: 'Sucesso! O pacote Max foi adicionado em sua assinatura.',
    button: {
      text: 'Veja como acessar',
      onClick: () => this.goToHBOLandingPage()
    },
    onCloseToaster: () => this.onCloseHBOBannerToaster()
  };

  @Input() main: MainStructure;
  @Input() movieList: Movie[];
  @Input() isMobile: boolean;
  @Input() idPerfil: number = 0;
  @Input() userIsInCoverageArea: boolean = true;

  public buildUrl: (
    cdnId: number | undefined,
    imageName: string | undefined,
    main: MainStructure
  ) => void;

  @HostListener('window: resize', ['$event'])
  public onResize() {
    this.screen_x = window.innerWidth;
  }

  constructor(
    private route: Router,
    public theme: Theme,
    public loginService: LoginService,
    private checkDeviceService: CheckDeviceService,
    private planAccessControlService: PlanAccessControlService
  ) {
    this.checkMobile = checkDeviceService.isMobile();
    this.screen_x = 0;
    this.main = <MainStructure>{};
    this.movieList = <Movie[]>[];
    this.buildUrl = buildUrlImage;
    this.isMobile = false;
    this.isLogged = false;
    this.isApp = environment.app;
  }

  ngOnInit(): void {
    if (window) {
      this.screen_x = window.innerWidth;
    }
    this.verifyIfIsLogged();
    this.checkShowGeolocationToaster();
    this.checkShowToasterHBOAcquired();
    this.editDuration();
    this.main.banners!.sort((a, b) => {
      if (a.order === 0) {
        return 1;
      } else if (b.order === 0) {
        return -1;
      } else {
        return a.order! - b.order!; 
      }
    });
  }

  public editDuration() {
    this.main.banners?.forEach(movie => {
      if (movie.duration) {
        const timeSeconds = this.extrairValorNumerico(movie.duration.toString());
        const hours = Math.floor(timeSeconds / 3600);
        const remainingSeconds = timeSeconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
  
        const hoursString = hours > 0 ? `${hours}h` : '';
        const minutesString = minutes > 0 ? `${minutes}min` : '';
        movie.time = `${hoursString} ${minutesString}`.trim();
      } else {
        movie.time = '0';
      }
    });
  }
  

  public extrairValorNumerico(str: string) {
    const matches = str.match(/\d+/);
  
    return matches ? parseInt(matches[0], 10) : 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userIsInCoverageArea']) {
      this.checkShowGeolocationToaster();
    }
  }

  private checkShowToasterHBOAcquired() {
    if (!!this.planAccessControlService.acquiredDateHBO) {
      const now = new Date();
      const hbo = new Date(this.planAccessControlService.acquiredDateHBO);
      hbo.setDate(hbo.getDate() + 1);

   
     if( localStorage.getItem('toasterHBOAcquiredIsClosed')){
      this.canShowToasterHBOAcquired = false;
     }else{
      this.canShowToasterHBOAcquired = now <= hbo && !!!getCookie('toasterHBOAcquiredIsClosed');
     }
      return;
    }

    this.canShowToasterHBOAcquired = false;
  }

  private onCloseHBOBannerToaster() {
    setCookie('toasterHBOAcquiredIsClosed', 'true');
    localStorage.setItem('toasterHBOAcquiredIsClosed', 'true');
  }

  private goToHBOLandingPage() {
    this.route.navigateByUrl('produtos-adicionais/max-tutorial/web');
  }

  private checkShowGeolocationToaster() {
    this.canShowGeolocationToaster = [
      ['watch', 'ligga'].includes(this.theme.client),
      !this.userIsInCoverageArea,
      !!!localStorage.getItem('showGeolocationToaster')
    ].every(i => i);
  }

  public displayMovie(id: number | undefined): Movie | undefined {
    let _movie;
    if (id) {
      for (let i = 0; i < this.movieList.length; i++) {
        if (this.movieList[i].id == id) {
          _movie = this.movieList[i];
          break;
        }
      }
    }
    return _movie;
  }

  public movieIndex(id: number): number {
    for (let i = 0; i < this.movieList!.length; i++) {
      if (this.movieList![i].id === id) return i;
    }
    return -1;
  }

  public checkClick(id: number, type: string, link_banner: string | undefined): void {
    this.isMobile ? this.goToDetails(id, type, link_banner) : '';
  }

  public goToDetails(id: number, type: string, link_banner: string | undefined): void {
    if (type !== 'banner') {
      this.route.navigateByUrl(`/details/${id}/${type}`);
    } else {
      if (link_banner && link_banner?.length > 0) {
        const isExternalLink = link_banner.includes('http' || 'https');

        if (this.isApp || !isExternalLink) {
          this.route.navigateByUrl(link_banner);
        } else {
          window.open(link_banner, '_blank');
        }
      }
    }
  }

  public verifyIfIsLogged() {
    if (this.loginService.token) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }
}
