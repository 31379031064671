<button class="button" type="button" (click)="click()">
  <img *ngIf="text.toLocaleLowerCase() === 'assistir'" class="icon" [src]="icon" alt="" />
  <span *ngIf="pageType == getPageType().default"
    >{{
      !loginService.token
        ? 'Assinar'
        : isKeepWatching
        ? (!resumido ? 'Continuar assistindo' : 'Continuar')
        : text
    }}
  </span>
  <span *ngIf="pageType != getPageType().default">{{ getTextByPageType() }}</span>
</button>
