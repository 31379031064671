import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PhaseList } from 'src/app/shared/interfaces/matches';

@Component({
  selector: 'app-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss']
})
export class PhasesComponent implements OnInit {
  @Input() data?: PhaseList[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cd.detectChanges();
  }
  public isLoaded(): boolean {
    let isLoaded = false;
    if (this.data == null) {
      isLoaded = false;
    } else {
      isLoaded = true;
    }

    return isLoaded;
  }
}
