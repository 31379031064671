import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { GenericObject } from '../../interfaces/generic';
import { CieloFormInputs } from '../../interfaces/cielo-form-inputs';
import {
  cardMonthValidityPatternValidator,
  cardSecurityValuePatternValidator,
  cardYearValidityPatternValidator,
  cardNumberValidator
} from '@src/utils/cielo-form-validators';
import { CieloPaymentService } from '../../services/cielo-payment.service';
import {
  CieloCheckPaymentParams,
  CieloPaymentResult
} from '../../interfaces/cielo';
import { Theme } from 'src/assets/theme/theme';

@Component({
  selector: 'app-cielo-payment-form',
  templateUrl: './cielo-payment-form.component.html',
  styleUrls: ['./cielo-payment-form.component.scss']
})
export class CieloPaymentFormComponent implements OnInit, OnChanges {
  public form: FormGroup;
  public formFields: CieloFormInputs[][];
  public isLoading: boolean;
  public backendErrorMessage: string;
  private formValues: CieloCheckPaymentParams;

  @Input() public itemId: number;
  @Input() public coupon: string;

  @Output() paymentResult = new EventEmitter<CieloPaymentResult>();

  constructor(
    private formBuilder: FormBuilder,
    private cieloPaymentService: CieloPaymentService,
    public theme: Theme
  ) {
    this.form = new FormGroup({});
    this.formFields = [
      [
        {
          label: 'Número impresso no cartão',
          name: 'cardNumber',
          type: 'text',
          placeholder: 'Insira o número do cartão',
          isDisabled: false,
          mask: '0000 0000 0000 0000',
          validators: [
            [Validators.required],
            [cardNumberValidator(this.cieloPaymentService)]
          ],
          errorMessage: {
            required: 'O número do cartão é obrigatório.',
            cardNumberPattern: 'O número de cartão é inválido.'
          }
        }
      ],
      [
        {
          label: 'Nome impresso no cartão',
          name: 'cardName',
          type: 'text',
          placeholder: 'Insira o nome impresso no cartão',
          isDisabled: false,
          validators: [[Validators.required]],
          errorMessage: {
            required: 'O Nome do cartão é obrigatório.'
          }
        }
      ],
      [
        {
          label: 'Data de validade:',
          name: 'cardMonth',
          type: 'text',
          placeholder: 'Mês',
          isDisabled: false,
          mask: '00',
          validators: [
            [Validators.required, cardMonthValidityPatternValidator()]
          ],
          errorMessage: {
            required: 'O mês da data de validade é obrigatório.',
            cardMonthValidityPattern: 'O mês da data de validade é inválido.'
          }
        },
        {
          label: '',
          name: 'cardYear',
          type: 'text',
          placeholder: 'Ano',
          isDisabled: false,
          mask: '0000',
          validators: [
            [Validators.required, cardYearValidityPatternValidator()]
          ],
          errorMessage: {
            required: 'O ano da data de validade é obrigatório.',
            cardYearValidityPattern: 'O ano da data de validade é inválido.'
          }
        }
      ],
      [
        {
          label: 'Código de segurança (CVV)',
          name: 'cardSecurityValue',
          type: 'text',
          placeholder: '000',
          isDisabled: false,
          mask: '0000',
          validators: [
            [Validators.required, cardSecurityValuePatternValidator()]
          ],
          errorMessage: {
            required: 'O CVV é obrigatório.',
            cardSecurityValuePattern: 'O CVV é inválido.'
          }
        }
      ],
      [
        {
          label: 'Opções de parcelamento',
          name: 'installmentOptions',
          type: 'text',
          placeholder: 'A vista',
          isDisabled: true,
          value: 'A vista',
          validators: [[Validators.required]],
          errorMessage: {
            required: 'Opções de parcelamento é obrigatório.'
          }
        }
      ]
    ];
    this.formValues = {
      cardNumber: '',
      cardName: '',
      cardMonth: '',
      cardYear: '',
      cardSecurityValue: '',
      itemId: 0,
      coupon: ''
    };

    this.isLoading = false;
    this.itemId = 0;
    this.coupon = '';
    this.backendErrorMessage = '';
  }

  ngOnInit(): void {
    this.createForm();

    this.formValues = {
      ...this.formValues,
      itemId: this.itemId
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coupon']) {
      this.coupon = changes['coupon'].currentValue;
      this.formValues.coupon = this.coupon;
    }
  }

  public handleSubmitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    this.isLoading = true;

    this.cieloPaymentService.makePayment(this.formValues).subscribe({
      next: (response) => {
        const { success, message } = response;
        this.paymentResult.emit({
          success,
          message
        });
        this.backendErrorMessage = '';
        this.isLoading = false;
      },
      error: (error) => {
        const message =
          error.error?.message ??
          'Ocorreu um erro ao realizar o pagamento! Por favor, tente novamente mais tarde!';

        console.error(error);
        this.paymentResult.emit({
          success: false,
          message
        });

        this.backendErrorMessage = message;
        this.isLoading = false;
      }
    });
  }

  private createForm(): void {
    const formGroupConfig: GenericObject = {};

    this.formFields.flat().forEach((input) => {
      const validator: Validators[] = [''];

      if (input.validators.length > 0) {
        validator.push(...input.validators);
      }

      formGroupConfig[input.name] = validator;
    });

    this.form = this.formBuilder.group(formGroupConfig);
  }

  public setFormValues(inputName: string) {
    if (inputName in this.formValues) {
      const inputControl = this.getInputControl(inputName);

      this.formValues[inputName] = inputControl?.value ?? '';
    }
  }

  public getInputControl(controlName: string) {
    return this.form.get(controlName) as FormControl;
  }

  public getErrorMessage(inputName: string): string {
    const input = this.getInputControl(inputName);

    if (input) {
      for (const errorType in input.errors) {
        if (input.errors.hasOwnProperty(errorType)) {
          const errorConfig = this.getErrorConfig(inputName, errorType);

          return errorConfig ?? '';
        }
      }
    }

    return '';
  }

  public getErrorConfig(inputName: string, errorType: string): any {
    const inputsWithError = this.formFields
      .flat()
      .find((field) => field.name === inputName);

    if (inputsWithError) {
      return (
        inputsWithError.errorMessage && inputsWithError.errorMessage[errorType]
      );
    }

    return null;
  }

  public isInputInvalid(inputName: string): boolean {
    const input = this.getInputControl(inputName);

    if (input) {
      return input.invalid && input.touched;
    }

    return true;
  }
}
