<div class="container">
  <div class="labels">
    <h4>Conteúdo Exclusivo</h4>
    <p>Assinantes {{ theme.client === 'multilaser' ? 'Multi+' : theme.client | titlecase }}</p>
  </div>
  <div class="icon">
    <img [src]="environment.bucket + 'icons/paramount_exclusive_watch.svg'" *ngIf="theme.client === 'watch'">
    <img [src]="environment.bucket + 'icons/paramount_exclusive_giga.svg'" *ngIf="theme.client === 'giga'">
    <img src="../../../../assets/icons/exclusive_multi.svg" *ngIf="theme.client === 'multilaser'">
  </div>
</div>