<header>
  <div id="content__header">
    <div id="logo__container">
      <img [src]="environment.bucket + 'logos/logo-multi-roxo.png'" />
    </div>
    <div id="promo__description__container">
      <p>Você ganhou <strong>{{ countDays }} DIAS</strong> de Multi+</p>
      <span id="warning__text" class="on-mobile"> {{ disclaimerText }} </span>
    </div>
    <div id="promo__button__container">
      <app-button
        class="primary-button"
        type="button"
        [text]="btnText"
        routerLink="/login/voucher"
      ></app-button>
    </div>
  </div>

  <span id="warning__text" class="on-desktop"> {{ disclaimerText }} </span>
</header>