import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-modal-cancel-la-carte',
  templateUrl: './modal-cancel-la-carte.component.html',
  styleUrls: ['./modal-cancel-la-carte.component.scss']
})
export class ModalCancelLaCarteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public showModal: boolean = true;


  public cancellationConfirmed: boolean = false;
  public checkMobile: boolean = false;
  public closeModal(): void {
    this.showModal = false;
  
  }

 



}
