<div class="container">
  <div class="footer-mobile">
    <ul class="footer-mobile__icons">
      <li>
        <a routerLink="/home">
          <div
            [class]="
              currentRoute === '/home' ? 'home-icon active' : 'home-icon'
            "
          ></div>
          <span>
            Home
          </span>
        </a>
      </li>
      <li *ngIf="hasLiveChannels">
        <a routerLink="/now"
          ><div
            [class]="currentRoute === '/now' ? 'live-icon active' : 'live-icon'"
          ></div
        >
          <span>
            Ao vivo
          </span>
        </a>
      </li>

      <li>
        <a routerLink="/search-mob/todos"
          ><div
            [class]="
              currentRoute.includes('/search') ? 'search-icon active' : 'search-icon'
            "
          ></div
        >
          <span>
            Busca
          </span>
        </a>
      </li>

      <li>
        <a routerLink="/opcoes"
          ><div
            [class]="
              currentRoute === '/opcoes' ? 'menu-icon active' : 'menu-icon'
            "
            style="--background-icon: url('{{ profilePhoto }}');"
          ></div
        >
          <span>
            Perfil
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
