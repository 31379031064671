import { strIsEmpty } from "../shared/utils";

export class Units {
  static zeroPointPixel = 120;
  static totalPixelOfOneHour = 600;

  public static convertMinuteToPixel(minute: number) {
    return (minute * Units.totalPixelOfOneHour) / 60;
  }

  public static timeToMinutes(value: string) {
    if (!strIsEmpty(value)) {
      const splitDuration = value.split(':');
      if (splitDuration.length >= 2) {
        return (parseInt(splitDuration[0]) * 60) + parseInt(splitDuration[1]);
      }
    }

    return 0;
  }
}
