import { Device } from 'src/app/helpers/device';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Profile } from '../../interfaces/profile';
import { CheckDeviceService } from '../../services/check-device.service';
import { environment } from '@src/environments/environment';
import { Theme } from '@src/assets/theme/theme';
import { UserIsInCoverageAreaService } from '../../services/user-is-in-coverage-area.service';
import { sortChannelsBasedOnCoverageArea } from '../../utils';

@Component({
  selector: 'app-channels-section',
  templateUrl: './channels-section.component.html',
  styleUrls: ['./channels-section.component.scss']
})
export class ChannelsSectionComponent implements OnInit, OnChanges {
  @Input() public channels: any;
  @Input() public profileAgeBracket: number;
  @Input() public profile: Profile;
  @Input() public title: string = '';
  @Input() public disableClick: boolean = false;
  @Input() public userIsInCoverageArea: boolean = false;
  @Input() public fromOpc: boolean = false;

  public originalChannels: any;
  public isMobile: boolean = false;
  public checkMobile: boolean = false;

  constructor(
    private checkDevice: CheckDeviceService,
    private theme: Theme,
    private userIsInCoverageAreaService: UserIsInCoverageAreaService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isMobile = Device.isMobile();
    this.channels = {};
    this.profileAgeBracket = 0;
    this.profile = {};
  }

  ngOnInit(): void {
    this.originalChannels = this.channels;
    this.filterChannels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userIsInCoverageArea'] && this.originalChannels) {
      this.filterChannels();
    }
  }

  private filterChannels() {
    if (['watch', 'ligga','giga','vero', 'voa'].includes(this.theme.client) && this.profile) {
      this.channels = sortChannelsBasedOnCoverageArea(this.originalChannels, this.userIsInCoverageArea, this.userIsInCoverageAreaService.myRegionalChannelName);
    }
  }
}
