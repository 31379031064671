<div class="info">
  <h2 class="info--title">
    A DGO chegou na Multi+ para somar!
  </h2>
  <p class="info--description">
    Além dos canais de TV AO VIVO e cobertura exclusiva de diversos esportes,
    milhares de filmes e séries estão disponíveis On Demand para assistir onde e
    como quiser. Contrate agora mesmo e tenha acesso a tudo o que a Multi+
    Play e a DGO oferecem!
  </p>
  <div routerLink="/planos" class="directvBtn">contrate agora!</div>
</div>
