import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PromoBanner } from '../../interfaces/promo-banner';

@Component({
  selector: 'app-promo-banners',
  templateUrl: './promo-banners.component.html',
  styleUrls: ['./promo-banners.component.scss']
})
export class PromoBannersComponent implements OnInit {

  @Input() banners: PromoBanner[] = [];
  @Input() srcMultiLogo: string = '/assets/icons/multi-content-for-you.svg';
  @Input() showCTA: boolean = true;
  public selectedBanner: PromoBanner = this.banners[0];
  public environment = environment;

  constructor() {}

  ngOnInit(): void {
    this.chooseBanner();
  }

  public chooseBanner() {
    const randomIndex = Math.floor(Math.random() * this.banners.length);
    this.selectedBanner = this.banners[randomIndex];
  }
}
