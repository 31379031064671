import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../services/login.service';
import { PageType } from '../../enums/page-type.enum';

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss']
})
export class PlayButtonComponent {
  @Input() public isKeepWatching: boolean = false;
  @Input() public resumido: boolean = false;
  @Input() public pageType?: number = PageType.default;
  @Input() public order?: number = 1;
  @Input() public text: string = "Assistir";
  @Output() public clickEvent = new EventEmitter();

  public environment: any;
  public icon: string;

  constructor(public loginService: LoginService) {
    this.icon = `${environment.imageService}/icons/button/play-icon.svg`;
  }

  public click(): void {
    this.clickEvent.emit();
  }

  public getPageType() {
    return PageType;
  }

  public getTextByPageType() {
    if (!this.loginService.token) return 'Assinar';
    return this.isKeepWatching ?
      'Continuar Episódio ' + this.order :
      this.text + ' Episódio ' + this.order
  }
}
