<div class="popup-mobile">
  <div class="rent-mobile-popup">
    <h4>Adquirir Conteúdo</h4>
    <p>
      Para adquirir esse conteúdo é preciso acessar a plataforma via plataforma
      web e na sessão minhas locações adquirir este conteúdo.
    </p>
    <app-button
      [text]="'Ok'"
      [classList]="'btn-primary'"
      (click)="closePopup()"
    ></app-button>
  </div>
</div>
