<div class="toaster" [class]="options.type" [ngClass]="{ hide: hideToaster }">
  <div class="toaster--icon">
    <img [src]="options.icon.source" [alt]="options.icon.name">
  </div>

  <p class="toaster--content" [innerHTML]="options.description"></p>

  <div *ngIf="!!options.button" class="toaster--action">
    <app-button
      classList="btn btn-secondary"
      [text]="options.button.text"
      (click)="options.button.onClick && options.button.onClick()"
    ></app-button>
  </div>

  <div class="toaster--close" (click)="options.onCloseToaster && options.onCloseToaster()" (click)="hideToaster = true">
    <img src="/assets/icons/close_small_icon.svg" alt="Close Icon">
  </div>
</div>