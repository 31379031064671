<div class="form-slider">
  <ng-container *ngFor="let step of formSliderSteps; let stepIndex = index">
    <form
      [formGroup]="step.formGroup"
      (submit)="step.onSubmitStep()"
      class="current-step-container"
      [class.active]="currentStepIndex === stepIndex"
    >
      <ng-container *ngFor="let inputs of step.inputs">
        <div class="input-group">
          <ng-container *ngFor="let input of inputs">
            <ng-container [ngSwitch]="input.type">
              <ng-container *ngSwitchCase="'checkbox'">
                <div
                  class="checkbox-control"
                  [ngClass]="{ disabled: input.isDisabled }"
                >
                  <label class="field-label">
                    <app-checkbox
                      [control]="getInputControl(step.formGroup, input.name)"
                      (checked)="setStepFormValue(input.name)"
                    ></app-checkbox>

                    <span class="min-info" [innerHTML]="input.label"></span>
                  </label>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'select'">
                <div
                  class="input-control"
                  [class]="getInputControlClasses(input)"
                >
                  <label
                    class="field-label column"
                    [ngClass]="{
                      shake: isInputInvalid(input.name)
                    }"
                  >
                    <span [innerHTML]="input.label"></span>

                    <select
                      [name]="input.name"
                      [formControlName]="input.name"
                      (change)="setStepFormValue(input.name)"
                    >
                      <option value="" [innerHTML]="input.placeholder" disabled></option>
                      <option
                        *ngFor="let item of input?.options"
                        [ngValue]="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <div
                      *ngIf="isInputInvalid(input.name)"
                      class="error-message vertical-appear"
                    >
                      {{ getErrorMessage(input.name) }}
                    </div>
                  </label>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <div
                  class="input-control"
                  [class]="getInputControlClasses(input)"
                >
                  <label
                    class="field-label column"
                    [ngClass]="{
                      shake: isInputInvalid(input.name)
                    }"
                  >
                    <span [innerHTML]="input.label"></span>
                    <app-input
                      [type]="input.type"
                      [placeholder]="input.placeholder"
                      [mask]="input?.mask ?? ''"
                      [(value)]="input.value"
                      (valueChange)="input.onChange && input.onChange($event)"
                      (valueChange)="
                        setStepFormValue(input.name)
                      "
                      [control]="getInputControl(step.formGroup, input.name)"
                    ></app-input>
                    <div
                      *ngIf="isInputInvalid(input.name)"
                      class="error-message vertical-appear"
                    >
                      {{ getErrorMessage(input.name) }}
                    </div>
                  </label>
                  <span
                    *ngIf="input.fieldInfo"
                    [class]="'field-' + input.fieldInfo.type"
                    [innerHTML]="input.fieldInfo.label"
                    (click)="input.fieldInfo.action && input.fieldInfo.action()"
                  ></span>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngFor="let info of step.moreInfos">
        <p
          [class]="'step-' + info.type"
          [innerHTML]="info.text"
          (click)="info.action && info.action()"
        ></p>
      </ng-container>

      <div class="action-group">
        <app-button
          *ngFor="let button of step.buttons"
          [text]="button.text"
          [classList]="button.classList"
          [type]="button.type"
          [disabled]="button.type === 'submit' ? step.formGroup.invalid : false"
          (click)="button.onClick && button.onClick()"
        ></app-button>
      </div>
    </form>
  </ng-container>
</div>

<app-toastify
  [status]="toastifyStatus"
  [message]="backendErrorMessage"
></app-toastify>
