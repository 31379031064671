<div class="container">
    <div class="banner">
        <img [src]="imgBannerUrl" alt="banner">
        <div class="gradient-banner"></div>
    </div>
    <div class="contents">
        <img [src]="iconUrl" alt="icon">
        <h1>Em breve na Watch!</h1>
        <p>Fique ligado em todos os conteúdos que estão prestes a estreiar em nossa plataforma. E para não perder tempo, acione o lembrete e avisaremos assim que estiver disponível.</p>
    </div>
</div>
