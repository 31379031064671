import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CardData } from '../shared/interfaces/card';
import { Matches, PhaseList } from '../shared/interfaces/matches';
import { LibertadoresService } from '../shared/services/libertadores.service';
import { ProfileService } from '../shared/services/profile.service';
import { CheckDeviceService } from '@app/shared/services/check-device.service';
import { environment } from 'src/environments/environment';
import { Theme } from 'src/assets/theme/theme';

@Component({
  selector: 'app-sulamericana',
  templateUrl: './sulamericana.component.html',
  styleUrls: ['./sulamericana.component.scss']
})
export class SulamericanaComponent implements OnInit {
  @Input() public data?: Matches;
  public id_perfil: number = 0;
  public phasesList?: PhaseList[];
  public isLoadedLive: boolean = false;
  public isLoadedPhase: boolean = false;
  public highlightsData: CardData[] = [];
  private readonly highlightsIdPhase: number = 256;
  public checkMobile: boolean = false;
  public environment: any;

  constructor(
    private sulamericana: LibertadoresService,
    private profileService: ProfileService,
    private cd: ChangeDetectorRef,
    public checkDevice: CheckDeviceService,
    public theme: Theme,
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.environment = environment;
  }

  ngOnInit(): void {
    const isLogged = parseInt(this.profileService.selectedProfile);

    if (isLogged) {
      this.id_perfil = isLogged;
      this.loadData();
    }
  }

  public async loadData() {
    await this.getMatches();
  }
  public async getMatches() {
    this.sulamericana.getMatches(this.id_perfil, 'sulamericana').subscribe(async (obj) => {
      this.isLoadedLive = false;
      this.data = await obj;
      this.sulamericana
        .getPhase(this.id_perfil, this.highlightsIdPhase)
        .subscribe({
          next: (phase) => {
            this.highlightsData = phase;
            this.cd.detectChanges();
          },
          error: (err) => console.error(err),
          complete: () => {
            this.isLoadedLive = true;
            this.mountPhases();
            this.cd.detectChanges();
          }
        });
    });
  }
  public async mountPhases() {
    this.isLoadedPhase = false;
    this.sulamericana.getPhases('sulamericana').subscribe((list) => {
      if (list && list.length > 0) {
        this.phasesList = list.filter((item: any) => {
          return item.id !== this.highlightsIdPhase;
        });
        this.phasesList!.forEach((item: any) => {
          this.sulamericana
            .getPhase(this.id_perfil, item.id)
            .subscribe((phase) => {
              item.data = phase;
              this.cd.detectChanges();
            });
        });
      }
      this.isLoadedPhase = true;
      this.cd.detectChanges();
    });
  }
}
