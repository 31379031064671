import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promo-header',
  templateUrl: './promo-header.component.html',
  styleUrls: ['./promo-header.component.scss']
})
export class PromoHeaderComponent implements OnInit {

  public environment = environment;
  @Input() public countDays: string | number = 0;
  @Input() public btnText: string = "EU QUERO";
  @Input() public requireCard: boolean = false;
  public disclaimerText: string = '';

  constructor() {}

  ngOnInit(): void {
    this.disclaimerText = this.requireCard ? '' : '*Sem precisar inserir cartão de crédito';
  }
}
