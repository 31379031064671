import { Injectable } from '@angular/core';
import { Http } from './http.service';
import { Observable } from 'rxjs';
import { ProfilePreferencesDefaultReturn, SetProfilePreferencesPayload } from '../interfaces/profile-preferences';

@Injectable({
  providedIn: 'root'
})
export class ProfilePreferencesService {
  private readonly getPreferencesEndpoint: string = 'v3/profile/get/preferences?id_perfil={id_perfil}';
  private readonly setPreferenceEndpoint: string = 'v3/profile/set/preferences';

  constructor(private http: Http) {}

  public getProfilePreferences(id_perfil: string): Observable<ProfilePreferencesDefaultReturn> {
    const endpoint: string = this.getPreferencesEndpoint.replace('{id_perfil}', id_perfil);

    return this.http.get(endpoint);
  }

  public setProfilePreferences(payload: SetProfilePreferencesPayload): Observable<Omit<ProfilePreferencesDefaultReturn, "preferences">> {
    return this.http.post(this.setPreferenceEndpoint, payload);
  }
}
