<section
  id="banner__container"
  [style]="
    '--background-banner: url(' + selectedBanner.bannerUrl + ')'
  "
>
  <div id="banner__container__content__logo">
    <img [src]="selectedBanner.channelUrl" alt="Logo do canal" />
    <p class="title__header">{{ selectedBanner.titleName }}</p>
  </div>

  <div id="multi__action">
    <img [src]="srcMultiLogo" alt="Logo Multi+" />
    <app-button
      *ngIf="showCTA"
      classList="btn-secondary"
      type="button"
      text="APROVEITE O PERÍODO GRATUITO"
      routerLink="/login/voucher"
    ></app-button>
  </div>
</section>