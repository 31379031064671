import { ProfileKtpService } from 'src/app/shared/services/profile-ktp.service';
import { PlanAccessControlService } from 'src/app/shared/services/plan-access-control.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Theme } from 'src/assets/theme/theme';
import { environment } from '../../../../environments/environment';
import { Profile } from '../../interfaces/profile';
import { CheckConnectionService } from '../../services/check-connection.service';
import { LoginService } from '../../services/login.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-dropdown-header',
  templateUrl: './dropdown-header.component.html',
  styleUrls: ['./dropdown-header.component.scss']
})
export class DropdownHeaderComponent implements OnInit {
  public environment: any;

  @Input() public active: boolean;
  @Input() public profileList: Profile[] = [];
  @Input() public isSelected: boolean;
  @Input() public profile: Profile = {};
  @Output() public closeEvent = new EventEmitter();
  @Output() public profileToEnterEmitter = new EventEmitter();
  public profileLimit: any;
  public src: string = ''
  public showModal:boolean = false
  private defaultProfileImages: { [key: string]: string } = {};
  public loading:boolean = true

  constructor(
    private loginService: LoginService,
    private profileService: ProfileService,
    private router: Router,
    public theme: Theme,
    public planAcessControl: PlanAccessControlService,
    private checkConnectionService: CheckConnectionService,
    private profileKtpService: ProfileKtpService
  ) {
    this.active = false;
    this.isSelected = false;
    this.environment = environment;
    this.profileLimit = {};

    this.defaultProfileImages = {
      'watch': this.environment.bucket + 'profiles/orange.svg',
      'multilaser': this.environment.bucket + 'profiles/pink.svg',
      'vero': this.environment.bucket + 'profiles/pink.svg',
      'ligga': this.environment.bucket + 'profiles/orange.svg',
      'giga': this.environment.bucket + 'profiles/blue.svg',
      'voa': this.environment.bucket + 'profiles/blue.svg',
    };
  }

  ngOnInit(): void {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.active = false;
      }
    });
  }

  public onDropdownClick(): void {
    this.getProfileList();
    if(this.theme.client != 'multilaser'){
      this.getProfilev3 ()
    }

    this.active = !this.active;
    if (!this.active) {
      this.closeEvent.emit();
    }
  }

  public getAgeBracket(): string {
    let _correctAge = this.profile?.age_bracket?.toString();
    if (_correctAge?.includes('a' || 'A')) _correctAge?.replace('a' || 'A', '');
    return _correctAge || '';
  }

  public getProfileList(): void {

    this.profileKtpService.getUserProfileListV4().subscribe(
      (res) => {
      this.profileList = res.data
      this.montImage()
      this.profileLimit.limitProfile = res.limitProfile;
      this.loading = false;
      if (!this.profileList) this.getProfilev3()
      },
      (err) => {
        if (!this.profileList) this.getProfilev3()
      },
      () =>{
        if (!this.profileList) this.getProfilev3()
      }
      )
  }

  public getProfilev3 () {
    this.profileService.profilesObservable.subscribe((profiles) => {
      this.profileList = profiles.success?.data!;
      if (profiles.success) {
        this.profileLimit = profiles.success;
        this.montImage()
      }
      this.loading = false
    });
  }

  public montImage() {
    this.profileList.forEach((el) => {
      if (el.foto) {
        if (!el.foto.includes('https://watchbr-resources')) {
          el.foto = `https://watchbr-resources.s3.amazonaws.com/perfil/${el.foto}`;
        }
      } else {
        switch (this.theme.client) {
          case 'multilaser':
            el.foto = environment.bucket + 'profiles/pink.svg';
            break;
          case 'watch':
            el.foto = environment.bucket + 'profiles/orange.svg';
            break;
          case 'vero':
            el.foto = environment.bucket + 'profiles/pink.svg';
            break;
          case 'ligga':
              el.foto = environment.bucket + 'profiles/orange.svg';
            break;
        }
      }
    });
  }

  public onLogoutProfileClick(): void {
    this.profileService.logoutProfile();
    this.router.navigateByUrl('/profile/select/manage');
  }

  public onLogoutClick(): void {
    let perfil = Number(localStorage.getItem('id_perfil'));
    this.checkConnectionService.disconnectMyProfile(perfil).subscribe((data) => { });
    this.loginService.logout();
  }

  public getCurrentUrl(): string {
    return this.router.url;
  }

  /**
   * SELECT PROFILE (DROPDOWN MENU)
   * @param profile
   */
  public selectProfile(profile: Profile): void {
    this.loading = true;

    if (profile.id_perfis === this.profile.id_perfis) {
      this.active = false;
      this.loading = false;
      return
    }

    if (localStorage.getItem('ks')) {
      this.profileKtpService.unconnectProfile().subscribe((resp) => {
        this.profileKtpService.getProfileData(Number(profile.id_perfis)).subscribe((resp) => {
          this.profileKtpService.saveKs(resp.data.ks);
          const selectReload = () => {
            this.profileService.selectProfile(profile.id_perfis);
            this.checkConnectionService.connectMyProfile().subscribe(() => {
              this.active = false;
              this.router.navigateByUrl('/home').then(() => window.location.reload())
            });
          };

          if (profile.use_pin) {
            profile.foto_full_path = profile.foto;
            this.profileToEnterEmitter.emit(profile);
            this.active = false
          } else {
            this.checkConnectionService
              .disconnectMyProfile(parseInt(this.profileService.selectedProfile))
              .subscribe(
                (next) => { selectReload() },
                (error) => { selectReload() }
              )
          }
        });
      });
    } else {
      const selectReload = () => {
        this.profileService.selectProfile(profile.id_perfis);
        this.checkConnectionService.connectMyProfile().subscribe(() => {
          this.active = false;
          this.router.navigateByUrl('/home').then(() => window.location.reload())
        });
      };

      if (profile.use_pin) {
        profile.foto_full_path = profile.foto;
        this.profileToEnterEmitter.emit(profile);
        this.active = false
      } else {
        this.checkConnectionService
          .disconnectMyProfile(parseInt(this.profileService.selectedProfile))
          .subscribe(
            (next) => { selectReload() },
            (error) => { selectReload() }
          )
      }
    }
  }

  public createProfile(route: string): void {
    if (localStorage.getItem('ks')) {
      this.router.navigateByUrl('/v4/profile/create');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  public goToFaq() {
    window.open('https://verointernet.com.br/rs/alvorada/fale-conosco/');
  }

  public getDefaultProfileImage() {
    return this.defaultProfileImages[this.theme.client];
  }
}
