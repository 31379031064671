import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Theme } from 'src/assets/theme/theme';
import { aLaCarte } from 'src/utils/alacarte';

@Injectable({
  providedIn: 'root'
})
export class AlacarteGuard implements CanActivate {

  constructor(private router: Router, private theme: Theme){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const alacarteKeys = Object.keys(aLaCarte);

      if (alacarteKeys.includes(route.params['src']) && this.theme.client === 'multilaser') return true;

      return this.router.parseUrl('/');
  }
}
