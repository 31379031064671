import { Device } from 'src/app/helpers/device';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { CardData, CardProperties } from '../../interfaces/card';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { KeepWatching } from '../../interfaces/keep-watching';
import { Theme } from 'src/assets/theme/theme';
import { CheckDeviceService } from '../../services/check-device.service';

@Component({
  selector: 'app-section-landing-page',
  templateUrl: './section-landing-page.component.html',
  styleUrls: ['./section-landing-page.component.scss']
})
export class SectionLandingPageComponent implements OnChanges{

  public checkMobile: boolean = false;
  @Input() public isNSportsCard: boolean = false;
  @Input() public isPreSaveCard: boolean = false;
  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public viewMore: string = '';
  @Input() public idPerfil: number = 0;
  @Input() public hasAwesomenessLogo: boolean = false;
  @Input() public hasCustomIcon: string = '';
  @Input() public properties: CardProperties = {};
  @Input() public data: CardData[] = [];
  @Input() public rented: string = '';
  @Input() public cardOnly: boolean = false;
  @Input() public verMais: boolean = true;
  @Input() public section: any = {};
  @Input() public id: number = 0;
  @Input() public isInfinity: boolean = false;
  @Input() public keepWatchingList: KeepWatching[] = [];
  @Input() public goToWatch: boolean = false;
  @Input() public isExclusiveContent: boolean = false;
  @Input() public labelExclusiveContent: string = '';
  @Input() public hasExclusiveContent: boolean = false;
  @Input() public hasSectionIcon: boolean = false;
  @Input() public hasPadding: boolean = true;
  @Input() public hasNavigation: boolean = true;
  @Input() public allowTouchMove: boolean = true;
  @Input() public showExpiredContent: boolean = true;
  @Input() public isConmebolLibertadores: boolean = false;
  @Input() public noClick: boolean = false;
  @Input() public hasCustomBanner: string = '';
  @Input() public sectionIsRanked: boolean = false;
  @Input() public specialBanner: boolean = false;
  @Input() public bannersList: any = {};
  @Input() public svg: string = "";
  @Input() public svgTop: string = "";
  @Input() public text: string = "";
  @Input() public subTitleBanner: string = "";
  @Input() public buttonText: string = "";
  @Input() public titleBanner: string = "";
  @Input() public buttonLink: string = "";
  @Input() public textCenter: string = "";
  @Input() public gradient: any = "";
  @Output() public hasntExclusiveContentEvent: EventEmitter<
    any
  > = new EventEmitter();

  public environment: any = environment;
  public isMobile: boolean = false;
  public showTitle: boolean = false;

  constructor(
    private router: Router,
    public theme: Theme,
    public checkDevice: CheckDeviceService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isMobile = Device.isMobile();
  }

  ngOnChanges(): void {
    this.buildRoute();
  }

  private buildRoute(): void {
    // if (this.data) {
    //   this.data.forEach((element) => {
    //     let _now = new Date();
    //     let _expireDate = new Date(element.expire!);
    //     let _diffTime = Math.round((Number(_expireDate) - Number(_now)) / 1000);

    //     element.expired = _diffTime < 0;

    //     if (this.goToWatch) {
    //       switch (element.type) {
    //         case 'filme':
    //           element.route = `watch?id=${element.id}&type=filme&position=${element.position}`;
    //           break;
    //         case 'serie':
    //           element.route = `watch?id=${element.id}&type=serie&isLive=false&episodeId=${element.episode_id}&isMarathon=false&position=${element.position}`;
    //           break;
    //       }
    //     } else
    //       element.route = `/details/${element.id}/${element.type}/${this.isExclusiveContent}`;
    //   });
    // }
  }

  public viewMoreClick(): void {
    this.router.navigateByUrl(this.viewMore);
  }

  public openCategory() {
    this.router.navigateByUrl('/landingPage/' + this.id + '/' + this.title);
  }

  public hasntParamount(): void {
    this.hasntExclusiveContentEvent.emit();
  }

  public goTo() {
    if (this.buttonLink) {
      window.open(this.buttonLink.toString(), '_blank');
    }else{
      this.router.navigateByUrl('/landingPage/' + this.id + '/' + this.title);
    }
  }
}
