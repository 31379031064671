<div class="toast opacity" class="{{toastClass}}" [class.from-app]="this.checkMobile" *ngIf="!toastReg">
  <div class="content">
    <div class="icon" *ngIf="showIconAlert">
      <img src="assets/icons/alert_icon.svg" alt="warning" />
    </div>
    <div class="main">
      <p class="title" *ngIf="(toastTitle == '')">
        Olá, Seu acesso à plataforma {{ theme.clientName }} está vencendo.
      </p>
      <p class="title" *ngIf="(toastTitle != '')">
        {{ toastTitle }}
      </p>
      <p class="description" *ngIf="(toastDesc == '') && showDescription">
        Você recebeu um voucher com acesso de 30 dias à plataforma da
        {{ theme.clientName }} mas seu prazo está acabando.
      </p>
      <p class="description" *ngIf="(toastDesc == '') && showDescription">
        Aproveite para renovar o seu plano e descobrir quais outros planos nós
        oferecemos!
      </p>
      <p class="description" *ngIf="(toastDesc != '') && showDescription">
        {{toastDesc}}
      </p>
    </div>
  </div>
  <div class="actions">
    <div class="main-button" routerLink="/planos" *ngIf="checkMobile && showMainButton">
      SAIBA MAIS
    </div>
    <div class="close-button" (click)="closeToast()" [ngClass]="{normalHeight: !showMainButton && !showDescription}">
      <img src="{{ environment.imageService }}/icons/close_small_icon.svg" alt="close" />
    </div>
  </div>
</div>

<div class="toast" class="{{toastClass}}" *ngIf="toastReg" [ngClass]="{'toast-mobile':checkMobile}">
  <div class="content">
    <div class="icon-reg" *ngIf="!checkMobile">
      <img src="assets/icons/alert_icon.svg" alt="warning" />
    </div>
    <div class="main">
      <p class="title-reg" *ngIf="!checkMobile">
        {{ toastTitle }}
      </p>

      <p class="description" [innerHTML]="formatarToastDesc()" *ngIf="!checkMobile">
      </p>

      <p class="description" *ngIf="checkMobile">
        {{descMobile}}
      </p>

    </div>
  </div>
  <div class="timer">
    <div class="main-button" routerLink="/planos" *ngIf="!checkMobile">
      <span class="title">SAIBA MAIS</span>
    </div>
    <div class="main-button-mobile" routerLink="/planos" *ngIf="checkMobile">
      <div >SAIBA MAIS</div>
    </div>
  </div>
  <div class="actions">
    <div class="close-button" (click)="closeToast()">
      <img src="{{ environment.imageService }}/icons/close_small_icon.svg" alt="close" />
    </div>
  </div>
</div>
