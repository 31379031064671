<div class="language-selector">
  <div class="language-selector--button" (click)="toggleMenu()">
    <img src="{{ environment.imageService }}/icons/player/legends.svg" />
  </div>
  <div
    class="language-selector--menu"
    id="language-selector-menu"
    [class.show]="isActive"
  >
    <div class="language-selector--menu--subtitles">
      <div class="language-selector--menu--subtitles--title">Legendas</div>
      <div
        [ngClass]="subtitle.isSelected ? 'selected' : ''"
        *ngFor="let subtitle of subtitlePack"
        class="language-selector--menu--subtitles--subtitle"
        (click)="selectSubtitle(subtitle)"
      >
        {{ subtitle.name }}
      </div>
    </div>
    <div class="vl"></div>
    <div class="language-selector--menu--audios">
      <div class="language-selector--menu--audios--title">Áudio</div>
      <div
        *ngFor="let audio of audioPack"
        class="language-selector--menu--audios--audio"
        [ngClass]="audio.isSelected ? 'selected' : ''"
        (click)="selectTrack(audio)"
      >
        {{ audio.name }}
      </div>
    </div>
  </div>
</div>
