import { Device } from 'src/app/helpers/device';
import { SectionService } from './../../services/section.service';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { CardData, CardProperties, KalturaCardData } from '../../interfaces/card';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { KeepWatching } from '../../interfaces/keep-watching';
import { Theme } from 'src/assets/theme/theme';
import { CheckDeviceService } from '../../services/check-device.service';
import { idNSportsMatches } from 'src/environments/environment.prod';

@Component({
  selector: 'app-section-v4',
  templateUrl: './section-v4.component.html',
  styleUrls: ['./section-v4.component.scss']
})
export class SectionComponentV4 implements OnChanges {
  public checkMobile: boolean = false;
  @Input() public isNSportsCard: boolean = false;
  @Input() public isPreSaveCard: boolean = false;

  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public viewMore: string = '';
  @Input() public idPerfil: number = 0;
  @Input() public hasAwesomenessLogo: boolean = false;
  @Input() public hasCustomIcon: string = '';
  @Input() public properties: CardProperties = {};
  @Input() public data: CardData[] = [];
  @Input() public rented: string = '';
  @Input() public cardOnly: boolean = false;

  @Input() public verMais: boolean = false;
  @Input() public section: any = {};
  @Input() public id: number = 0;
  @Input() public isInfinity: boolean = false;
  @Input() public keepWatchingList: KalturaCardData[] = [];

  @Input() public goToWatch: boolean = false;

  @Input() public isExclusiveContent: boolean = false;
  @Input() public labelExclusiveContent: string = '';
  @Input() public hasExclusiveContent: boolean = false;
  @Input() public hasSectionIcon: boolean = false;
  @Input() public hasPadding: boolean = true;
  @Input() public hasNavigation: boolean = true;
  @Input() public allowTouchMove: boolean = true;

  @Input() public showExpiredContent: boolean = true;

  @Output() public hasntExclusiveContentEvent: EventEmitter<any> =
    new EventEmitter();

  public environment: any = environment;
  public isMobile: boolean = false;
  constructor(
    private router: Router,
    public theme: Theme,
    public checkDevice: CheckDeviceService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isMobile = Device.isMobile();
  }

  ngOnChanges(): void {
  }

  public viewMoreClick(): void {
    this.router.navigateByUrl(this.viewMore);
  }

  openCategory() {

    if (this.isPreSaveCard) {
      this.router.navigateByUrl('pre-lancamentos');
      return;
    }

    if (this.hasCustomIcon) {
      sessionStorage.setItem('hasCustomIcon', this.hasCustomIcon);
      if (this.id === idNSportsMatches) {
        this.router.navigateByUrl(
          '/nsports'
        );
        return;
      }
      this.router.navigateByUrl(
        '/ver-mais/' + this.id + '/' + this.title + '/CustomIcon'
      );
    } else this.router.navigateByUrl('/ver-mais/' + this.id + '/' + this.title);
  }

  public hasntParamount(): void {
    this.hasntExclusiveContentEvent.emit();
  }

  public getParentalRate(item: any): any {
    return item.tags.ParentalRating.objects[0].value;
  }

  public getImage(item: KalturaCardData): any {
    return item.images?.find((image) => image.imageTypeName === "Landscape").url;
  }
}
