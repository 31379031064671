import { Component, OnInit, Input } from '@angular/core';
import { AlertPopUpButtonOptions } from '../../interfaces/generic';

@Component({
  selector: 'app-alert-pop-up',
  templateUrl: './alert-pop-up.component.html',
  styleUrls: ['./alert-pop-up.component.scss']
})

export class AlertPopUpComponent implements OnInit {

  @Input() iconPath: string = '/assets/icons/warning.svg';
  @Input() modalTitle: string = 'Ops!';
  @Input() modalSubtitle: string = '';
  @Input() buttonsOption: AlertPopUpButtonOptions[] = [
    {
      label: 'Ok, voltar',
      classList: 'btn-secondary',
      onClick: this.closeModal
    }
  ];

  public hideModal: boolean = false;
  public readonly hideClassName: string = 'hide';

  constructor() {}

  ngOnInit(): void {
    document.body.style.overflowY = 'hidden';
  }

  public onClickButton(alertPopUpButtonOptionFunction: AlertPopUpButtonOptions) {
    alertPopUpButtonOptionFunction.onClick();
  }

  public closeModal() {
    // document.querySelector('.alert-pop-up-container')?.classList.add(this.hideClassName);
    this.hideModal = true;
    document.body.style.overflowY = 'auto';
  }
}
