import { Injectable, OnDestroy } from '@angular/core';
import {
  Geolocation,
  PermissionStatus,
  Position
} from '@capacitor/geolocation';
import { GeolocationLocationPermission } from '../enums/geolocation-modal-options';
import { GeolocationPositions } from '../interfaces/geolocation-modal';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UserIsInCoverageAreaService } from './user-is-in-coverage-area.service';
import { ProfileService } from './profile.service';
import { Http } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService implements OnDestroy {
  private locationPermissionSubject: BehaviorSubject<GeolocationLocationPermission> =
    new BehaviorSubject<GeolocationLocationPermission>(
      GeolocationLocationPermission.prompt
    );
  locationPermission$ = this.locationPermissionSubject.asObservable();

  private geolocationPositionsSubject: BehaviorSubject<GeolocationPositions> =
    new BehaviorSubject<GeolocationPositions>({ latitude: 0, longitude: 0 });
  geolocationPositions$ = this.geolocationPositionsSubject.asObservable();

  private unsubscribe$: Subject<void> = new Subject<void>();

  public hasGloboRegionalChannels: boolean | null = null;

  get locationPermission() {
    return this.locationPermissionSubject.value;
  }

  set locationPermission(permission: any) {
    this.locationPermissionSubject.next(permission);
  }

  get geolocationPositions() {
    return this.geolocationPositionsSubject.value;
  }

  set geolocationPositions(positions: any) {
    this.geolocationPositionsSubject.next(positions);
  }

  constructor(
    private userIsInCoverageAreaService: UserIsInCoverageAreaService,
    private profileService: ProfileService,
    private http: Http
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async verifyPermissionAndPositionsOnWeb() {
    try {
      const checkLocationPermission = this.checkPermissions();
      const checkLocationPositions = this.getCurrentPosition();

      const [permission, positions] = await Promise.all([
        checkLocationPermission,
        checkLocationPositions
      ]);
      this.locationPermission = permission;
      let coordsType = localStorage.getItem('coordsType');
      let coords = localStorage.getItem('coords');
      if (coordsType && parseInt(coordsType) === 1 && coords) {
        this.geolocationPositions = JSON.parse(coords);
      } else {
        this.geolocationPositions = positions;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async verifyPermissionAndPositionsOnMobile() {
    try {
      const permission = await this.checkPermissions();

      if (permission === GeolocationLocationPermission.granted) {
        const positions = await this.getCurrentPosition();

        this.geolocationPositions = positions;
      } else {
        this.geolocationPositions = null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public checkPermissions(): Promise<GeolocationLocationPermission> {
    return new Promise((resolve, reject) => {
      Geolocation.checkPermissions()
        .then((resp: PermissionStatus) => {
          const { coarseLocation } = resp;

          resolve(
            GeolocationLocationPermission[
              coarseLocation as GeolocationLocationPermission
            ]
          );
        })
        .catch((resp: any) => {
          reject(GeolocationLocationPermission.denied);
        });
    });
  }

  public getCurrentPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition()
        .then((resp: Position) => {
          let { latitude, longitude } = resp.coords;

          resolve({ latitude, longitude });
        })
        .catch((resp: any) => {
          reject(GeolocationLocationPermission.denied);
        });
    });
  }

  public verifyUserHasGloboRegionalChannels(isApp: boolean) {
    if (this.hasGloboRegionalChannels === null) {
      const id_perfil = parseInt(this.profileService.selectedProfile);

      this.http
        .post('v3/user-has-globo-regional-channels', {
          id_perfil
        })
        .subscribe({
          next: (result: any) => {
            if (result) {
              const { hasGloboGeolocation } = result;

              this.hasGloboRegionalChannels = hasGloboGeolocation;

              if (hasGloboGeolocation) {
                this.verifyUserPermissionAndPositions(isApp);
              }
            }
          },
          error: (error) => {
            console.error(error);

            this.hasGloboRegionalChannels = false;
          }
        });
    } else if (this.hasGloboRegionalChannels === true) {
      this.verifyUserPermissionAndPositions(isApp);
    }
  }

  public async verifyUserPermissionAndPositions(isApp: boolean) {
    if (isApp) {
      await this.verifyPermissionAndPositionsOnMobile();
    } else {
      await this.verifyPermissionAndPositionsOnWeb();
    }

    this.geolocationPositions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((positions) => {
        this.userIsInCoverageAreaService.initializeVerificationIsInCoverageArea(
          positions
        );
      });
  }
}
