<div class="container">
    <div class="modal-cancel" *ngIf="showModal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="theme.client != 'giga'">
                    Pronto, aproveite o melhor da {{ theme.client | titlecase }}!
                </h4>
            </div>
            <div class="modal-body">
                <img [src]="getFirstAccessImage()"
                    alt="image first access" />
                <div class="modal-subtitle">
                    <p>
                        E aí, curtiu?
                    </p>
                </div>
                <div class="notice">
                    <p>
                        Você também pode criar um novo perfil e<br> ajustar a faixa etária, ou torna-lo infantil.
                    </p>
                </div>
                <app-button (click)="closeModal()">Assistir agora</app-button>
            </div>
        </div>
    </div>
</div>


