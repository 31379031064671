<div class="section">
  <div class="section--header">
    <img
      *ngIf="sectionIcon"
      class="section--header--icon"
      [src]="sectionIcon"
      alt="data.image"
    />
    <h3>{{ sectionTitle }}</h3>
    <div class="section--header--content">
      <div class="button">
        <app-button
          *ngIf="sectionButtonText !== ''"
          [type]="'button'"
          [text]="sectionButtonText"
          [classList]="'btn-secondary'"
          (click)="goToSports()"
        ></app-button>
      </div>
    </div>
  </div>
  <swiper
    [slidesPerView]="'auto'"
    *ngIf="data?.length !== undefined"
    [navigation]="!checkMobile"
  >
    <ng-template swiperSlide *ngFor="let item of data">
      <a [routerLink]="['/details', item.id, item.type]">
        <app-sports-card
          [cardProperties]="cardProperties"
          [data]="item"
        ></app-sports-card>
      </a>
    </ng-template>
  </swiper>
</div>
