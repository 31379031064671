import { Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { NotificationListData } from '../../interfaces/notifications';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, DoCheck {
  public iconPath: string = '/assets/icons/';
  public isOpened: boolean = false;
  public notifications: NotificationListData[] = [];
  public onlyRead: boolean = false;
  public idPerfil: number = 0;
  public imagePath: string = environment.notificationService;
  private currentRoute: string = '';

  constructor(
    private notificationService: NotificationService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.idPerfil = parseInt(this.profileService.selectedProfile);

    if (!!this.idPerfil) {
      this.getPreferences(this.idPerfil);
    }
  }

  ngDoCheck(): void {
    if (window.location.pathname !== this.currentRoute && !!this.idPerfil) {
      this.currentRoute = window.location.pathname;
      this.getNotifications(this.idPerfil);
    }
  }

  private async setNotifications(newNotification: NotificationListData[]) {
    this.notifications = newNotification;
  }

  private getNotifications(idPerfil: number) {
    this.notificationService.getNotifications(idPerfil).subscribe({
      next: async (res) => {
        if (res.notifications.length > this.notifications.length) {
          this.setNotifications(res.notifications);
        }
      },
      error: (err) => console.error(err)
    });
  }

  private getPreferences(idPerfil: number) {
    this.notificationService.getNotificationsPreferences(idPerfil).subscribe({
      next: (res) => {
        if (res.success) {
          this.onlyRead = res.new_notifications;
        }
      }
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape' && this.isOpened) {
      this.isOpened = false;
    }
  }

  public bellIcon(): string {
    return this.isOpened
      ? this.iconPath + (environment.app ? 'app/notification-active.svg' : 'notification-active.svg')
      : this.iconPath + (environment.app ? 'app/notification-default.svg' : 'notification-default.svg');
  }

  public openNotifications() {
    this.isOpened = !this.isOpened;
  }

  public hasNewNotification(): boolean {
    return this.notifications.some((n) => !n.read);
  }

  private nowDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minutes}`;
  }
}
