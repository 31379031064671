<div *ngIf="showModalOnInit" class="geolocation-container" [ngClass]="{ 'hide': hideModal }">
  <div class="geolocation-container-modal">
    <div class="modal-header">
      <div class="icon-container">
        <img
          [src]="currentGeolocationOption.iconPath"
          class="icon"
          alt="Ícone de Localização"
        />
      </div>
    </div>
    <div class="modal-content">
      <h1 class="modal-title" [innerHTML]="currentGeolocationOption.modalTitle"></h1>
      <p class="modal-subtitle" [innerHTML]="currentGeolocationOption.modalSubtitle"></p>
      <div class="modal-btn-actions">
        <app-button *ngFor="let buttonOption of currentGeolocationOption.buttonOptions" [text]="buttonOption.label" [classList]="buttonOption.classList" (click)="callActionModal(buttonOption.onClickAction)"></app-button>
      </div>
    </div>
  </div>
</div>
