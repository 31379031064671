<div class="container">
  <a class="i-icon"><img src="assets/icons/info.svg" /></a>
  <div class="hide">
    <div class="bubble">
      {{ bubbleMessage }}
      <div class="ticket">
        <img src="assets/bg/ticket.svg" />
        <p class="ticket-text">{{ bubbleTicketText }}</p>
      </div>
    </div>
    <div class="pointer"></div>
  </div>
</div>
