import { GenericObject } from "./interfaces/generic";

export function slugify(text: string) {
  return text
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
}

export function sortChannelsBasedOnCoverageArea(channels: any[], userIsInCoverageArea: boolean, myRegionalChannelName: string | null) {
  return channels.filter((channel: any) => {
    return userIsInCoverageArea
      ? ['globo'].includes(channel.regional_type)
        ? channel.slug ===
          myRegionalChannelName
        : true
        : (channel.regional_type === null || channel.regional_type === '' || channel.regional_type === 'null');  });
}

export function objectArrayPluck(array: GenericObject[], property: string) {
  return array.map(value => value[property]);
}

export function getCouponsWithShortedUrl() {
  const couponsByRoute: any = {
    'promofamilia/cupom': [
      'MultiCelular7',
      'MultiTablet7',
      'MultiPC7',
      'MultiTV7',
      'flyerfamilia',
      'MultiEletro7',
      'multiemail',
      'Multi+som'
    ]
  };
  let routes: any[] = [];
  Object.keys(couponsByRoute).forEach(k => {
    return couponsByRoute[k].forEach((i: any) => {
      routes.push({ path: i, redirectTo: `/${k}/${i}` });
    });
  });
  return routes;
}

export function strIsEmpty(str?: string) {
  return !str || str.trim().length <= 0;
}

export function isValidEmail(str?: string) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(str || '');
}

export function isValidPassword(str?: string) {
  return [
    /[a-z]/.test(str ?? ''), /* pelo menos 1 letra minúscula */
    /[A-Z]/.test(str ?? ''), /* pelo menos 1 letra maiúscula */
    /[0-9]/.test(str ?? ''), /* pelo menos 1 número */
    /.{6,}/.test(str ?? '') /* 6+ caracteres */
  ].every(i => i);
}

export function isValidCEP(str?: string) {
  return /^\d{5}-?\d{3}$/.test(str ?? '');
}

export function isValidCPF(str?: string) {
  if (strIsEmpty(str)) {
    return false;
  }

  const cpf = str!.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let firstDigit = 11 - (sum % 11);
  if (firstDigit >= 10) {
    firstDigit = 0;
  }

  if (parseInt(cpf.charAt(9)) !== firstDigit) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let secondDigit = 11 - (sum % 11);
  if (secondDigit >= 10) {
    secondDigit = 0;
  }

  if (parseInt(cpf.charAt(10)) !== secondDigit) {
    return false;
  }

  return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(str!);
}

export function isValidPhone(str?: string) {
  return /\(\d{2}\) \d{4,5}-\d{4}/.test(str ?? '');
}

export function isValidFullName(str?: string) {
  return /^[a-zA-ZÀ-ÿ]+(\s+[a-zA-ZÀ-ÿ]+)+$/.test(str ?? '');
}

export function removeMask(value: string) {
  return value.replace(/\W/g, '');
}

export function setCookie(key: string, value: string) {
  document.cookie = `${key}=${value}; path=/;`;
}

export function getCookie(key: string) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === key) {
      return cookieValue;
    }
  }
  return null;
}

export function deleteCookie(key: string) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function isValidCardNumber(str?: string) {
  return /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(str || '');
}

export function isValidCVVCard(str?: string) {
  return /^\d{3,4}$/.test(str || '');
}

export function isValidMonthValidityCard(str?: string) {
  return /^(0[1-9]|1[0-2])$/.test(str || '');
}

export function isValidYearValidityCard(str?: string) {
  return /^\d{4}$/.test(str || '');
}

export function extractNumbersFromString(inputString: string) {
  const regex = /\d+/g;
  const numbersArray = inputString.match(regex);
  if (numbersArray) {
      return +numbersArray.join("");
  } else {
      return 0;
  }
}
