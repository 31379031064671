<div class="container">
  <div class="header" [class.padding]="hasPadding">
    <div class="row">
      <img *ngIf="hasCustomIcon" [src]="hasCustomIcon" width="170" />
      <h3 *ngIf="hasCustomIcon && !isNSportsCard">
        | {{ title || 'Sugestões' }}
      </h3>

      <h3 *ngIf="!hasCustomIcon">
        <ng-container *ngIf="theme.logoweb && hasSectionIcon"><img class="logo-icon"
            [src]="environment.bucket + 'logos/multi_mais.svg'" alt="logo-icon" />
        </ng-container>{{ title }}
      </h3>

      <div *ngIf="verMais" class="ver-mais" (click)="openCategory()">
        <app-button
          type="button"
          text="ver mais"
          classList="btn-secondary"
        ></app-button>
      </div>

      <app-button
        *ngIf="viewMore !== ''"
        type="button"
        text="ver mais"
        classList="btn-secondary"
        (click)="viewMoreClick()"
      ></app-button>
    </div>
    <div class="row">
      <p *ngIf="subtitle !== ''">{{ subtitle }}</p>
    </div>
  </div>
  <swiper
    ngif="keepWatchingList.length > 0"
    slidesPerView="auto"
    [navigation]="!checkMobile && hasNavigation"
    [allowTouchMove]="allowTouchMove"
    [loop]="true"
  >
    <ng-template swiperSlide *ngFor="let item of keepWatchingList">
      <div class="card horizontal">
        <img [src]="getImage(item)" class="card--image">
        <div class="card--details">
          <div class="card--details--row">
            <div class="horizontal-details">
              <div class="progress">
                <ng-container *ngIf="!isMobile && !cardOnly">
                  <app-censorship [age]="getParentalRate(item)"></app-censorship>
                  <p>{{ item.name }}</p>
                </ng-container>
    
                <ng-container *ngIf="isMobile && !cardOnly">
                  <app-censorship-small
                    [age]="getParentalRate(item)"
                  ></app-censorship-small>
                  <p>{{ item.name }}</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
