import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CurrentDay } from '../interfaces/current-day';

@Injectable({
  providedIn: 'root'
})
export class TimerService implements OnDestroy {
  private time: Date;
  private currentDay: CurrentDay = {};
  private timer: Subject<Date>;
  private daytimes: Subject<CurrentDay>;
  private repeater: any;

  constructor() {
    this.time = new Date();
    this.currentDay = {};
    this.timer = new Subject<Date>();
    this.daytimes = new Subject<CurrentDay>();
    this.repeater = setInterval(() => {});

    this.onInit();
  }

  public onInit(): void {
    this.repeater = setInterval(() => {
      this.time = new Date();
      this.timer.next(this.time);

      if (!this.currentDay.schedules) {
        this.updateCurrentDay(this.currentDay);
        this.daytimes.next(this.currentDay);
      } else {
        if (this.currentDay.currentScheduleIndex) {
          if (
            this.currentDay.schedules[
              this.currentDay.currentScheduleIndex
            ].getDate() !== this.time.getDate()
          ) {
            this.updateCurrentDay(this.currentDay);
            this.daytimes.next(this.currentDay);
          }
        }
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.repeater);
  }

  public handleTimer(): Observable<Date> {
    return this.timer.asObservable();
  }

  public handleDaytimes(): Observable<CurrentDay> {
    return this.daytimes.asObservable();
  }

  public updateCurrentDay(_day: CurrentDay): void {
    _day.schedules = this.getDaytimes();
      _day.schedules!.map((schedule, index) => {
        if (
          schedule.getHours() == this.time.getHours() &&
          schedule.getMinutes() <= this.time.getMinutes()
        ) {
          const year = new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric'
          }).format(this.time);

          const month = new Intl.DateTimeFormat('pt-BR', {
            month: '2-digit'
          }).format(this.time);

          const day = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit'
          }).format(this.time);

          _day.year = year;
          _day.month = month;
          _day.day = day;
          _day.date = `${year}-${month}-${day}`;
          _day.currentScheduleIndex = index;
        }
      });
  }

  private generateDaytimes(intervalInMinutes: number): Date[] {
    const now = new Date();
    const lastHours = now.getHours() - 2;
    const daytimes = new Array<Date>();
    let lastMinutes = 0;

    for (let i = 1; i < 300 / intervalInMinutes; i++) {
      const date = new Date();
      date.setHours(lastHours);
      date.setMinutes(lastMinutes);
      date.setSeconds(0);
      daytimes.push(date);
      lastMinutes += intervalInMinutes;
    }

    return daytimes;
  }

  private getDaytimes(): Date[] {
    const daytimes = this.generateDaytimes(30);
    return daytimes;
  }
}
