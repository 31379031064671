import { Component } from '@angular/core';
import { Theme } from '@src/assets/theme/theme';
@Component({
  selector: 'app-first-access-app-modal',
  templateUrl: './first-access-app-modal.component.html',
  styleUrls: ['./first-access-app-modal.component.scss']
})
export class FirstAccessAppModalComponent {
  public url:string = 'play.watch.tv.br';

  constructor(
    public theme: Theme
  ) {

    if(this.theme.client == 'ligga')
      this.url = 'play.liggavc.com.br';
    
    if(this.theme.client == 'giga')
      this.url = 'gigamais.tv.br';
    
    if(this.theme.client == 'ligga')
      this.url = 'play.liggavc.com.br';

      if(this.theme.client == 'vero')
      this.url = 'play.verovideo.com.br/welcome/vero';
  }
}
