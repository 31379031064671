import { ProfileStructureResponsev2 } from './../interfaces/profile-structure-response copy';
import { Injectable } from '@angular/core';
import { Http } from './http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProfileStructureResponse } from '../interfaces/profile-structure-response';
import { Profile } from '../interfaces/profile';
import { Router, Data } from '@angular/router';
import { CheckConnectionService } from './check-connection.service';

@Injectable({
  providedIn: 'root'
})

export class ProfileKtpService {

  public id_perfis: any;
  public ks_perfil: any;
  public uuid: any;
  public deviceId: any;
  public profileList = new BehaviorSubject<ProfileStructureResponse>({});
  public profileSelected: Profile = {};
  public oldProfileId: string = '';
  private storageProfile: Subject<string>;
  public storageProfileObservable: Observable<string>;
  public profilesObservable: Observable<ProfileStructureResponse> =
    new Observable<ProfileStructureResponse>();

  constructor(
    private http: Http,
    private router: Router,
    private checkConnectionService: CheckConnectionService
  ) {
    this.storageProfile = new Subject<string>();
    this.storageProfileObservable = new Observable<string>();
    this.onInitServices();
  }

  public onInitServices(): void {
    this.storageProfileObservable = this.storageProfile.asObservable();
    this.profilesObservable = this.profileList.asObservable();
  }

  public getUserProfileListV4(): Observable<ProfileStructureResponsev2> {
    return this.http.get(`oauth-api/user/profiles`);
  }

  public loadProfile(): boolean {
    const storage = localStorage.getItem('id_perfil');
    if (storage) {
      this.storageProfile.next('loaded');
      return true;
    }
    return false;
  }

  public logoutProfile(): void {
    localStorage.removeItem('id_perfil');
    this.storageProfile.next('removed');
  }

  public setProfileListV4(profiles: any) {
    profiles.data!.forEach((element: any) => {
      if (element.foto)
        element.foto = 'https://watchbr-resources.s3.amazonaws.com/perfil/' + element.foto!;
    });
    this.profileList.next(profiles);
  }

  public selectProfile(profileId: number | undefined) {
    if (profileId) {
      localStorage.setItem('id_perfil', profileId.toString());
      this.storageProfile.next('selected');
    }

    let connectMyProfileSub = this.checkConnectionService.connectMyProfile()
        .subscribe(() => {
          connectMyProfileSub.unsubscribe()
        })

    if(profileId){
      this.router.navigateByUrl('/home');
    }
  }

  public getProfile(): Observable<Profile> | any{
    let id = localStorage.getItem('id_perfil');
    this.getUserProfileListV4().subscribe((data) => {
      return data.data.find((it) => {
        return it.id_perfis == Number(id)
      })
    })
  }

  public unconnectProfile(): Observable<any> {
    const profileId = localStorage.getItem('id_perfis');
    const ks = localStorage.getItem('ks');
    const deviceId = localStorage.getItem('uuid');
    const requestBody = { id_perfis: profileId, ks, device_id: deviceId };

    return this.http.post("oauth-api/auth/unconnect/profile", requestBody);
  }

  public unconnectProfiles(): Observable<any> {
    const profileId = localStorage.getItem('id_perfis');
    const ks = localStorage.getItem('token');
    const deviceId = localStorage.getItem('uuid');
    const requestBody = { id_perfis: profileId, ks: ks, device_id: deviceId };

    return this.http.post("oauth-api/auth/unconnect/profiles", requestBody);
  }

  public getProfileData(profileId: any): Observable<any> {
    localStorage.setItem('uuid', this.generateDeviceUuid(6))
    localStorage.setItem('id_perfis', profileId)

    const requestBody = {
      id_perfis: profileId,
      device_brand_id: 22,
      device_id: localStorage.getItem('uuid')
    };

    return this.http.post("oauth-api/auth/connect/profile", requestBody);// vai conectar o profile
  }

  public saveKs(ks: any) {
    localStorage.setItem('ks', ks)
  }

  public get selectedProfile(): string {
    return localStorage.getItem('id_perfil')!;
  }
 
  public saveProfile(profile: Profile): Observable<any> {
    profile.kids = profile.age_bracket === '10' || profile.age_bracket === '12';
    if (profile.foto !== '') {
      return this.http.put('oauth-api/user/profile', profile);
    }
    return this.http.put('oauth-api/user/profile', {
      id_perfis: `${profile.id_perfis}`,
      age_bracket: profile.age_bracket,
      foto: '',
      nome: profile.nome,
      kids: profile.kids,
      audio_default: profile.audio_default,
      subtitle_default: profile.subtitle_default,
      live_content: profile.live_content,
      pin: ""
    });
  }
  
  public deleteProfile(id_perfil: number): Observable<any> {
    return this.http.delete(`oauth-api/user/profile/${id_perfil}`);
  }

  public saveUserId(userId: number): void {
    localStorage.setItem('user_id', userId.toString());
  }

  get userId(): string {
    return localStorage.getItem('user_id')!;
  }
 
  public createProfile(profile: Profile): Observable<any> {
    return this.http.post('oauth-api/user/profile', profile);
  }
  
  public generateDeviceUuid(parts: number): string {
    return `WAT_${localStorage.getItem('user_id')}_${localStorage.getItem('id_perfis')}_${navigator.platform.replace(/\s+/g, '_')}_${navigator.userAgent.split(' ')[0]}_${Array.from({ length: parts }).map(() => {
      let S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      return S4;
    }).join('-')}`;
  }
}