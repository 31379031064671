<div class="alert-pop-up-container" [ngClass]="{ hideClassName: hideModal }">
  <div class="alert-pop-up-container-modal">
    <div class="modal-header">
      <div class="icon-container">
        <img
          [src]="iconPath"
          class="icon"
          alt="Ícone de informação"
        />
      </div>
    </div>
    <div class="modal-content">
      <h1 class="modal-title" [innerHTML]="modalTitle"></h1>
      <p class="modal-subtitle" [innerHTML]="modalSubtitle"></p>
      <div class="modal-btn-actions">
        <app-button *ngFor="let buttonOption of buttonsOption" [text]="buttonOption.label" [classList]="buttonOption.classList" (click)="buttonOption.onClick()"></app-button>
      </div>
    </div>
  </div>
</div>
