import { Component, Input, OnInit } from '@angular/core';
import { EPGChannel } from '@app/shared/interfaces/program-details';
import { WatchService } from '../../../watch/shared/watch.service';
import { Units } from '@src/app/helpers/units';
import { Slider } from '@src/app/helpers/slider';

@Component({
  selector: 'app-tv-program-scheduled-section',
  templateUrl: './tv-program-scheduled-section.component.html',
  styleUrls: ['./tv-program-scheduled-section.component.scss']
})
export class TvProgramScheduledSectionComponent implements OnInit {
  @Input() public channel?: EPGChannel;
  @Input() public profileAgeBracket: string | number;
  @Input() public currentScheduleIndex: number;

  public blocked: boolean;
  public epgSectionList?: HTMLCollectionOf<Element>;

  constructor(
    private watchService: WatchService
  ) {
    this.blocked = true;
    this.profileAgeBracket = 0;
    this.currentScheduleIndex = 0;
  }

  ngOnInit(): void {
    this.epgSectionList = document.getElementsByClassName(
      'section--content--programs'
    );
    this.goToNow();
      }

  private goToNow() {
    if (this.epgSectionList) {
      const translate = (Units.totalPixelOfOneHour / 2) * this.currentScheduleIndex * -1;
      this.updateEPGTranslate(translate);
    }
  }

  private updateEPGTranslate(translate: number): void {
    if (this.epgSectionList) {
      for (let i = 0; i < this.epgSectionList.length; i++) {
        Slider.slideTo(this.epgSectionList[i], translate);
      }
    }
  }

  public goWatch(): void {
    if (this.channel?.id && !this.blocked) {
      this.watchService.watch(this.channel?.id, 'filme', true);
    }
  }

  public setBlockedChannel(event: boolean) {
    this.blocked = event;
  }
}
