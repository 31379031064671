<div #modal class="modal">
  <div class="modal--content">
    <p><ng-content></ng-content></p>
  </div>
  <div class="modal--options">
    <app-button
      classList="btn-secondary"
      (click)="cancel()"
      *ngIf="!confirmationOnly"
    >
      Cancelar
    </app-button>
    <app-button classList="btn-primary" (click)="confirm()">
      Confirmar
    </app-button>
  </div>
</div>
