import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GeolocationPositions, RegionalChannels } from '../interfaces/geolocation-modal';
import { Http } from './http.service';
import { objectArrayPluck, slugify } from '../utils';
import { Theme } from '@src/assets/theme/theme';

@Injectable({
  providedIn: 'root'
})
export class UserIsInCoverageAreaService implements OnDestroy {
  private userIsInCoverageAreaSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  userIsInCoverageArea$ = this.userIsInCoverageAreaSubject.asObservable();

  public regionalChannels: RegionalChannels[] | null = null;
  public userIsInCoverageAreaIntervals: any[] = [];
  public myRegionalChannelName: string | null = null;
  public readonly secondsToVerifyIfUserIsInCoverageArea: number = 60;

  private unsubscribe$: Subject<void> = new Subject<void>();

  get userIsInCoverageArea(): any {
    return this.userIsInCoverageAreaSubject.value;
  }

  set userIsInCoverageArea(value: any) {
    this.userIsInCoverageAreaSubject.next(value);
  }

  constructor(private http: Http, private theme: Theme) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.clearAllUserIsInCoverageAreaIntervals();
  }

  public initializeVerificationIsInCoverageArea(
    geolocationPositions: GeolocationPositions | null
  ) {
    if (!geolocationPositions) return;

    if (!this.regionalChannels) {
      this.http
        .post('v3/regional-channels', {
          origin: this.theme.client
        })
        .subscribe({
          next: (globoRegionalChannels: any) => {
            this.regionalChannels =
              (globoRegionalChannels?.channels as RegionalChannels[]) ?? [];
            this.userIsInCoverageArea =
              (globoRegionalChannels?.has_access as boolean) ?? false;

            this.startVerificationUserIsInCoverageArea(geolocationPositions);
          },
          error: (error) => {
            console.error(error);
            this.userIsInCoverageArea = false;
            this.regionalChannels = [];
          }
        });
    } else {
      this.startVerificationUserIsInCoverageArea(geolocationPositions);
    }
  }

  private startVerificationUserIsInCoverageArea(
    geolocationPositions: GeolocationPositions
  ) {
    this.verifyIfIsInCoverageArea(geolocationPositions);

    const newUserIsInCoverageAreaInterval = setInterval(() => {
      this.verifyIfIsInCoverageArea(geolocationPositions);
    }, this.secondsToVerifyIfUserIsInCoverageArea * 1000);

    this.clearAllUserIsInCoverageAreaIntervals();
    this.userIsInCoverageAreaIntervals.push(newUserIsInCoverageAreaInterval);
  }

  public clearAllUserIsInCoverageAreaIntervals() {
    for (const intervalId of this.userIsInCoverageAreaIntervals) {
      clearInterval(intervalId);
    }

    this.userIsInCoverageAreaIntervals = [];
  }

  private verifyRegionalChannelIsInGloboRegionalChannels(regionalChannelName: string | null) {
    if (regionalChannelName) {

      if (this.regionalChannels) {
        const regionalChannelsNames = objectArrayPluck(this.regionalChannels, 'slug');

        this.userIsInCoverageArea = regionalChannelsNames.includes(regionalChannelName);
      }

    } else {
      this.userIsInCoverageArea = false;
    }
  }

  private verifyIfIsInCoverageArea(geolocationPositions: GeolocationPositions) {
    const { latitude, longitude } = geolocationPositions;
   
    fetch(
      `https://affiliates.video.globo.com/affiliates/info?lat=${latitude}&long=${longitude}`
    )
      .then((response) => response.json())
      .then(
        (affiliateRegionalChannel) => {
          if (affiliateRegionalChannel && ('name' in affiliateRegionalChannel)) {
            this.myRegionalChannelName = slugify(affiliateRegionalChannel.name);
          }

          this.verifyRegionalChannelIsInGloboRegionalChannels(this.myRegionalChannelName);
        }
      )
      .catch((err) => {
        console.log(err);
        this.userIsInCoverageArea = false;
      });
  }
}
