<div class="container">
  <div class="modal">
    <div class="close-button" (click)="closeButton()">
      X
    </div>
    <h1 class="title">Poxa, que pena!</h1>
    <p class="subtitle">Este adicional não faz parte do seu plano</p>
    <p class="description">acesse nossa tela de planos para contratar e tenha os conteúdos disponibilizados na hora</p>
    <div class="button" (click)="changePlan()">
      <p>Conferir planos</p>
    </div>
  </div>
</div>
