import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { Slider } from '../helpers/slider';
import { Units } from '../helpers/units';
import { EPGCategory, EPGChannel, EPGListResult } from '../shared/interfaces/program-details';
import { CheckDeviceService } from '../shared/services/check-device.service';
import { NowService } from '../shared/services/now.service';
import { ProfileService } from '../shared/services/profile.service';
import { TimerService } from '../shared/services/timer.service';
import { WatchService } from '../watch/shared/watch.service';
import { HomeService } from '../shared/services/home.service';
import { MainStructure } from '../shared/interfaces/main-structure';
import { CardProperties } from '../shared/interfaces/card-default';
import { Theme } from 'src/assets/theme/theme';
import { environment } from 'src/environments/environment';
import { UserIsInCoverageAreaService } from '../shared/services/user-is-in-coverage-area.service';
import { GeolocationService } from '../shared/services/geolocation.service';
import { Subject, takeUntil } from 'rxjs';
import { sortChannelsBasedOnCoverageArea } from '../shared/utils';
import { vero } from '../profile/edit/profiles-images';

@Component({
  selector: 'app-now',
  templateUrl: './now.component.html',
  styleUrls: ['./now.component.scss'],
  providers: [TimerService]
})
export class NowComponent implements OnInit, OnDestroy {
  public timer: Date;
  public currentScheduleIndex: number;
  public horizontalTimelineCurrentSlideX: number;
  public isEPGListFirstLoading: boolean = true;
  public isUpdateEPGListLoading: boolean = false;
  public canLoadWatchedChannelsRecently: boolean = false;
  public epgSectionList?: HTMLCollectionOf<Element>;
  public profileAgeBracket: string | number;
  public isMobile: boolean;
  public epgList: EPGChannel[] = [];
  public timesRange: Date[] = [];
  public filteredEpgList: EPGChannel[] = [];
  public idPerfil: number = 0;
  public cardProperties: CardProperties;
  public isAmericaNet: boolean = false;
  public channelsCategories: EPGCategory[] = [];
  public selectedCategoryId: number = 0;
  public selectedCategoryName: string = 'Todos os canais';
  public isApp: boolean = environment.app;
  public totalPages: number = 1;
  public page: number = 1;
  public showRightButton: boolean = true;
  private readonly rightSpaceDiff: number = 83;
  public userIsInCoverageArea: boolean = true;
  public myRegionalChannelName: string | null = null;
  public hasGloboRegionalChannels: boolean | null = null;
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('timeline')
  public verticalTimeline?: ElementRef<HTMLDivElement>;

  @ViewChild('schedules')
  public horizontalTimeline?: ElementRef<HTMLDivElement>;

  @ViewChild('channelsFilter')
  public channelsFilter?: ElementRef<HTMLDivElement>;

  @HostListener('window: resize', ['$event'])
  public onResize(): void {
    this.goToNow();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft': {
        this.goBack();
        break;
      }
      case 'ArrowRight': {
        this.goNext();
        break;
      }
    }
  }

  constructor(
    private timerService: TimerService,
    private nowService: NowService,
    private watchService: WatchService,
    private profileService: ProfileService,
    private homeService: HomeService,
    public theme: Theme,
    private checkDeviceService: CheckDeviceService,
    private geolocationService: GeolocationService,
    private userIsInCoverageAreaService: UserIsInCoverageAreaService
  ) {
    this.timer = new Date();
    this.currentScheduleIndex = 0;
    this.horizontalTimelineCurrentSlideX = 0;
    this.profileAgeBracket = 0;
    this.isMobile = false;
    this.cardProperties = {
      orientation: 'vertical',
      channelLogo: false,
      hasWatchLogo: false,
      smallCard: false,
      movieLogo: false,
      indicativeAge: false,
      movieTitle: false,
      hasProgressBar: false,
      sportTitle: false,
      isLive: false,
      secondDescription: false,
      day: false,
      rentable: false,
      rentMovieTitle: false
    };
  }

  ngOnInit(): void {
    this.idPerfil = parseInt(this.profileService.selectedProfile);
    this.startTimer();
    this.onInitEPG();
    this.setAgeBracket();

    this.homeService
      .getMainList(this.idPerfil)
      .subscribe((data: MainStructure) => {
        this.isAmericaNet = data.isAmericanet!;
      });

    this.isMobile = this.checkDeviceService.isMobile();
    this.epgSectionList = document.getElementsByClassName(
      'section--content--programs'
    );

    if (['watch', 'ligga', 'giga', 'vero', 'voa'].includes(this.theme.client)) {
      this.geolocationService.verifyUserHasGloboRegionalChannels(this.isApp);

      this.userIsInCoverageAreaService
        .userIsInCoverageArea$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(userIsInCoverageArea => {
          this.userIsInCoverageArea = userIsInCoverageArea;
          this.myRegionalChannelName = this.userIsInCoverageAreaService.myRegionalChannelName;
          this.hasGloboRegionalChannels = this.geolocationService.hasGloboRegionalChannels;
          this.filterChannelsByCategory(this.selectedCategoryId, this.selectedCategoryName);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.userIsInCoverageAreaService.clearAllUserIsInCoverageAreaIntervals();
  }

  public filterChannelsByCategory(
    category: number,
    categoryName: string,
    byClick: boolean = false
  ): void {
    this.selectedCategoryId = category;
    this.selectedCategoryName = categoryName;

    let allChannelsFilteredByCoverageArea = this.filteredEpgList;

    if (['watch', 'ligga', 'giga', 'vero', 'voa'].includes(this.theme.client)) {
      allChannelsFilteredByCoverageArea = sortChannelsBasedOnCoverageArea(this.filteredEpgList, this.userIsInCoverageArea, this.myRegionalChannelName);
    }

    this.epgList = allChannelsFilteredByCoverageArea.filter((channel: EPGChannel) => {
      return category !== 0 ? channel.genre_id === category : true;
    });

    if (byClick) {
      this.channelsFilter?.nativeElement.classList.remove('active');
      this.goToNow();
    }
  }

  public startTimer(): void {
    this.timerService.handleTimer().subscribe((timer) => {
      this.timer = timer;
    });
  }

  public onInitEPG(): void {
    this.getFirstLoadEPGList();
  }

  public onInitHorizontalTimeline(dateList: Date[]): void {
    if (dateList && dateList.length) {
      dateList.forEach((date, index) => {
        if (date.getHours() === this.timer.getHours()) {
          if (date.getMinutes() <= this.timer.getMinutes()) {
            this.currentScheduleIndex = index;
            if (this.horizontalTimeline) {
              this.goToNow();
            }
          }
        }
      });
    }
  }

  public goToNow() {
    this.goTo(this.currentScheduleIndex);
  }

  public goTo(index: number): void {
    if (this.epgSectionList && this.horizontalTimeline) {
      const translate = (Units.totalPixelOfOneHour / 2) * index * -1;
      Slider.slideTo(this.horizontalTimeline, translate);
      this.updateEPGTranslate(translate);
      this.horizontalTimelineCurrentSlideX = translate;
    }
  }

  private getMaxSlideX() {
    if (this.horizontalTimeline) {
      return (this.horizontalTimeline.nativeElement.scrollWidth - this.horizontalTimeline.nativeElement.clientWidth - 150) * -1;
    }
    return 0;
  }

  public moveAll(translateX: number, direction: 'right' | 'left' | 'none' = 'none'): void {
    if (this.epgSectionList && this.horizontalTimeline) {
      const slideX = 100;
      const minSlideX = slideX * -1;
      const maxSlideX = this.getMaxSlideX();
      const maxLimitRight = -maxSlideX - this.rightSpaceDiff;
      const directions = {
        'right': this.horizontalTimelineCurrentSlideX - slideX >= maxSlideX,
        'left': this.horizontalTimelineCurrentSlideX - slideX <= minSlideX,
        'none': false
      };
      const canDrag = directions[direction];

      if (-translateX > maxLimitRight) {
        translateX = -maxLimitRight;
      }

      this.showRightButton = -translateX + (this.rightSpaceDiff - 27) < maxLimitRight;

      if (canDrag) {
        Slider.slideTo(this.horizontalTimeline, translateX);
        this.updateEPGTranslate(translateX);
        this.horizontalTimelineCurrentSlideX = translateX;
      }
    }
  }

  public goNext(): void {
    if (this.showRightButton && this.epgSectionList && this.horizontalTimeline) {
      const slideX = 100;
      const maxSlideX = this.getMaxSlideX();
      const maxLimitRight = -maxSlideX - this.rightSpaceDiff;
      const translate = (this.horizontalTimelineCurrentSlideX - slideX);

      this.moveAll(
        -translate + slideX > maxLimitRight ? maxSlideX : translate,
        'right'
      );
    }
  }

  public goBack(): void {
    if (this.epgSectionList && this.horizontalTimeline) {
      const slideX = 100;
      const maxSlideX = 0;

      if (this.horizontalTimelineCurrentSlideX + slideX <= maxSlideX) {
        const translate = (this.horizontalTimelineCurrentSlideX += slideX);
        Slider.slideTo(this.horizontalTimeline, translate);

        this.updateEPGTranslate(translate);
      }
    }
  }

  public updateEPGTranslate(translate: number): void {
    if (this.epgSectionList) {
      for (let i = 0; i < this.epgSectionList.length; i++) {
        Slider.slideTo(this.epgSectionList[i], translate);
      }
    }
  }

  public openChannels(): void {
    if (this.channelsFilter) {
      this.channelsFilter.nativeElement.classList.toggle('active');
    }
  }

  public goWatch(channelId?: number): void {
    if (channelId) this.watchService.watch(channelId, 'filme', true);
  }

  public setAgeBracket(): void {
    this.profileService.profilesObservable.subscribe((profiles) => {
      let selectedProfile = profiles.success?.data!.find((profile) => {
        return (
          profile.id_perfis == parseInt(this.profileService.selectedProfile)
        );
      });
      if(selectedProfile != undefined)
        this.profileAgeBracket = selectedProfile!.age_bracket || 18;
    });
  }

  public sortFilteredChannels() {
    const listAtTop = [1594,1598,1596,1597,1593,1595,1616,1563,1564,1565,1566,1570,1571,1572];
    // armazenando itens que vão ficar no topo
    const topList = this.filteredEpgList.filter(i => listAtTop.indexOf(i.id) !== -1);
    // removendo da listagem principal os itens que vão ficar no topo
    this.filteredEpgList = this.filteredEpgList.filter(i => listAtTop.indexOf(i.id) === -1);
    // fazendo ordenação por título dos itens restantes
    this.filteredEpgList.sort((a, b) => {
      const firstChannelName = a?.title?.toLowerCase(), secondChannelName = b?.title?.toLowerCase();
      return firstChannelName == secondChannelName ? 0 : firstChannelName > secondChannelName ? 1 : -1;
    });
    // concatenando itens do topo com o restante dos canais
    this.filteredEpgList = topList.concat(this.filteredEpgList);
    this.filterChannelsByCategory(this.selectedCategoryId, this.selectedCategoryName);
  }

  public restartEPGList(restartEPGList: boolean) {
    if (!restartEPGList) {
      return;
    }

    this.isUpdateEPGListLoading = true;
    return this.getFirstLoadEPGList();
  }

  private getEPGList() {
    return this.nowService.getEPGList(this.idPerfil, this.page).subscribe({
      next: (response: EPGListResult) => {
        this.setEPGPrograms(response.data.programs);
        this.channelsCategories = response.data.categories;
        this.channelsCategories.unshift({
          name: 'Todos os canais',
          genre_id: 0
        });

        if (this.isEPGListFirstLoading) {
          this.timesRange = response.data.timesRange.map(time => new Date(time));

          this.totalPages = response.data.totalPagesPerProgram;
          setTimeout(() => this.onInitHorizontalTimeline(this.timesRange), 250);
        }

        this.page++;

        if (this.page <= this.totalPages) {
          this.getEPGList();
        } else {
          this.canLoadWatchedChannelsRecently = true;
        }
      },
      error: (error) => console.log(error),
      complete: () => {
        this.isEPGListFirstLoading = false;
        this.isUpdateEPGListLoading = false;
      }
    });
  }

  private setEPGPrograms(programs: EPGChannel[]) {
    this.filteredEpgList.push(...programs);
    this.sortFilteredChannels();
  }

  private getFirstLoadEPGList() {
    this.epgList = [];
    this.filteredEpgList = [];
    this.channelsCategories = [];
    this.page = 1;

    return this.getEPGList();
  }
}
