<div class="container">
  <img class="img-banner"
    [src]="environment.bucket + 'directvgo/carrosel-directvgo.png'" />
  <div class="grid">
    <div class="background"></div>
    <div class="texts">
      <h3 class="title">Exclusivos DGO</h3>
      <p class="subtitle" *ngIf="!text">
        Aqui na {{ theme.clientName | titlecase }} você pode contratar conteúdos de parceiros! Explore agora
        mesmo os conteúdos DGO
      </p>
      <p class="subtitle" *ngIf="text" [innerHTML]="text"></p>
      <div class="btn">
        <app-button *ngIf="hasExploreButton" class="button" text="Explorar" type="button" classList="btn-primary"
          (click)="goTo('directvgo')"></app-button>
      </div>
    </div>
    <div class="section">
      <app-section [hasNavigation]="hasNavigation" [allowTouchMove]="allowTouchMove" [properties]="cardProperties"
        [isExclusiveContent]="true" [data]="dataCards" labelExclusiveContent="Direct TV GO" [cardOnly]="true">
      </app-section>
    </div>
  </div>
</div>
