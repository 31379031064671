<div class="container">
  <div class="modal-cancel" *ngIf="showModal">
    <div class="modal-content">
      <div class="modal-header">
        <img class="modal-close" src="assets/icons/close_small_icon.svg" alt="fechar" (click)="closeModal()" />
      </div>
      <div class="modal-body">
        <img class="cancel-logo" src="assets/icons/cancel.svg" alt="logo-cancelar" />
        <div class="modal-title">
          <h4 *ngIf="!cancellationConfirmed">
            Suas assinaturas foram canceladas!
          </h4>
  
        </div>
        <div class="modal-subtitle">
          <p>
            Você receberá um email com a confirmação do cancelamento.
          </p>
        </div>
        <div class="notice">
          <p>
            *Para período de degustação (7 dias) cancelamento imediato.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>