import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-directvgo-secao-info',
  templateUrl: './directvgo-secao-info.component.html',
  styleUrls: ['./directvgo-secao-info.component.scss']
})
export class DirectvgoSecaoInfoComponent {
  constructor() {}
}
