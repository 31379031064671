import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-rent',
  templateUrl: './modal-rent.component.html',
  styleUrls: ['./modal-rent.component.scss']
})
export class ModalRentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public showModal: boolean = true;

  
  public closeModal(): void {
    this.showModal = false;
  }

}
