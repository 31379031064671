import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ChoosePlanAlacarte } from '../../interfaces/choose-plan-alacarte';

@Component({
  selector: 'app-choose-payment-alacarte',
  templateUrl: './choose-payment-alacarte.component.html',
  styleUrls: ['./choose-payment-alacarte.component.scss']
})
export class ChoosePaymentAlacarteComponent implements OnInit {
  @Input() public data?: ChoosePlanAlacarte;
  @Output() public setShowModal = new EventEmitter<boolean>();

  public selectedSide: string = 'left';
  public rotate: any = {
    left: true,
    right: false
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public select(side: string): void {
    this.selectedSide = side;
  }

  public submit() {
    if (this.selectedSide === 'left') {
      this.router.navigateByUrl(this.data?.left?.url || '');
    } else {
      localStorage.setItem('showGreenBorder', '1');
      this.router.navigateByUrl(this.data?.right?.url || '');
    }
  }

  public closeModal() {
    this.setShowModal.emit(false);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape' && this.setShowModal) {
      this.closeModal();
    }
  }
}
