import { Component, HostListener, Input, OnInit } from '@angular/core';
import { WatchService } from '../../../watch/shared/watch.service';

@Component({
  selector: 'app-card-rounded',
  templateUrl: './card-rounded.component.html',
  styleUrls: ['./card-rounded.component.scss']
})
export class CardRoundedComponent implements OnInit {
  private pathChannelIcons: string;

  @Input() public channel: any;
  @Input() public profileAgeBracket: number;
  @Input() public disableClick: boolean = false;

  public blockChannel: boolean;
  public censorship: number;
  public lockImgSrc: string;

  constructor(private watchService: WatchService) {
    this.pathChannelIcons =
      'https://watchbr-resources.s3.amazonaws.com/channels/';
    this.censorship = 0;
    this.profileAgeBracket = 0;
    this.blockChannel = false;
    this.lockImgSrc = '/assets/icons/lock.svg';
    this.channel = {};
  }

  ngOnInit(): void {
    this.channel.channelBrand = this.channel.white;
    this.setCensorship();
  }

  public watch(): void {
    if (this.disableClick) return;
    if (!this.blockChannel) {
      this.watchService.watch(this.channel.id!, 'filme', true);
    }
  }

  private extractNumbersFromString(inputString: string) {
    const regex = /\d+/g;
    const numbersArray = inputString.match(regex);
    if (numbersArray) {
        return +numbersArray.join("");
    } else {
        return 0;
    }
}

  public setCensorship() {
    if (
      !this.channel.ratingCode ||
      this.channel.ratingCode === 'L'
    ) {
      this.censorship = 0;
      return;
    }
    this.censorship = this.extractNumbersFromString(this.channel.ratingCode);
    this.blockChannel = this.censorship > this.profileAgeBracket;
  }
}
