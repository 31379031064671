<div class="container" [ngClass]="{'padding': isMobile && !data}">
  <div class="container-top" *ngIf="svgTop && !isMobile">
    <div class="background">
      <img class="img-banner" [src]="svgTop" />
    </div>
  </div>

  <!--IMAGENS SVG-->
  <div class="container-bottom">
    <div class="background" *ngIf="svg && isMobile && !data">
      <img class="img-banner" [src]="svg" />
    </div>
    <div class="background" *ngIf="svg && !isMobile">
      <img class="img-banner" [src]="svg" />
    </div>
    <!--OUTRAS FORMAS DE IMAGEM-->
    <div class="background" *ngIf="hasCustomBanner && !checkMobile && !svg">
      <img class="img-banner" [src]="hasCustomBanner" />
    </div>

    <div class="header" [class.padding]="hasPadding">
      <div class="row">
        <img *ngIf="hasCustomIcon" [src]="hasCustomIcon" width="170" />
        <h3 *ngIf="title && !hasCustomIcon && !titleBanner && !isMobile">
          {{ title }}
        </h3>
        <h3 *ngIf="title && hasCustomIcon && !titleBanner && !isMobile">
          | {{ title }}
        </h3>
        <h3 *ngIf="title && !hasCustomIcon && isMobile">
          {{ title }}
        </h3>
        <h3 *ngIf="title && hasCustomIcon && isMobile">
          | {{ title }}
        </h3>

        <div *ngIf="verMais && !buttonText" class="ver-mais" (click)="openCategory()">
          <app-button type="button" text="ver mais" classList="btn-secondary"></app-button>
        </div>

        <div *ngIf="verMais && buttonText && isMobile" class="ver-mais" (click)="openCategory()">
          <app-button type="button" text="ver mais" classList="btn-secondary"></app-button>
        </div>
      </div>
    </div>

    <div class="textCenter" *ngIf="textCenter">
      <h3 [innerHTML]="textCenter"></h3>
    </div>

    <div class="grid" *ngIf="!textCenter">
      <div class="texts" *ngIf="buttonText && !isMobile">
        <h3 class="title" [innerHTML]="titleBanner"></h3>
        <p class="subtitle" [innerHTML]="subTitleBanner"></p>
        <div class="btn">
          <app-button *ngIf="true" class="button" type="button" classList="btn-primary" (click)="goTo()">{{buttonText}}
          </app-button>
        </div>
      </div>

      <div class="section" *ngIf="data">
         <app-section 
        [hasNavigation]="hasNavigation" 
        [goToWatch]="true" 
        [properties]="properties" 
        [data]="data"
        [cardOnly]="true"
        [isInfinity]="true"
        [isLandingPage]= true>
        </app-section> 
      </div>
    </div>
  </div>
</div>