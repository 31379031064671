<header>
  <div id="content__header">
    <div id="logo__container">
      <img [src]="environment.bucket + 'logos/logo-multi-roxo.png'" />
    </div>
    <div id="promo__description__container">
      <div class="descriptions">
        <h1>
          Você ganhou {{ countDays }} DIAS de Multi+ Família
          <sup>*</sup>
        </h1>
        <p>Digite seu cupom no campo ao lado e aproveite.</p>
      </div>
      <div class="input-wrapper">
        <input type="text" name="code" maxlength="10" [(ngModel)]="code" (input)="disableBtn = code.trim().length < 4">
        <p *ngIf="errorMessage.trim().length > 0">{{ errorMessage }}</p>
      </div>
    </div>
    <div id="promo__button__container">
      <app-button
        class="primary-button"
        type="button"
        [text]="btnText"
        [disabled]="disableBtn"
        (click)="sendCode()"
      ></app-button>
    </div>
  </div>
</header>
