import { style } from '@angular/animations';
import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import { Theme } from 'src/assets/theme/theme';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-load-watch',
  templateUrl: './load-watch.component.html',
  styleUrls: ['./load-watch.component.scss']
})
export class LoadWatchComponent implements OnInit {

  @Input() isLoaded: boolean = false;
  public isLoading: boolean = true;
  public screenX: number = 0;
  public folderName: string = 'load-watch';

  constructor(
    private cd: ChangeDetectorRef,
    private theme: Theme
  ) { }

  @HostListener('window: resize', ['$event'])
  public onResize() {
    this.screenX = window.innerWidth;
  }

  ngOnInit() {
    this.folderName = `load-${this.theme.client}`;
  }

  ngOnChanges() {
    if (this.isLoaded) {
      let _this = this;
      this.setOpacity('1');
      setTimeout(function() {
        _this.isLoading = !_this.isLoaded;
        _this.cd.detectChanges();
      }, 500);
    }
  }

  setOpacity(value: string) {
    try{
      document.getElementById("load-bg")!.style.opacity = value;
    }catch(err){}
  }
}
