<button
  class="button"
  type="button"
  (click)="click()"
>
  <img
    class="icon"
    [src]="icon"
    alt=""
  />
  Assistir
</button>
