import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastifyStatus } from '../../interfaces/generic';

@Component({
  selector: 'app-toastify',
  templateUrl: './toastify.component.html',
  styleUrls: ['./toastify.component.scss']
})
export class ToastifyComponent implements OnInit, OnChanges {
  @Input() message: string = '';
  @Input() status: ToastifyStatus = 'error';
  public iconSource: string = '/assets/icons/alert_icon.svg';
  public showToastify: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.initToastify();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']) {
      this.initToastify();
    }
  }

  private changeIconSourceBasedOnStatus() {
    switch(this.status){
      case 'success':
        this.iconSource = '/assets/icons/check_white.svg';
      break;
      case 'error':
        this.iconSource = '/assets/icons/alert_icon.svg'
      break;
    }
  }

  private initToastify() {
    this.changeIconSourceBasedOnStatus();
    this.showToastify = true;

    const timeOut = setTimeout(() => {
      this.showToastify = false;
      clearTimeout(timeOut);
    }, 7000);
  }

}
