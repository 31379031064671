import { ChromecastService } from './../shared/services/chromecast.service';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  Renderer2,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { Episode } from '../shared/interfaces/episode';
import { Language } from '../shared/interfaces/language';
import { Movie, SeriesSeasons } from '../shared/interfaces/movie';
import { SeasonAndEpisode } from '../shared/interfaces/season-and-episode';
import { Source } from '../shared/interfaces/source';
import { CheckConnectionService } from '../shared/services/check-connection.service';
import { MoviesSeriesService } from '../shared/services/movies-series.service';
import { NextEpisodeService } from './shared/next-episode.service';
import { LanguageSelectorComponent } from './../shared/components/language-selector/language-selector.component';
import { VideoQualityComponent } from './../shared/components/video-quality/video-quality.component';
import { ProfileService } from './../shared/services/profile.service';
import { ProgressService } from './../shared/services/progress.service';
import { debounceTime, Subject, Observable, Subscription, takeUntil } from 'rxjs';
import { WatchService } from './shared/watch.service';
import { Location } from '@angular/common';
import { CheckDeviceService } from '../shared/services/check-device.service';
import { Theme } from 'src/assets/theme/theme';
import { UserDataServiceService } from '../shared/services/user-data-service.service';
import { UserData } from '../shared/interfaces/user-data';
import { CheckContentService } from '../shared/services/check-content.service';
import { UserIsInCoverageAreaService } from '../shared/services/user-is-in-coverage-area.service';
import { GeolocationService } from '../shared/services/geolocation.service';
import { AlertPopUpButtonOptions, GenericObject } from '../shared/interfaces/generic';
import { ProfilePreferences, SetProfilePreferencesPayload } from '../shared/interfaces/profile-preferences';
import { ProfilePreferencesService } from '../shared/services/profile-preferences.service';
import { ScreenOrientation, OrientationType } from '@robingenz/capacitor-screen-orientation';

declare let window: any;
declare let KalturaPlayer: any;
@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent implements OnInit, OnDestroy {
  public environment: any = environment;
  public kaltura: any;
  public id_kaltura?: number;
  public position: number = 0;
  public id?: number;
  public type?: string;
  public movie?: Movie;
  public serieData: Movie = {};
  public seasonIndex: number = 0;
  public episode: Episode = {};
  public nextEpisode: Episode = {};
  public showNextEpisode: boolean = false;
  public nextEpisodeAvailable: boolean = false;
  public screen_x: number = 0;
  public actualTime: string = '00:00:00';
  public fullTime: string = '00:00:00';
  public openVolume: boolean = false;
  public isPlaying: boolean = false;
  public isLive: boolean = false;
  public loading: boolean = true;
  public inactive: boolean = false;
  public initialPosition: number = 0;

  public isPlayOnChromecast: boolean = false;

  public audioPack: Language[] = [];
  public subtitlePack: Language[] = [];
  public languagesLoaded: boolean = false;
  public sources: Source[] = [];
  public videoQualitiesLoaded: boolean = false;
  public now: number = 0;
  public tempoAtual: string = '';
  public idPerfil: number = 0;
  public intervalSaveProgress: any = 0;
  public previousVolume: number = 0;
  public muted: boolean = false;
  public timerSubscription: Subscription = new Subscription();

  public playerLoaded: boolean = false;

  private subscribeCurrentTime: any;
  public details: any = { id_kaltura: 0 };
  public isMarathon: boolean = false;
  public isNovela: boolean = false;

  private isWatching: boolean = true;
  private sendProgressTimeout: any;
  private checkConnectionTimeout: any;
  private checkLimitParamountTimeout: any;

  public isSafari = false;

  public chromeCastDevicesAvailable: Observable<boolean> = new Observable();
  public playOnChromecast: boolean = false;

  public isMobile: boolean = false;
  public appleDevice: boolean = false;
  public mobileApple: boolean = false;
  public macOS: boolean = false;
  public UserData: any = [];
  public company: string = '';
  public id_isp: string = '';
  public user_id: string = '';
  public id_perfis: string = '';

  public isRunningOnChromecast: boolean = false;
  public preRollActive: boolean = true;
  public limitContent: boolean = false;
  public limitContentLoop: number = 0;
  public idParamountLoop: number | undefined = 0;
  public limitContentMessage: string = '';
  public playerUnavailable: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasSubtitles: boolean = false;
  public hasAudios: boolean = false;
  public isLiveAndApp: boolean = false;
  public showModalBBB: boolean = false;
  public modalBBBTitle: string = '';
  public modalBBBDescription: string = '';

  public currentTimeCast: number = 0;
  public tempoAtualChromeCast: string = '00:00:00';

  public alertPopUpButtonOptions: AlertPopUpButtonOptions[] = [
    {
      label: 'Ok, voltar',
      classList: 'btn-secondary',
      onClick: () => this.returnPrevious()
    }
  ];

  public audioSelected: string = '';
  public subtitleSelected: string = '';
  public profilePreferences: ProfilePreferences | null = null;

  public alertPopUpButtonOptionsBBB: AlertPopUpButtonOptions[] = [
    {
      label: 'Ok',
      classList: 'btn-primary',
      onClick: () => this.hideModalBBB()
    }
  ];

  @ViewChild('audioOptions') audioOptions!: LanguageSelectorComponent;
  @ViewChild('videoOptions') videoOptions!: VideoQualityComponent;
  @ViewChild('btnFullScreen') btnFullScreen!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private mediaService: MoviesSeriesService,
    private deviceService: DeviceDetectorService,
    private nextEpisodeService: NextEpisodeService,
    private profileService: ProfileService,
    private progressService: ProgressService,
    private watchService: WatchService,
    private checkConnectionService: CheckConnectionService,
    private chromecastService: ChromecastService,
    public location: Location,
    private checkDeviceService: CheckDeviceService,
    private router: Router,
    public theme: Theme,
    private UserDataServiceService: UserDataServiceService,
    private CheckContentService: CheckContentService,
    private geolocationService: GeolocationService,
    private userIsInCoverageAreaService: UserIsInCoverageAreaService,
    private profilePreferencesService: ProfilePreferencesService,
    private renderer: Renderer2
  ) {
    this.sendProgressTimeout = this.sendProgress();
    this.checkConnectionTimeout = this.checkConnection();
    this.appleDevice = this.watchService.checkAppleDevice();
    this.macOS = this.watchService.checkMacOs();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case ' ':
        this.togglePlay();
        break;

      case 'ArrowLeft':
        this.handleRewind();
        break;

      case 'ArrowRight':
        this.handleForward();
        break;
    }
  }

  @HostListener('window: resize', ['$event'])
  public onResize(): void {
    this.screen_x = window.innerWidth;
  }

  private inactiveSubject: Subject<any> = new Subject();
  @HostListener('document: mousemove', ['$event'])
  public onMouseMove(): void {
    this.inactive = false;
    this.inactiveSubject.next(true);
  }

  hideModalBBB()
  {
    this.renderer.setStyle(document.body, 'overflow-y', 'auto');
    this.showModalBBB = false;
  }

  ngOnInit() : void {
    if(this.appleDevice){
      ScreenOrientation.lock({ type: OrientationType.LANDSCAPE });
    }
    this.watchService.fullScreen(true)

    this.isMobile = this.checkDeviceService.isMobile();
    this.mobileApple = this.watchService.checkAppleDevice();

    try {
      window.screen.orientation.unlock();

      window.screen.orientation.lock('landscape').catch(() => {});
    } catch { }

    this.isPlaying = !this.playerUnavailable;

    this.screen_x = window.innerWidth;
    const isLogged = parseInt(this.profileService.selectedProfile);
    if (isLogged) this.idPerfil = isLogged;
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];

      if (params['isMarathon'])
        this.isMarathon = params['isMarathon'] === 'true';
      if (params['playOnChromecast'])
        this.playOnChromecast = params['playOnChromecast'] === 'true';

      this.type = params['type'];
      this.initialPosition = Number(params['position']);
      this.isLive = params['isLive'] === 'true';
      this.isLiveAndApp = environment.app && this.isLive;

      this.isNovela = params['isNovela'] === 'true';

      this.episode.id = Number(params['episodeId']);

      if (this.type === 'serie') this.nextEpisodeService.serieId = this.id!;

      if (this.id && this.type) {
        this.displayMediaInfo();
      } else {
        this.return();
      }
    });

    this.inactiveSubject.pipe(debounceTime(5000)).subscribe(() => {
      if (this.isPlayOnChromecast) return;

      this.inactive = true;
      this.audioOptions?.close();
      this.videoOptions?.close();
    });
    this.inactiveSubject.next(true);

    if (['watch', 'ligga', 'giga', 'vero', 'voa'].includes(this.theme.client)) {
      let cancelSubscriber = false;

      this.geolocationService.verifyUserHasGloboRegionalChannels(environment.app);

      this.userIsInCoverageAreaService
        .userIsInCoverageArea$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(userIsInCoverageArea => {
          if (this.movie) {
            if (this.movie.isRegional) {
              if (!userIsInCoverageArea && !this.loadingMedia) {
                this.isPlaying = !this.isPlaying;
                this.handlePlay();
                this.playerUnavailable = true;

                cancelSubscriber = this.userIsInCoverageAreaService.userIsInCoverageAreaIntervals.length > 0;
              }
            } else cancelSubscriber = this.userIsInCoverageAreaService.userIsInCoverageAreaIntervals.length > 0;

            if (cancelSubscriber) {
              this.unsubscribe$.next();
              this.unsubscribe$.complete();
              this.userIsInCoverageAreaService.clearAllUserIsInCoverageAreaIntervals();
            }
          }
        });
    }

    this.getProfilePreferences();
  }

  ngOnDestroy(): void {
    if(this.appleDevice){
      ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
      ScreenOrientation.unlock();
    }
    try {
      window.screen.orientation.unlock();
    } catch {}
    this.watchService.fullScreen(false)
    this.isWatching = false;

    clearTimeout(this.sendProgressTimeout);
    clearTimeout(this.checkConnectionTimeout);

    clearInterval(this.intervalSaveProgress);
    clearInterval(this.checkLimitParamountTimeout);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.userIsInCoverageAreaService.clearAllUserIsInCoverageAreaIntervals();

    try {
      this.kaltura?.destroy();
      this.timerSubscription.unsubscribe();
    } catch { }

  }

  clickVolume() {
    if (this.isMobile) this.openVolume = !this.openVolume;
    else this.volume = this.volume == 0 ? 0.5 : 0;
  }

  openChromeCast(changeUrl: boolean = false): void {
    if (changeUrl)
      this.chromecastService.changeMedia(
        this.kaltura.sources.dash[0].url,
        this.kaltura.currentTime,
        this.kaltura.volume,
        this.movie?.title!,
        this.getPoster()
      );
    if (!changeUrl)
      this.chromecastService.openChromeCast(
        this.kaltura.sources.dash[0].url,
        this.kaltura.currentTime,
        this.kaltura.volume,
        this.movie?.title!,
        this.getPoster()
      );

    this.isPlayOnChromecast = true;

    this.isPlaying = !this.playerUnavailable;

    this.currentTimeCast = this.kaltura.currentTime;
    this.tempoAtualChromeCast = this.convertHMS(this.currentTimeCast);

    if (!changeUrl) this.kaltura.pause();
    else this.kaltura.play();

    this.updateTimeChromecast();
  }

  private updateTimeChromecast(): void {
    setTimeout(() => {
      this.updateTimeChromecast();
      this.chromecastService.getCurrentTime().subscribe((time) => {
        if (time == 0) return;

        this.currentTimeCast = time;
        this.tempoAtualChromeCast = this.convertHMS(this.currentTimeCast);
      });
    }, 1000);
  }

  reproduzirDispositivo() {
    this.isPlayOnChromecast = false;
    this.isRunningOnChromecast = false;
    this.chromecastService.stop();
    this.kaltura.play();
  }

  reproduzirChromecast() {
    this.isPlayOnChromecast = true;
    this.isRunningOnChromecast = false;
    this.openChromeCast(true);
  }

  loadingMedia = false;
  private async initKalturaPlayer(
    idKaltura: string,
    position: number
  ): Promise<void> {
    if (this.movie?.isParamount) {
      this.loadingMedia = true;
      this.CheckContentService.startParamount(idKaltura).subscribe((resp) => {
        this.idParamountLoop = resp.Data.id;
        this.CheckContentService.checkParamount(idKaltura, this.idParamountLoop).subscribe((resp) => {
          if (resp.HasError) {
            this.limitContentMessage = 'É permitido assistir apenas 3 conteúdos da Paramount+ simultaneamente.';
            this.limitContent = true;
          } else {
            this.limitContentLoop++;
            this.checkContentLimitParamount(idKaltura);
            this.startPLayer(idKaltura);
          }
        });
      });
    } else if (this.movie?.isGlobo) {
      this.loadingMedia = true;
      this.CheckContentService.startGlobo(idKaltura).subscribe((resp) => {
        this.idParamountLoop = resp.Data.id;
        this.CheckContentService.checkGlobo(idKaltura, this.idParamountLoop).subscribe((resp) => {
          if (resp.HasError) {
            this.limitContentMessage = 'É permitido assistir apenas 3 conteúdos da Globo simultaneamente.';
            this.limitContent = true;
          } else {
            this.limitContentLoop++;
            this.checkContentLimitGlobo(idKaltura);
            this.startPLayer(idKaltura);
          }
        });
      });
    } else if (this.movie?.isVubiquitySony) {
      this.loadingMedia = true;
      this.CheckContentService.startVubiquitySony(idKaltura).subscribe((resp) => {
        this.idParamountLoop = resp.Data.id;
        this.CheckContentService.checkVubiquitySony(idKaltura, this.idParamountLoop).subscribe((resp) => {
          if (resp.HasError) {
            this.limitContentMessage = 'É permitido assistir apenas 2 conteúdos VUbiquity Sony simultaneamente.';
            this.limitContent = true;
          } else {
            this.limitContentLoop++;
            this.checkContentLimitVubiquitySony(idKaltura);
            this.startPLayer(idKaltura);
          }
        });
      });
    } else {
      this.startPLayer(idKaltura);
    }
  }

  private startPLayer(idKaltura: string) {
    this.UserDataServiceService.AccountInfo().subscribe(val => {
      this.company = val.data.company;
      this.id_isp = val.data.id_isp;
      this.user_id = val.data.user_id;
      try {
        this.preRollActive = !this.isLive;
        this.kaltura = KalturaPlayer.setup({
          targetId: 'kaltura_player_15630281',
          provider: {
            partnerId: '2267831',
            uiConfId: '44208331'
          },
          playback: {
            autoplay: !this.playerUnavailable,
            audioLanguage: 'pt'
          },
          ui: {
            disable: true
          },
          plugins: {
            youbora: {
              disable: false,
              options: {
                accountCode: "watchbrazil",
                username: this.user_id,
                'content.customDimension.1': this.id_isp,
                'content.customDimension.2': this.company,
                'content.customDimension.3': this.user_id,
                'content.customDimension.4': this.idPerfil,
                'content.customDimension.5': idKaltura,
                'content.id': idKaltura,
                'content.metadata': {
                  'user_id': this.user_id,
                  'profile_id': this.idPerfil,
                  'content_id': idKaltura
                },
                'user.email': localStorage.getItem('email'),
                'user.type': 'email',
                'content.language': this.audioSelected,
                'content.subtitles': this.subtitleSelected
              },
            }
          }
        });

        this.kaltura.addEventListener(
          this.kaltura.Event.PLAYER_STATE_CHANGED,
          (event: any) => {
            this.loadingMedia =
              event.payload.newState.type == this.kaltura.State.LOADING ||
              (event.payload.newState.type == this.kaltura.State.PAUSED &&
                event.payload.oldState.type == this.kaltura.State.LOADING);
          }
        );

        this.kaltura.addEventListener(this.kaltura.Event.WAITING, () => {
          if (!localStorage.getItem('videoQuality')) {
            this.kaltura.enableAdaptiveBitrate();
          }
        });

        if (!this.preRollActive) {
          this.startMedia(idKaltura);
        } else {
          this.kaltura.loadMedia({ entryId: this.theme.preRoll });
          this.kaltura.addEventListener(
            this.kaltura.Event.PLAYER_STATE_CHANGED,
            (event: any) => {
              if (
                event.payload.newState.type == this.kaltura.State.IDLE &&
                this.preRollActive
              ) {
                this.startMedia(idKaltura);
              }
            }
          );
        }
      } catch (e: any) { }


      this.kaltura.ready().then(() => {
        this.mediaReady();
        this.kaltura.play();
      });

    });
  }

  public changeEvent() {
    let optionsPlugin = this.kaltura.plugins.youbora._youbora.options
    optionsPlugin['content.language'] = this.audioSelected;
    optionsPlugin['content.subtitles'] = this.subtitleSelected;
    if (this.isLive) {
      optionsPlugin['content.playbackType'] = 'LIVE';
    } else {
      optionsPlugin['content.playbackType'] = 'VOD';
    }
  }

  private startMedia(idKaltura: string) {
    this.chromeCastDevicesAvailable =
      this.chromecastService.chromeCastDevicesAvailable;
    this.chromecastService.inicializeChromecast().subscribe((status) => {
      setTimeout(() => {
        if (status === 'CONNECTED') {
          this.isRunningOnChromecast = true;
          if (this.kaltura) this.kaltura.pause();
        }
      }, 2000);
    }, () => { });

    const payload: GenericObject[] = [
      { entryId: idKaltura },
      { volume: 0.5 }
    ];
    if (!this.isLive) {
      payload.splice(1, 0, { startTime: this.initialPosition || 0 });
    }

    this.checkMarathonIsAvailable();

    this.kaltura
      .loadMedia(...payload)
      .then(() => {
        if (this.playOnChromecast) this.openChromeCast(true);

        this.isSafari = this.kaltura.isAdaptiveBitrateEnabled();
        this.loading = false;
        this.isPlaying = !this.playerUnavailable;
        this.preRollActive = false;

        if (this.isRunningOnChromecast) this.kaltura.pause();


        if(this.id == 1615 && this.theme.client != 'vero'){
          this.hasAudios = true;
          this.showModalBBB = true;
          this.modalBBBTitle = this.theme.client == 'watch' ? 'Olá, Watcher!' : 'Olá'
          this.modalBBBDescription = 'Para alternar entre os áudios das câmeras disponíveis, vá nas<br> configurações de áudio aqui da tela e escolha "ÁUDIO 1" para a<br> câmera da esquerda e "ÁUDIO 2" para a câmera da direita.<br> Assim, você acompanha e fica por dentro de tudo o que está<br> rolando na casa mais vigiada do Brasil!'
        }

      });
  }

  private mediaReady(): void {
    this.kaltura.volume = 0.5;
    this.isPlaying = !this.playerUnavailable;
    this.startCurrentTimeCheck();
    this.prepareSources();

    this.checkMarathonIsAvailable();

    const savedQuality = localStorage.getItem('videoQuality');
    const savedQualityIndex = this.sources.findIndex(s => s.label == savedQuality);
    if (savedQualityIndex != -1) {
      this.selectSource({
        source: this.sources[savedQualityIndex],
        id: savedQualityIndex
      });
    }
  }

  public set source(id: number) {
    if (id > -1) return;
    localStorage.setItem('source_id', id.toString());
  }

  public get source(): number {
    const value = localStorage.getItem('source_id');
    return value ? parseInt(value) : -1;
  }

  private async prepareNextEpisode(): Promise<void> {
    this.nextEpisode = await this.nextEpisodeService.getNextEpisode();
  }

  private prepareSources(): void {
    this.sources = this.kaltura.getTracks(this.kaltura.Track.VIDEO);
    this.sources.sort((a, b) => +a.label.replace('p', '') <= +b.label.replace('p', '') ? -1 : 1);
    const isDifferent = this.sources.findIndex(s => s.active) != this.source;
    const hasSources = this.sources.length > 0;
    if (hasSources && isDifferent && !this.isLiveAndApp) {
      this.selectSource({
        source: this.sources[this.source],
        id: this.source
      });
    }
  }

  private subtitles = [];

  private audio = [];

  private loadAudioAndSubtitlesLanguages(): void {
    const subtitles = this.kaltura.getTracks(this.kaltura.Track.TEXT);
    const audio = this.kaltura.getTracks(this.kaltura.Track.AUDIO);

    let _subtitlesIguais =
      this.subtitles.map((x: any) => x.language).length ==
      subtitles.map((x: any) => x.language).length;
    let _audioIguais =
      this.audio.map((x: any) => x.language).length ==
      audio.map((x: any) => x.language).length;

    if (!_subtitlesIguais || !_audioIguais) {
      this.subtitles = subtitles;
      this.audio = audio;
      this.subtitlePack = [];
      this.audioPack = [];
    } else {
      return;
    }

    subtitles.map((subtitle: any) => {
      switch (subtitle.language) {
        case 'off': {
          this.subtitlePack.push({
            name: 'Desativado',
            code: 'off',
            isSelected: subtitle.active,
            type: 'subtitle'
          });
          break;
        }
        case 'por': {
          this.subtitlePack.push({
            name: 'Português',
            code: 'por',
            isSelected: subtitle.active,
            type: 'subtitle'
          });
          break;
        }
        case 'pt': {
          this.subtitlePack.push({
            name: 'Português',
            code: 'pt',
            isSelected: subtitle.active,
            type: 'subtitle'
          });
          break;
        }
        case 'eng': {
          this.subtitlePack.push({
            name: 'Inglês',
            code: 'eng',
            isSelected: subtitle.active,
            type: 'subtitle'
          });
          break;
        }
        case 'en': {
          this.subtitlePack.push({
            name: 'Inglês',
            code: 'en',
            isSelected: subtitle.active,
            type: 'subtitle'
          });
          break;
        }
      }
    });
    audio.map((audio: any) => {
      switch (audio.language) {
        case 'por': {
          this.audioPack.push({
            name: (this.id == 1615) ? 'Áudio 1' : 'Português',
            code: 'por',
            isSelected: audio.active,
            type: 'audio'
          });
          break;
        }
        case 'pt': {
          this.audioPack.push({
            name: (this.id == 1615) ? 'Áudio 1' : 'Português',
            code: 'pt',
            isSelected: audio.active,
            type: 'audio'
          });
          break;
        }
        case 'eng': {
          this.audioPack.push({
            name: (this.id == 1615) ? 'Áudio 2' : 'Original',
            code: 'eng',
            isSelected: audio.active,
            type: 'audio'
          });
          break;
        }
        case 'en': {
          this.audioPack.push({
            name: (this.id == 1615) ? 'Áudio 2' : 'Original',
            code: 'en',
            isSelected: audio.active,
            type: 'audio'
          });
          break;
        }
      }
    });

    if(this.id == 1615){
      this.audioPack.sort((a, b) => a.name.localeCompare(b.name, 'pt'));
    }

    if (this.subtitlePack.length > 0) {
      this.subtitlePack.sort((a, b) => {
        if (a.code === 'off') return -1;
        if (b.code === 'off') return 1;
        return 0;
      });
    } else {
      this.subtitlePack.push({
        name: 'Desativado',
        code: 'off',
        isSelected: true,
        type: 'subtitle'
      });
    }

    if (this.audioPack.length > 0) {
      this.audioPack.sort((a, b) => {
        if (a.code === 'por') return -1;
        if (b.code === 'por') return 1;
        return 0;
      });
    } else {
      this.audioPack.push({
        name: 'Português',
        code: 'por',
        isSelected: true,
        type: 'audio'
      });
    }

    this.subtitlePack = this.removeDuplicates(this.subtitlePack, 'code');
    this.audioPack = this.removeDuplicates(this.audioPack, 'code');

    this.subtitlePack = this.subtitlePack.filter((item, index, array) => {
      return array.findIndex(obj => obj.name === item.name) === index;
    });

    this.audioPack = this.audioPack.filter((item, index, array) => {
      return array.findIndex(obj => obj.name === item.name) === index;
    });

    let savedAudio = this.profilePreferences?.audio_default;
    let selectedAudioIndex = this.audioPack.findIndex(
      (s) => {
        s.code == savedAudio
      }
    );

    if (savedAudio && selectedAudioIndex != -1) {
      this.selectTrack(this.audioPack[selectedAudioIndex])
    }
    if (!savedAudio || selectedAudioIndex == -1) {
      let defaultPtIndex = this.audioPack.findIndex(item => item.code === 'pt' || item.code === 'por');
      if (defaultPtIndex == -1 && this.isLive == false){
        this.selectTrack(this.audioPack[0])
      } else {
        this.selectTrack(this.audioPack[defaultPtIndex]);
      }
    } else {
      this.selectTrack(this.audioPack[0]);
    }

    let savedSubtitle = this.profilePreferences?.subtitle_default;
    let savedSubtitleIndex = this.subtitlePack.findIndex(
      (s) => s.code == savedSubtitle
    );
    if (!savedSubtitle || savedSubtitleIndex == -1) this.selectTrack(this.subtitlePack[0]);
    else this.selectTrack(this.subtitlePack[savedSubtitleIndex]);
  }

  private removeDuplicates(originalArray: any, prop: any) {
    const newArray: any = [];
    const lookupObject: any = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  public selectTrack(track: Language, byClick: boolean = false) {
    if (track.type == 'subtitle') {
      this.kaltura.getTracks(this.kaltura.Track.TEXT).map((subtitle: any) => {
        this.hasSubtitles = true;
        if (subtitle.language == track.code) {
          localStorage.setItem('savedSubtitle', track.code);
          this.subtitlePack.forEach((track) => {
            track.isSelected = false;
          });
          track.isSelected = true;
          this.kaltura.selectTrack(subtitle);
          if (track.isSelected) {
            this.subtitleSelected = track.code;
            this.changeEvent();
          }
          this.updateTracks();
        }
      });
      if (this.isPlayOnChromecast)
        this.chromecastService.setSubtitle(track.code);
    } else if (track.type == 'audio') {
      this.kaltura.getTracks(this.kaltura.Track.AUDIO).map((audio: any) => {
        if (audio.language == track.code) {
          this.hasAudios = true;
          localStorage.setItem('savedAudio', track.code);
          this.audioPack.forEach((track) => {
            track.isSelected = false;
          });
          track.isSelected = true;
          this.kaltura.selectTrack(audio);
          if (track.isSelected) {
            this.audioSelected = track.code
            this.changeEvent();
          }
          this.updateTracks();
        }
      });
      if (this.isPlayOnChromecast) this.chromecastService.setAudio(track.code);
    }

    if (byClick) {
      const { type, code } = track;
      this.setProfilePreference(type, code);
    }
  }

  public selectSource(newSource: { source: Source; id: number }): void {
    try {
      this.kaltura.selectTrack(newSource.source);
      this.source = newSource.id;
      localStorage.setItem('source_id', newSource.id.toString());
      localStorage.setItem('videoQuality', newSource.source.label);
    } catch (error) {}
  }

  private updateTracks(): void {
    this.sources = [];
    this.loadAudioAndSubtitlesLanguages();

    this.sources = this.kaltura.getTracks(this.kaltura.Track.VIDEO);

    this.sources = this.sources.reduce<Source[]>((accumulator, current) => {

      if (!accumulator.some(item => item.label === current.label)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    this.sources.sort((a, b) => +a.label.replace('p', '') - +b.label.replace('p', ''));
  }

  public mute(): void {
    this.muted = !this.muted;
    if (this.muted) {
      this.previousVolume = this.kaltura.volume;
      this.kaltura.volume = 0;
    } else {
      this.kaltura.volume = this.previousVolume;
    }

    if (this.isPlayOnChromecast)
      this.chromecastService.setVolume(this.kaltura.volume);
  }

  public displayMediaInfo(): void {
    this.mediaService.getDetails(0, this.id!, this.type!).subscribe((data) => {
      this.movie = data;

      if ((this.movie.isConmebolSulamericanaMatch || this.movie.isConmebolLibertadoresMatch) && this.movie.isLiveMatch)  {
        this.isLive = true;
      }

      this.checkRent(this.movie)

      if (this.type === 'filme') {
        if (this.movie && this.movie.id_kaltura) {
          this.details.id_kaltura = this.movie.id_kaltura;
          this.initKalturaPlayer(this.movie.id_kaltura, this.position);
        }
      } else if (this.type === 'serie') {
        this.serieData = data;

        this.preparePlayerForSerie();
      }
    });
  }

  public checkRent(movie: any) {
    if (movie.availability === "TVOD" && movie.rented == false) {
      localStorage.setItem('isRent', 'false')
      this.router.navigateByUrl('/rent')
    }
  }

  public preparePlayerForSerie(): void {
    let index = this.findSeasonAndEpisodeIndex(
      this.episode.id!,
      this.serieData.seasons!
    );

    if (index.seasonIndex !== -1 && index.episodeIndex !== -1) {
      let episode =
        this.serieData.seasons![index.seasonIndex!].videos![
        index.episodeIndex!
        ];

      this.episode.title =
        'T' +
        index.seasonOrder! +
        ':E' +
        index.episodeOrder! +
        ' - ' +
        episode.title!;

      this.nextEpisodeService.seasonIndex = index.seasonIndex!;
      this.nextEpisodeService.episodeIndex = index.episodeIndex!;
      this.nextEpisodeService.seasonOrder = index.seasonOrder!;

      this.details.id_kaltura = episode.id_kaltura;

      if (!this.playerLoaded)
        this.initKalturaPlayer(episode.id_kaltura!, this.position);
      else {
        this.playerLoaded = true;
        this.restartPlayer(episode.id_kaltura!);
      }
    }
  }

  private restartPlayer(id_kaltura: string): void {
    this.fullTime = '00:00:00';
    this.showNextEpisode = false;
    this.nextEpisodeAvailable = false;
    this.kaltura
      .loadMedia(
        { entryId: id_kaltura },
        {
          startTime: this.isLive ? this.kaltura.duration : this.initialPosition || 0
        },
        { volume: 0.5 }
      )
      .then(() => {
        this.mediaReady();
      });
  }

  private findSeasonAndEpisodeIndex(
    episodeId: number,
    seasons: SeriesSeasons[]
  ): SeasonAndEpisode {
    let _seasonEpisodeIndex = {
      seasonIndex: -1,
      episodeIndex: -1,
      seasonOrder: -1,
      episodeOrder: 0
    };
    seasons.some((season, seasonIndex) => {
      return season.videos.some((episode, episodeIndex) => {
        if (episode.id === episodeId) {
          _seasonEpisodeIndex.seasonIndex = seasonIndex;
          _seasonEpisodeIndex.seasonOrder = season.order!;
          _seasonEpisodeIndex.episodeIndex = episodeIndex;
          _seasonEpisodeIndex.episodeOrder = episode.order!;
          return true;
        }
        return false;
      });
    });

    return _seasonEpisodeIndex;
  }

  public updateCurrentTime(currentTime: number): void {
    if (this.isPlayOnChromecast) {
      this.chromecastService.seek(currentTime);
      return;
    } else {
      this.kaltura.currentTime = currentTime;
    }

    if (currentTime) {
      this.changeEvent();
    }
  }

  public togglePlay(): void {
    if (this.playerUnavailable) return;

    this.isPlaying = !this.isPlaying;
    this.handlePlay();
  }

  public toggleSubtitles(language: string): void {
    let textTracks = this.kaltura.getTracks(this.kaltura.Track.TEXT);
    this.kaltura.selectTrack(
      textTracks[this.filterLanguages(textTracks, language)]
    );
  }

  public toggleAudioLanguage(language: string): void {
    let audioTracks = this.kaltura.getTracks(this.kaltura.Track.AUDIO);
    this.kaltura.selectTrack(
      audioTracks[this.filterLanguages(audioTracks, language)]
    );
  }

  returnPrevious() {
    if(this.appleDevice){
      ScreenOrientation.lock({ type: OrientationType.LANDSCAPE });
      ScreenOrientation.unlock();
    }
    this.watchService.fullScreen(false)
    try {
      window.screen.orientation.unlock();
    } catch {}
    this.route.queryParamMap
      .subscribe((params) => {
        if (params.get('isNovelas') != null) {
          if (params.get('isNovelas') == "true") {
            this.isNovela = true;
          }
        }
      }
      );

    setTimeout(() => {
      if (this.isMarathon == true) {
        this.router.navigate(['/details', this.id, this.type]);
      } else {
        this.location.back();
      }
    }, 100);
  }

  public return() {
    clearInterval(this.intervalSaveProgress);
    clearInterval(this.subscribeCurrentTime);
  }

  public handleRewind(): void {
    if (this.isPlayOnChromecast) {
      this.chromecastService.seek(this.currentTimeCast - 10);
      return;
    }

    if (this.isLive) {
      if (this.kaltura.currentTime - 10 > this.now - 14400) {
        this.kaltura.currentTime =
          this.kaltura.currentTime - 10 < 0 ? 0 : this.kaltura.currentTime - 10;
      }
    } else {
      this.kaltura.currentTime =
        this.kaltura.currentTime - 10 < 0 ? 0 : this.kaltura.currentTime - 10;
    }
  }

  get onTimeLive(): boolean {
    try {
      return this.liveTime - this.kaltura.currentTime < 5;
    } catch {
      return false;
    }
  }

  getPoster() {
    return this.kaltura?.sources?.poster;
  }

  public handleForward(): void {
    if (this.isPlayOnChromecast) {
      this.chromecastService.seek(this.currentTimeCast + 10);
      return;
    }

    this.kaltura.currentTime =
      this.kaltura.currentTime + 10 >= this.kaltura.duration
        ? (this.kaltura.currentTime = this.kaltura.duration)
        : (this.kaltura.currentTime = this.kaltura.currentTime + 10);
  }

  public handlePlay(): void {
    if (!this.isPlayOnChromecast) {
      this.kaltura.paused ? this.kaltura.play() : this.kaltura.pause();

      if (this.isPlaying) this.kaltura.play();
      else this.kaltura.pause();
    } else {
      this.chromecastService.playOrPause();
    }
  }

  public liveTime = 0;
  public startCurrentTimeCheck(): void {
    try {
      this.updateTracks();
    } catch { }

    let dif = 0;
    if (!this.isPlayOnChromecast)
      dif = this.kaltura.duration - this.kaltura.currentTime;
    else if (this.isPlayOnChromecast)
      dif = this.kaltura.duration - this.currentTimeCast;

    if (!this.preRollActive && dif <= 10 && this.nextEpisodeAvailable)
      this.showNextEpisode = true;

    this.tempoAtual = this.convertHMS(this.kaltura.currentTime);
    this.fullTime = this.convertHMS(this.kaltura.duration);
    if (this.isLive && this.kaltura.currentTime > 0) {
      if (this.liveTime == 0) this.liveTime = this.kaltura.currentTime;
      this.liveTime += 1;
    }

    if (this.showNextEpisode && dif <= 1 && this.type === 'serie') {
      this.marathon();
      return;
    } else if (
      (this.type === 'filme' ||
        (this.type === 'serie' && !this.nextEpisodeAvailable)) &&
      dif <= 1 &&
      !this.preRollActive
    ) {
      if (!this.isLiveAndApp) {
        this.returnPrevious();
        return;
      }
    }

    setTimeout(() => {
      this.startCurrentTimeCheck();
    }, 1000);
  }

  public convertHMS(value: any) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }

  public sendProgress(): void {
    let dif = 0;
    dif = this.kaltura?.duration - this.kaltura?.currentTime;
    if (this.kaltura?.currentTime)
      this.progressService
        .sendProgress(
          this.idPerfil,
          this.details.id_kaltura,
          this.kaltura.currentTime
        )
        .subscribe();

    if (this.isWatching) {
      this.sendProgressTimeout = setTimeout(() => {
        this.sendProgress();
      }, 10000);
    }
  }

  public checkConnection(): void {
    this.checkConnectionService.checkConnection();

    this.checkConnectionTimeout = setTimeout(() => {
      if (this.isWatching) this.checkConnection();
    }, 10000);
  }

  public checkContentLimitParamount(entry_id: string): void {
    if (this.limitContentLoop == 0) {
      this.loadingMedia = true;
    }
    this.CheckContentService.checkParamount(entry_id, this.idParamountLoop).subscribe((resp) => {
      this.checkLimitParamountTimeout = setTimeout(() => {
        if (this.isWatching) this.checkContentLimitParamount(entry_id);
      }, 5000);
      if (resp.HasError && this.limitContentLoop == 1) {
        if (!this.limitContent || this.isWatching) {
          this.togglePlay();
          this.isWatching = false;
        } else {
          this.loadingMedia = false;
        }
        this.limitContentMessage = 'É permitido assistir apenas 3 conteúdos da Paramount+ simultaneamente.';
        this.limitContent = true;
      }
      this.limitContentLoop++;
    });
  }

  public checkContentLimitGlobo(entry_id: string): void {
    if (this.limitContentLoop == 0) {
      this.loadingMedia = true;
    }
    this.CheckContentService.checkGlobo(entry_id, this.idParamountLoop).subscribe((resp) => {
      this.checkLimitParamountTimeout = setTimeout(() => {
        if (this.isWatching) this.checkContentLimitGlobo(entry_id);
      }, 5000);
      if (resp.HasError && this.limitContentLoop == 1) {
        if (!this.limitContent || this.isWatching) {
          this.togglePlay();
          this.isWatching = false;
        } else {
          this.loadingMedia = false;
        }
        this.limitContentMessage = 'É permitido assistir apenas 3 conteúdos da Globo simultaneamente.';
        this.limitContent = true;
      }
      this.limitContentLoop++;
    });
  }

  public checkContentLimitVubiquitySony(entry_id: string): void {
    if (this.limitContentLoop == 0) {
      this.loadingMedia = true;
    }
    this.CheckContentService.checkVubiquitySony(entry_id, this.idParamountLoop).subscribe((resp) => {
      this.checkLimitParamountTimeout = setTimeout(() => {
        if (this.isWatching) this.checkContentLimitVubiquitySony(entry_id);
      }, 5000);
      if (resp.HasError && this.limitContentLoop == 1) {
        if (!this.limitContent || this.isWatching) {
          this.togglePlay();
          this.isWatching = false;
        } else {
          this.loadingMedia = false;
        }
        this.limitContentMessage = 'É permitido assistir apenas 2 conteúdos VUbiquity Sony simultaneamente.';
        this.limitContent = true;
      }
      this.limitContentLoop++;
    });
  }

  public marathon() {
    this.isMarathon = true;
    this.nextEpisodeService.playEpisode(this.isPlayOnChromecast);
  }

  public get volume(): number {
    return this.kaltura.volume;
  }
  public set volume(v: number) {
    this.kaltura.volume = v;
    if (this.isPlayOnChromecast) this.chromecastService.setVolume(v);
  }

  public playerToggleFullscreen(): void {
    this.watchService.fullScreen();
  }

  private filterLanguages(arr: any[], searchKey: string) {
    return arr.findIndex((obj) => obj.language === searchKey);
  }

  private getProfilePreferences() {
    if (!!!this.idPerfil) {
      return;
    }

    this.profilePreferencesService.getProfilePreferences(String(this.idPerfil)).subscribe({
      next: ({ success, preferences }) => {
        if (!success) {
          return;
        }

        this.profilePreferences = preferences;
      },
      error: (error) => console.error(error)
    });
  }

  private setProfilePreference(type: 'subtitle' | 'audio', code: string) {
    if (!!!this.idPerfil) {
      return;
    }

    const payload: SetProfilePreferencesPayload = {
      type,
      code,
      id_perfil: this.idPerfil
    };

    this.profilePreferencesService.setProfilePreferences(payload).subscribe({
      next: ({ success, message }) => {
      },
      error: (error) => console.error(error)
    });
  }

  private checkMarathonIsAvailable() {
    if (this.type === 'serie') {
      this.nextEpisodeService.seasons = this.serieData.seasons!;

      this.playerLoaded = true;

      this.nextEpisodeAvailable = this.nextEpisodeService.checkNextEpisode();

      if (this.nextEpisodeAvailable) this.prepareNextEpisode();
    }
  }
}
