<div class="shadow" *ngIf="menuActive" (click)=" toggleSideMenu()"></div>
<div class="menu-icon" [class.active]="menuActive">
  <a><img *ngIf="!menuActive" src="/assets/icons/{{hamburguerIcon}}" (click)="toggleSideMenu()" /></a>
  <a><img *ngIf="menuActive" src={{closePath}} (click)="toggleSideMenu()" /></a>
</div>

<div class="menu-content" [class.active]="menuActive">

  <div class="top">
    <img src="{{ theme.logoMobile }}" [alt]="theme.title" class="logo-streaming-by" />
    <div class="profile-image">
      <div class="circle">
        <img [src]="buildImg(profileName.foto)" />
      </div>
      <p class="profile-name">{{ profileName.nome }}</p>
    </div>
  </div>

  <div class="middle" [class.cobrand]="!['watch'].includes(theme.client)">
    <div class="options">
      <div class="link" (click)="optionSelect('filmes')">
        <p>Filmes</p>
        <div class="arrow" [class.active]="selectedOption === 'filmes'">
          <img src={{arrowPath}} />
        </div>
      </div>
      <mat-expansion-panel #expansionFilmes [expanded]="selectedOption === 'filmes'">
        <a class="link-item" *ngFor="let cat of filmeCategories" (click)="openCategory(cat.title!, 'filme', cat.id)">
          <p>{{ cat.title }}</p>
        </a>
      </mat-expansion-panel>
      <div class="link" (click)="optionSelect('series')">
        <p>Séries</p>
        <div class="arrow" [class.active]="selectedOption === 'series'">
          <img src={{arrowPath}} />
        </div>
      </div>
      <mat-expansion-panel #expansionSeries [expanded]="selectedOption === 'series'">
        <a class="link-item" *ngFor="let cat of serieCategories" (click)="openCategory(cat.title!, 'serie', cat.id)">
          <p>{{ cat.title }}</p>
        </a>
      </mat-expansion-panel>
      <a routerLink="/favorites" (click)="menuActive = false">
        <div class="link" (click)="optionSelect('favoritos')">
          <p>Minha Lista</p>
          <div class="icon-header">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="icon-list">
              <path d="M8.00004 2.99988V13M3 7.99992H13.0001" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </a>
      <a routerLink="/rent" (click)="menuActive = false">
        <div class="link" (click)="optionSelect('alugados')">
          <p>Minhas Locações</p>
        </div>
      </a>
    </div>
  </div>

  <div class="bottom-watch" *ngIf="['watch'].includes(theme.client)">
    <div class="logo">
      <img src="{{ theme.logoMobile }}" [alt]="theme.title" />
    </div>
  </div>

  <div class="bottom"[ngClass]="{'bottom-iphone': appleDevice}" *ngIf="!['watch'].includes(theme.client)">
    <div class="logo">
      <img src="{{ theme.streamingByMobile }}" alt="theme.title">
    </div>
  </div>
</div>