import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Units } from '../helpers/units';
import { TimerService } from '../shared/services/timer.service';

@Directive({
  selector: '[appTimeline]'
})
export class TimelineDirective implements OnInit, OnChanges {
  marginLeft = 56;
  screenWidth = 0;
  minX = 50;
  timer = new Date();
  scroll = 0;
  screenSize = 0;

  @Input() appTimeline = 0;
  @Output() triggerUpdateEPGList = new EventEmitter<boolean>();
  private currentHour: number | null = null;

  constructor(private el: ElementRef, private timerService: TimerService) {}

  @HostListener('window: scroll', ['$event'])
  public onScroll(): void {
    this.scroll = window.scrollY;
  }

  @HostListener('window: resize', ['$event'])
  public onResize(): void {
    this.reUpdateMinX();
    this.screenWidth = this.el.nativeElement.closest('body').clientWidth;
  }

  ngOnInit(): void {
    this.screenWidth = this.el.nativeElement.closest('body').clientWidth;
    this.rePosition();
    this.startTimer();
  }

  ngOnChanges(): void {
    this.rePosition();
  }

  private startTimer() {
    this.timerService.handleTimer().subscribe((timer) => {
      this.screenWidth = this.el.nativeElement.closest('body').clientWidth;
      this.timer = timer;
      this.rePosition();
      this.reUpdateMinX();
    });
  }

  private reUpdateMinX(): void {
    if (this.screenWidth <= 768) {
      this.minX = 18;
    } else {
      this.minX = 50;
    }
  }

  private getScreenWidthToTimeline(): number {
    const percentsOfScreenWidth: { [key: string]: number } = {
      '1921': 10.1,
      '1920': 10.4,
      '1600': 10.7,
      '1366': 11,
      '1200': 11.3,
      '1024': 11.8,
      '768': 12.5,
      '596': 14,
      '475': 14.5,
      '425': 15.5,
      '375': 16.5,
      '320': 18,
    };
    const minScreenWidthPercent = Object.keys(percentsOfScreenWidth).find(i => this.screenWidth <= parseInt(i));
    return percentsOfScreenWidth[minScreenWidthPercent ?? '1921'];
  }

  private verifyIfNeedRestartEPGList() {
    const hours = this.timer.getHours();

    if (!this.currentHour) {
      this.currentHour = hours;
    }

    if (hours !== this.currentHour) {
      this.currentHour = null;
      this.triggerUpdateEPGList.emit(true);
    }
  }

  private rePosition(): void {
    const minutes = this.timer.getMinutes();

    const currentMinutesInPixels = Units.convertMinuteToPixel(minutes);
    const halfHourInPixels = Units.convertMinuteToPixel(60 / 2);
    const oneMinuteInPixel = Units.convertMinuteToPixel(1);
    const screenPercent = this.getScreenWidthToTimeline();
    const firstTimeSpaceLeft = Math.ceil((this.screenWidth * screenPercent) / 100) - oneMinuteInPixel;
    const scheduleTimeIndex = 4;
    const translate = firstTimeSpaceLeft + (halfHourInPixels * scheduleTimeIndex) + currentMinutesInPixels;

    this.verifyIfNeedRestartEPGList();

    if (translate + this.appTimeline > this.screenWidth - 80) {
      this.el.nativeElement.style.transform = this.translate(
        this.screenWidth - this.el.nativeElement.clientWidth
      );
      this.el.nativeElement.classList.remove('active');

      this.el.nativeElement.children[0].children[0].textContent = 'Agora';
    } else if (translate + this.appTimeline >= this.minX) {
      this.el.nativeElement.style.transform = this.translate(
        translate + this.appTimeline
      );
      this.el.nativeElement.classList.add('active');

      this.el.nativeElement.children[0].children[0].textContent = `${this.timer
        .getHours()
        .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}:${this.timer
        .getMinutes()
        .toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}`;
    } else if (translate + this.appTimeline < this.minX) {
      this.el.nativeElement.style.transform = this.translate(
        translate + this.appTimeline
      );
      this.el.nativeElement.classList.remove('active');
      this.el.nativeElement.children[0].children[0].textContent = 'Agora';

      if (translate + this.appTimeline <= 0) {
        this.el.nativeElement.style.transform = this.translate(0);
      }
    }
  }

  private translate(xAxis: number) {
    if (this.screenWidth <= 768) {
      return `translate3D(${xAxis > 55 ? xAxis : 55}px, 0, 0)`;
    } else {
      return `translate3D(${xAxis > 165 ? xAxis : 165}px, 0, 0)`;
    }
  }
}
