import { ProfileService } from 'src/app/shared/services/profile.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { Component, Input, OnInit } from '@angular/core';
import { Theme } from 'src/assets/theme/theme';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {
  @Input() public currentRoute: string = '';
  @Input() public hasLiveChannels: boolean = false;

  public isSelected: boolean = false;
  public profilePhoto: string = '';
  private defaultProfileImages: { [key: string]: string } = {};
  public environment: any;

  constructor(
    private loginService: LoginService,
    private profileService: ProfileService,
    private theme: Theme,
  ) {}

  ngOnInit(): void {
    this.environment = environment;
    this.defaultProfileImages = {
      'watch': this.environment.bucket + 'profiles/orange-app.svg',
      'multilaser': this.environment.bucket + 'profiles/pink-app.svg',
      'vero': this.environment.bucket + 'profiles/pink-app.svg',
      'ligga': this.environment.bucket + 'profiles/orange-app.svg',
      'giga': this.environment.bucket + 'profiles/blue-app.svg',
      'voa': this.environment.bucket + 'profiles/profile.svg',
    };
    this.loginService.loadToken();
    if (this.profileService.loadProfile()) {
      this.isSelected = true;
      this.profilePhoto = this.profileService.profileSelected?.foto ?? '';

      if (this.profilePhoto.trim().length <= 0) {
        this.profilePhoto = this.defaultProfileImages[this.theme.client];
      }
    }
  }
}
