import { Component, Input } from '@angular/core';
import { BannerToasterOptions } from '../../interfaces/generic';

@Component({
  selector: 'app-banner-toaster',
  templateUrl: './banner-toaster.component.html',
  styleUrls: ['./banner-toaster.component.scss']
})
export class BannerToasterComponent {
  @Input() options: BannerToasterOptions = {
    type: 'success',
    icon: {
      name: '',
      source: ''
    },
    description: ''
  };
  public hideToaster: boolean = false;

  constructor() {}
}
