import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IClientGTM } from './app/shared/interfaces/google-tag-manager';
import { GoogleTagScriptStructure } from './app/shared/interfaces/generic';

if (environment.production) {
  enableProdMode();
} else {
  const metaTag = document.createElement('meta');
  metaTag.name = "robots";
  metaTag.content = "noindex";
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}

if (environment.client === "multilaser") {
  localStorage.clear();
  window.location.href = "https://cloud.marketing.watch.tv.br/promo-multimais";
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

addGoogleTagNameScripts();

function addGoogleTagNameScripts() {
  const googleTagCodes: { [key: string]: string } = {
    watch: 'G-Y8DHMYT540',
    multilaser: 'G-X3HG7P8GEH'
  };

  const rdstation:  { [key: string]: boolean } = {
    watch: false,
    multilaser: true
  };

  if (Object.keys(googleTagCodes).includes(environment.client)) {
    const googleTagCode = googleTagCodes[environment.client];

    const asyncScript = document.createElement('script');
    const configScript = document.createElement('script');
    const { head, body } = document;

    asyncScript.setAttribute('async', '');
    asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagCode}`;

    configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${googleTagCode}');
    `;

    if (environment.client == "watch") {
      head.appendChild(asyncScript);
      head.appendChild(configScript);
      body.appendChild(asyncScript);
      body.appendChild(configScript);
    } else if (environment.client == "multilaser" && window.location.hostname == 'multimais.tv') {
      head.appendChild(asyncScript);
      head.appendChild(configScript);
      body.appendChild(asyncScript);
      body.appendChild(configScript);
    } 
  }

  if (Object.keys(rdstation).includes(environment.client)) {
    const enable = rdstation[environment.client];

    if(enable && window.location.hostname == 'multimais.tv'){
      const asyncScript = document.createElement('script');
      const configScript = document.createElement('script');
      const { head, body } = document;

      asyncScript.setAttribute('async', '');
      asyncScript.src = `https://d335luupugsy2.cloudfront.net/js/loader-scripts/8c0dd548-d86b-4abb-b810-c702f2598d9c-loader.js`;

      configScript.innerHTML = ``;

      head.appendChild(asyncScript);
      head.appendChild(configScript);

      body.appendChild(asyncScript);
      body.appendChild(configScript);
    }
  }
}

addGoogleTagManager();

function addGoogleTagManager() {
  const gtmIds: IClientGTM = {
    multilaser: "G-X3HG7P8GEH"
  };
  if (Object.keys(gtmIds).includes(environment.client) && window.location.hostname == 'multimais.tv') {
    const id = gtmIds[environment.client as keyof IClientGTM];
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.textContent = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${id}');`;
    document.head.appendChild(script2);
  }
}

addGoogleTagScripts(window.location.pathname);

function addGoogleTagScripts (route: string) {
  const tagScriptStructure: GoogleTagScriptStructure[] = [
    {
      code: `
        (function(w, d, s, l, i){
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j,f);
        })(window, document, 'script', 'dataLayer', 'GTM-5MB2CFNV');
      `,
      metaTag: null,
      noScriptCode: "GTM-5MB2CFNV",
      routesAllowed: ["/institucional", "/login/cadastro", "/institucional/plans", "/login/voucher", "/login", "/faq", "/try", "/login/activate", "/promofamilia/cupom/multi7", "/promofamilia/cupom/oba7", "/promofamilia/cupom/flyerfamilia", "/promofamilia/cupom/multicelular7", "/promofamilia/cupom/multiTV7", "/promofamilia/cupom/multitablet7"],
      clientsAllowed: ["multilaser"]
    },
    {
      code: `
        (function(c, l, a, r, i, t, y){
          c[a] = c[a] || function(){
            (c[a].q = c[a].q || []).push(arguments)
          };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "i1dgf8r67a");
      `,
      metaTag: '<meta name="google-site-verification" content="TnFzvP2QWyDmZWPngxQAwWhi7bifTa9_8hdx-3R1R1o" />',
      noScriptCode: null,
      routesAllowed: ["/promofamilia"],
      clientsAllowed: ["multilaser"]
    },
  ];

  tagScriptStructure.forEach((structure: GoogleTagScriptStructure) => {
    const verificationStructure = [
      structure.clientsAllowed.includes(environment.client),
      structure.routesAllowed.some(routes => route.includes(routes)),
      route.trim().length > 0
    ];
    const verificationStructureIsValid = verificationStructure.every(value => value);

    if (!verificationStructureIsValid) {
      return;
    }

    const { head, body } = document;
    const headScript = document.createElement('script');

    headScript.innerHTML = structure.code;

    head.prepend(headScript);

    if (structure.metaTag) {
      head.insertAdjacentHTML('afterbegin', structure.metaTag);
    }

    if (structure.noScriptCode) {
      const bodyNoScript = document.createElement('noscript');
      const iframe = document.createElement('iframe');

      iframe.src = `https://www.googletagmanager.com/ns.html?id=${structure.noScriptCode}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";

      bodyNoScript.appendChild(iframe);
      body.insertAdjacentElement('afterbegin', bodyNoScript);
    }
  });
}
