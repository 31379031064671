<div class="container">
    <div class="modal-cancel" *ngIf="showModal">
        <div class="modal-content">
            <div class="modal-header">
                <h4>
                    Não identificamos a compra deste conteúdo.
                </h4>
            </div>
            <div class="modal-body">
                <app-button (click)="closeModal()">Fechar</app-button>
            </div>
        </div>
    </div>
</div>



