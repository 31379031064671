import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../shared/services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): true | UrlTree {
    return this.checkLogin(state.url);
  }

  private checkLogin(url: string) {
    if(url == '/ativar' && !this.loginService.token){
      localStorage.setItem('redirectToAtivar', 'true');
    }

    if (this.loginService.token) return true;
    return this.router.parseUrl('/login');
  }
}
