<div class="container">
    <!--IMAGENS SVG-->
    <div class="background" *ngIf="svg">
      <img class="img-banner" [src]="svg" />
    </div>

    <div class="background" *ngIf="hasCustomBanner && !checkMobile && dynamic">
      <img class="img-banner" [src]="hasCustomBanner" />
    </div>

  <div class="header" [class.padding]="hasPadding && !isLandingPage"><!---->
    <div class="row">
      <img *ngIf="hasCustomIcon" [src]="hasCustomIcon" width="170" />
      <h3 *ngIf="hasCustomIcon && !isNSportsCard">
        | {{ title || 'Sugestões' }}
      </h3>

      <h3 *ngIf='isTelecine'>
         {{title}} | Degustação Natal
      </h3>

      <h3 *ngIf="!hasCustomIcon && !isTelecine">
        <ng-container *ngIf="theme.logoweb && hasSectionIcon"><img class="logo-icon"
            [src]="environment.bucket + 'logos/multi_mais.svg'" alt="logo-icon" />
        </ng-container>{{ title }}
      </h3>

      <div *ngIf="verMais" class="ver-mais" (click)="openCategory()">
        <app-button type="button" text="ver mais" classList="btn-secondary"></app-button>
      </div>

      <div *ngIf="viewMore !== ''" class="ver-mais" (click)="viewMoreClick()">
        <app-button type="button" text="ver mais" classList="btn-secondary"></app-button>
      </div>
    </div>
    <div class="row">
      <p *ngIf="subtitle !== ''">{{ subtitle }}</p>
    </div>
  </div>
  <swiper *ngIf="data.length > 0" slidesPerView="auto" [navigation]="!checkMobile && hasNavigation"
    [allowTouchMove]="allowTouchMove" [loop]="data.length >= 7 && isInfinity">
    <ng-template swiperSlide *ngFor="let item of data; let index = index">
      <div *ngIf="sectionIsRanked" class="ellipse">
        <div class="number">{{ index + 1 }}</div>
      </div>

      <app-card *ngIf="showExpiredContent || !item.expired" [isExclusiveContent]="isExclusiveContent"
        [labelExclusiveContent]="labelExclusiveContent" [idPerfil]="idPerfil" [rented]="rented"
        [isExpired]="item.expired!" [properties]="properties" [data]="item" [route]="item.route!"
        [hasExclusiveContent]="hasExclusiveContent" [cardOnly]="cardOnly" (hasntParamountEvent)="hasntParamount()"
        [isNSports]="isNSportsCard" [noClick]="noClick" [isConmebolLibertadores]="isConmebolLibertadores"></app-card>
    </ng-template>
  </swiper>
</div>
