import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/auth.guard';
import { ProfileGuard } from '@app/auth/profile.guard';
import { PageComponent } from '@app/core/page/page/page.component';
import { LoggedGuard } from '../auth/logged.guard';

const routes: Routes = [
  {
    path: 'v4',
    component: PageComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./views/home-v4/home-v4.module').then((m) => m.HomeV4Module),
        canActivate: [AuthGuard, ProfileGuard]
      },      
      {
        path: 'search',
        loadChildren: () =>
          import('./views/search-v4/search-v4.module').then((m) => m.SearchV4Module),
        canActivate: [AuthGuard, ProfileGuard]
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('./views/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./views/login-v4/login.module').then((m) => m.LoginModule),
        canActivate: [LoggedGuard]
      },
      {
        path: 'paramount',
        loadChildren: () => import('./views/paramount/paramount.module').then((m) => m.ParamountModule)
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingV4Module {}
