<div class="input-container" [class.error]="error || (control.invalid && control.touched)" *ngIf="!registerWatch && !register">
  <input
    type="{{ type }}"
    placeholder="{{ placeholder }}"
    [ngModel]="value"
    [disabled]="disabled"
    [maxlength]="maxlength"
    #input
    (input)="valueChange.emit(input.value)"
    (ngModelChange)="valueChange.emit(value)"
    [mask]="mask"
    [class.center]="center"
    autocomplete="new-password"
    [formControl]="control"
  />
  <img *ngIf="type === 'password'" src="/assets/icons/eyes_slash_large.svg" alt="Eye Icon Password" (click)="toggleVisibilityPassword($event)">
</div>

<div class="input-container-record" [class.error]="error || (control.invalid && control.touched)" *ngIf="register">
  <input
    type="{{ type }}"
    placeholder="{{ placeholder }}"
    [ngModel]="value"
    [disabled]="disabled"
    [maxlength]="maxlength"
    #input
    (input)="valueChange.emit(input.value)"
    (ngModelChange)="valueChange.emit(value)"
    [mask]="mask"
    [class.center]="center"
    autocomplete="new-password"
    [formControl]="control"
  />
  <img *ngIf="theme.client === 'multilaser' && type === 'password'" src="/assets/icons/eyes_slash_large.svg" alt="Eye Icon Password" (click)="toggleVisibilityPassword($event)">
</div>

<div class="input-container" [class.error]="error || (control.invalid && control.touched)" *ngIf="registerWatch">
  <input
    type="{{ type }}"
    placeholder="{{ placeholder }}"
    [ngModel]="value"
    [disabled]="disabled"
    [maxlength]="maxlength"
    #input
    (input)="valueChange.emit(input.value)"
    (ngModelChange)="valueChange.emit(value)"
    [mask]="mask"
    [class.center]="center"
    autocomplete="new-password"
    [formControl]="control"
  />
  <img *ngIf=" type === 'password'" src="/assets/icons/eyes_slash_large.svg" alt="Eye Icon Password" (click)="toggleVisibilityPassword($event)">
  
</div>