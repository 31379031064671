<div class="top-most-watched-container">
  <div class="content-container">
    <p class="title" [innerHTML]="title"></p>
    <p class="sub-title" [innerHTML]="description"></p>
  </div>

  <swiper slidesPerView="auto">
    <ng-template
      swiperSlide
      *ngFor="let item of topMostWatched; let index = index"
    >
      <div class="top-most-watched">
        <div class="ellipse">
          <div class="number">{{ index + 1 }}</div>
        </div>

        <app-card [route]="'/details/' + item.id + '/' + item.type" [properties]="properties" [data]="item"></app-card>
      </div>
    </ng-template>
  </swiper>
</div>

<div class="only-mobile">
  <app-section
    [title]="title"
    [properties]="properties"
    [data]="topMostWatched"
    [sectionIsRanked]="true"
  ></app-section>
</div>
