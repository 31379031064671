import { Component } from '@angular/core';

@Component({
  selector: 'app-regional-channels-epg-notification',
  templateUrl: './regional-channels-epg-notification.component.html',
  styleUrls: ['./regional-channels-epg-notification.component.scss']
})
export class RegionalChannelsEpgNotificationComponent {
  constructor() {}
}
