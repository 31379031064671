<app-modal-no-content
  *ngIf="showNoContentModal"
  (closeEvent)="showNoContentModal = false"
></app-modal-no-content>
<ng-container *ngIf="!mediaLoaded">
  <div [ngClass]="firstTime ? 'loading' : 'loading-fullscreen'">
    <app-loading></app-loading>
  </div>
</ng-container>

<ng-container *ngIf="!firstTime">
  <div>
    <app-series-banner
      [backgroundPath]="
        buildImage(
          serieMovie?.cdn,
          mediaType === 'serie' ? serieMovie.highlight2 : serieMovie?.highlight
        )
      "
      [type]="mediaType"
      [title]="mediaTitle"
      [data]="serieMovie"
      [id]="mediaId"
      [idPerfil]="idPerfil"
      [favorite]="favoriteState"
      [isParamount]="isParamount"
      (hasntParamount)="showNoContentModal = true"
    ></app-series-banner>
    <div *ngIf="seriesDataLoaded" class="season-and-episodes-wrapper">
      <app-season-selector
        [pageType]="seriesData.pageType!"
        [seriesSeasons]="seriesData.seasons!"
      ></app-season-selector>
      <ng-container *ngIf="seriesData.pageType! == getPageType().default">
        <app-episodes-section
          *ngFor="
            let episode of seriesData.seasons![seasonSelectedIndex].videos;
            let index = index
          "
          [type]="mediaType"
          [episode]="episode"
          [episodePosition]="episodePosition[seasonSelectedIndex][index]"
          [episodeDuration]="episodeDuration[seasonSelectedIndex][index]"
          [cardProperties]="cardProperties"
          [serieId]="seriesData?.id"
          [censorship]="seriesData?.censorship"
          [isParamount]="isParamount"
          [isParamountFreemium]="isParamountFreemium"
          (hasntParamount)="showNoContentModal = true"
        ></app-episodes-section>
      </ng-container>
      <ng-container *ngIf="seriesData.pageType! != getPageType().default">
        <app-episodes-section
          *ngFor="
            let episode of getPaginatedEpisodes(seasonSelectedIndex);
            let j = index
          "
          [type]="mediaType"
          [episode]="episode"
          [episodePosition]="getEpisodePosition(seasonSelectedIndex, j)"
          [episodeDuration]="getEpisodeDuration(seasonSelectedIndex, j)"
          [cardProperties]="cardProperties"
          [serieId]="seriesData?.id"
          [censorship]="seriesData?.censorship"
          [isParamount]="isParamount"
          [isParamountFreemium]="isParamountFreemium"
          (hasntParamount)="showNoContentModal = true"
        ></app-episodes-section>
      </ng-container>
    </div>
  </div>
  <div class="spacer" *ngIf="mediaType === 'serie'"></div>
  <div class="container--suggestions" *ngIf="resultsLoaded!"
       [style]="mediaType === 'serie' ? 'margin-top:0;' : ''">
    <app-section
      *ngIf="!isEmptySugestions"
      [title]="'Sugestões para você'"
      [data]="resultsRecommended.list!"
      [properties]="cardProperties"
    ></app-section>
  </div>
</ng-container>
