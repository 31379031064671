<div
  class="cielo-payment-wrapper flex flex-col justify-center align-center gap-10"
>
  <form
    [formGroup]="form"
    (submit)="handleSubmitForm()"
    class="cielo-payment-form flex flex-col gap-9"
  >
    <h1 class="font-bold text-primary" *ngIf="theme.client !== 'ligga'">Dados do Cartão de Crédito</h1>
    <h1 class="font-bold --secondary-base" *ngIf="theme.client == 'ligga'">Dados do Cartão de Crédito</h1>

    <div
      *ngFor="let fields of formFields"
      class="w-full flex justify-flex-start align-flex-end gap-2"
    >
      <div
        *ngFor="let field of fields"
        class="w-full flex"
        [ngClass]="{ disabled: field.isDisabled }"
      >
        <label
          class="flex flex-1 flex-col justify-center gap-3"
          [ngClass]="{
            shake: isInputInvalid(field.name)
          }"
        >
          <span class="font-normal text-white" [innerHTML]="field.label"></span>
          <app-input
            [class.year]="field.name === 'cardYear'"
            [type]="field.type"
            [placeholder]="field.placeholder"
            [mask]="field?.mask ?? ''"
            [control]="getInputControl(field.name)"
            [value]="field.value"
            [disabled]="field.isDisabled"
            (valueChange)="setFormValues(field.name)"
          ></app-input>
          <div *ngIf="isInputInvalid(field.name)" class="error-message">
            {{ getErrorMessage(field.name) }}
          </div>
        </label>

        <div
          *ngIf="field.name === 'cardSecurityValue'"
          class="cvv-info-container flex-1 flex justify-center align-baseline gap-2"
        >
          <div class="cvv-info flex flex-col justify-center align-center gap-2">
            <img
              src="/assets/icons/visa-master-cvv-info.svg"
              alt="Visa/Master Security Card Info"
            />
            <span class="font-normal text-white">Visa/Master</span>
          </div>
  
          <div class="cvv-info flex flex-col justify-center align-center gap-2">
            <img
              src="/assets/icons/amex-cvv-info.svg"
              alt="Amex Security Card Info"
            />
            <span class="font-normal text-white">Amex</span>
          </div>
        </div>
      </div>

    </div>

    <p class="font-light text-white">
      Ao clicar no botão “Alugar”, você concorda com os termos de uso, com a
      política de privacidade e declaram ter mais de 18 anos de idade.
    </p>

    <p *ngIf="backendErrorMessage.trim().length > 0" class="error-message" [innerHTML]="backendErrorMessage"></p>

    <div class="flex justify-flex-end align-center gap-6">
      <app-button classList="btn btn-secondary" text="Cancelar"></app-button>
      <app-button classList="btn btn-primary" text="Alugar" [disabled]="form.invalid || isLoading"></app-button>
    </div>
  </form>
  <app-secure-site-card-badges></app-secure-site-card-badges>
</div>
