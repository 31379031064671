import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Theme } from 'src/assets/theme/theme';
import { TypesInput } from '../../interfaces/toggle-input-types';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnChanges {
  @Input() public placeholder: string;
  @Input() public type: string;
  @Input() public error: boolean | undefined;
  @Input() public disabled: boolean = false;
  @Input() public value: string | undefined;
  @Input() public mask: string;
  @Input() public maxlength: any;
  @Output() public valueChange: EventEmitter<string>;
  @Input() public center: boolean = false;
  @Input() public register: boolean = false;
  @Input() public registerWatch: boolean = false;
  @Input() control: FormControl;

  constructor(public theme: Theme) {
    this.placeholder = '';
    this.type = 'text';
    this.mask = '';
    this.error = false;
    this.value = '';
    this.valueChange = new EventEmitter();
    this.control = new FormControl('');
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this.control.setValue(value, { emitEvent: false });
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      if (
        changes['value'].previousValue === '' &&
        changes['value'].currentValue !== ''
      )
        this.error = false;
    }
  }

  public onInputChanges(value: string) {
    this.valueChange.emit(value);
  }

  public toggleVisibilityPassword(event: Event) {
    const eyeIcon = (event.target) as HTMLImageElement;
    const types: TypesInput = {
      'text': {
        newType: 'password',
        newSrcIcon: '/assets/icons/eyes_slash_large.svg'
      },
      'password': {
        newType: 'text',
        newSrcIcon: '/assets/icons/eyes_large.svg'
      }
    };
    
    if (eyeIcon) {
      const inputPassword = (eyeIcon.previousSibling) as HTMLInputElement;
      eyeIcon.src = types[inputPassword.type].newSrcIcon;

      if (inputPassword) inputPassword.type = types[inputPassword.type].newType;
    }
  }
}
