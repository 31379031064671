<div id="regionalChannelsNotificationEPG">
  <div class="notificationIcon">
    <img src="/assets/icons/geolocation/white_no_signal_big.svg" alt="Regional Channels EPG Notification" />
  </div>

  <p class="notificationText">
    Alguns canais ao vivo só podem ser assistidos dentro da área de cobertura do
    sinal, pois são de distribuição regional.
  </p>
</div>
