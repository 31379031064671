import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  isValidCVVCard,
  isValidCardNumber,
  isValidMonthValidityCard,
  isValidYearValidityCard
} from '@src/app/shared/utils';
import { Observable, catchError, debounceTime, map, of, switchMap } from 'rxjs';
import { CieloPaymentService } from '../app/shared/services/cielo-payment.service';

export function cardSecurityValuePatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!isValidCVVCard(value)) {
      return { cardSecurityValuePattern: true };
    }

    return null;
  };
}

export function cardMonthValidityPatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!isValidMonthValidityCard(value)) {
      return { cardMonthValidityPattern: true };
    }

    return null;
  };
}

export function cardYearValidityPatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const year = new Date().getFullYear();

    if (!isValidYearValidityCard(value) || +value < year) {
      return { cardYearValidityPattern: true };
    }

    return null;
  };
}

export function cardNumberValidator(
  cieloPaymentService: CieloPaymentService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const value = control.value;

    if (!value) {
      return of(null);
    }

    if (!isValidCardNumber(value)) {
      return of({ cardNumberPattern: true });
    }

    return of(value).pipe(
      debounceTime(300),
      switchMap((value) =>
        cieloPaymentService.checkCardNumber(value).pipe(
          map((result) =>
            !result.success ? { cardNumberPattern: true } : null
          ),
          catchError(() => of({ cardNumberPattern: true }))
        )
      )
    );
  };
}
