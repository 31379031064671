import { Component, OnInit } from '@angular/core';
import { GeolocationModal, GeolocationModalOptions, GeolocationPositions } from '../../interfaces/geolocation-modal';
import { GeolocationLocationPermission, GeolocationModalCallAction } from '../../enums/geolocation-modal-options';
import { GeolocationService } from '../../services/geolocation.service';
import { Theme } from '@src/assets/theme/theme';

@Component({
  selector: 'app-geolocation-modal',
  templateUrl: './geolocation-modal.component.html',
  styleUrls: ['./geolocation-modal.component.scss']
})
export class GeolocationModalComponent implements OnInit {
  private geolocationModalOptions: GeolocationModalOptions;
  public hideModal: boolean;
  public showModalOnInit: boolean;
  public currentGeolocationOption: GeolocationModal;
  

  constructor(
    private geolocationService: GeolocationService,
    private theme: Theme
    ) {
    this.geolocationModalOptions = {
      prompt: {
        iconPath: this.theme.client == 'giga' ? '/assets/icons/geolocation/location_blue.svg' : '/assets/icons/geolocation/location.svg' ,
        modalTitle: 'Ative a sua localização',
        modalSubtitle:
          'Para a liberação dos conteúdos regionais na plataforma é necessário ativar a localização em seu dispositivo, caso contrário não será possível a visualização destes conteúdos.',
        buttonOptions: [
          {
            label: 'Não ativar',
            classList: 'btn-secondary',
            onClickAction: GeolocationModalCallAction.NoAllowLocation
          },
          {
            label: 'Ativar agora',
            classList: 'btn-primary',
            onClickAction: GeolocationModalCallAction.RequestLocationPermission
          }
        ]
      },
      granted: {
        iconPath: this.theme.client == 'giga' ? '/assets/icons/geolocation/location_blue.svg' : '/assets/icons/geolocation/location.svg',
        modalTitle: 'Localização ativada!',
        modalSubtitle:
          'Você optou por ativar a localização do seu dispositivo móvel. Neste caso será possível a visualização dos conteúdos de distribuição regional.',
        buttonOptions: [
          {
            label: 'Ok, continuar',
            classList: 'btn-secondary',
            onClickAction: GeolocationModalCallAction.CloseModal
          }
        ]
      },
      denied: {
        iconPath: this.theme.client == 'giga' ? '/assets/icons/geolocation/no_signal_blue.svg' : '/assets/icons/geolocation/no_signal.svg',
        modalTitle: 'Localização não ativada!',
        modalSubtitle:
          'Você optou por não ativar a localização do seu dispositivo móvel. Neste caso pode não ser possível a visualização dos conteúdos de distribuição regional.',
        buttonOptions: [
          {
            label: 'Ok, continuar',
            classList: 'btn-secondary',
            onClickAction: GeolocationModalCallAction.CloseModal
          }
        ]
      }
    };
    this.showModalOnInit = false;
    this.hideModal = false;
    this.currentGeolocationOption =
      this.geolocationModalOptions[this.geolocationService.locationPermission];
  }

  ngOnInit(): void {
    if (this.geolocationService.hasGloboRegionalChannels === true) {
      document.body.style.overflowY = 'hidden';
      this.checkLocationPermission();
    }
  }

  private checkLocationPermission() {
    this.geolocationService
      .checkPermissions()
      .then(permission => this.geolocationService.locationPermission = permission)
      .catch((_) => this.geolocationService.locationPermission = GeolocationLocationPermission.denied)
      .finally(() => {
        this.showModalOnInit = this.geolocationService.locationPermission !== GeolocationLocationPermission.granted;

        if (!this.showModalOnInit) this.closeModal();
      });
  }

  private requestLocationPermission() {
    this.geolocationService
      .getCurrentPosition()
      .then(positions => {
        this.geolocationService.locationPermission = GeolocationLocationPermission.granted;
        this.geolocationService.geolocationPositions = positions;
      })
      .catch(permissionDenied => {
        this.geolocationService.locationPermission = permissionDenied;
        this.geolocationService.geolocationPositions = {
          latitude: 0,
          longitude: 0
        };
      })
      .finally(() => this.currentGeolocationOption = this.geolocationModalOptions[this.geolocationService.locationPermission]);
  }

  private closeModal() {
    this.hideModal = true;
    document.body.style.overflowY = 'auto';
  }

  private noAllowLocation() {
    this.geolocationService.geolocationPositions = {
      latitude: 0,
      longitude: 0
    };
    this.closeModal();
  }

  public callActionModal(callAction: GeolocationModalCallAction): void {
    switch (callAction) {
      case GeolocationModalCallAction.CloseModal:
        this.closeModal();
        break;
      case GeolocationModalCallAction.NoAllowLocation:
        this.noAllowLocation();
        break;
      case GeolocationModalCallAction.RequestLocationPermission:
        this.requestLocationPermission();
        break;
    }
  }
}
