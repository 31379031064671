import { Injectable } from '@angular/core';
import { Http } from './http.service';
import { Observable } from 'rxjs';
import { ReturnValidateMGMCode } from '../interfaces/code';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  constructor(private http: Http) {}

  public validateMGMCode(
    code: string
  ): Observable<ReturnValidateMGMCode> {
    return this.http.post('v3/validateMGMCode', {
      code
    });
  }
}
