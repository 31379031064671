import { Device } from 'src/app/helpers/device';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import SwiperCore, { Navigation } from 'swiper';
import { DetailedProgram } from '../../interfaces/program-details';
import { CheckDeviceService } from '../../services/check-device.service';
import { NowService } from '../../services/now.service';
import { environment } from '../../../../environments/environment';
import { Theme } from '@src/assets/theme/theme';
import { UserIsInCoverageAreaService } from '../../services/user-is-in-coverage-area.service';
import { sortChannelsBasedOnCoverageArea } from '../../utils';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-most-watched-channels',
  templateUrl: './most-watched-channels.component.html',
  styleUrls: ['./most-watched-channels.component.scss']
})
export class MostWatchedChannelsComponent implements OnInit, OnChanges {
  @Output() callNewChannels = new EventEmitter<string>();
  @Input() dataFromHome: any = [];
  @Input() fromHome: boolean = false;
  @Input() public userIsInCoverageArea: boolean = false;

  public originalData: DetailedProgram[];
  public data: DetailedProgram[];
  public isMobile: boolean = false;
  public checkMobile: boolean = false;
  public isLoaded: boolean = false;
  public isApp: boolean = environment.app;

  constructor(
    private checkDevice: CheckDeviceService,
    private now: NowService,
    private theme: Theme,
    private userIsInCoverageAreaService: UserIsInCoverageAreaService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isMobile = Device.isMobile();
    this.originalData = [];
    this.data = [];
  }

  ngOnInit(): void {
    //this.getEssentials();
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (['watch', 'ligga'].includes(this.theme.client)) {
      const isPreparedToFilter: boolean | null =
        changes['userIsInCoverageArea'] &&
        this.data.length > 0 &&
        this.originalData.length > 0;

      if (isPreparedToFilter) {
        this.data = sortChannelsBasedOnCoverageArea(this.originalData, this.userIsInCoverageArea, this.userIsInCoverageAreaService.myRegionalChannelName);
      }
    }
  }

  onSwiper() {
    this.callNewChannels.next('next channels');
  }
  getChanelList() {
    this.now.getMostWatched().subscribe({
      next: (res) => {
        this.data = res.list;
        this.originalData = this.data;
      },
      error: (err) => console.error(err),
      complete: () => (this.isLoaded = true)
    });
  }

  // updateList() {
  //   setInterval(() => {
  //     this.getChanelList();
  //   }, 600000);
  // }

  getEssentials() {
    this.getChanelList();
    //this.updateList();
  }

  setData() {
    this.isLoaded = true;
    this.data = this.dataFromHome;
    if (!this.fromHome) {
      this.getChanelList();
    }
    //this.updateList();
  }
}
