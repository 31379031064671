<div class="space" *ngIf="!checkMobile && !hideActions"></div>
<nav class="container" *ngIf="!hideActions">
  <div class="navbar">
    <a class="logo" [ngClass]="{'voa-logo': theme.client === 'voa'}"  (click)="logoClick()">
      <img [src]="headerLogo()" [alt]="theme.title" />
    </a>
    <div class="nav-links__container" *ngIf="!checkMobile">
      <ul *ngIf="theme.client !== 'multilaser'" class="navbar-nav">
        <li
        *ngIf="!['watch','voa'].includes(theme.client)"
          class="nav-item"
          [ngClass]="{ selected: (getCurrentPage() === 'welcome') }"
        >
          <a (click)="goTo('welcome')">Início</a>
        </li>
        <ng-container *ngIf="theme.client === 'watch'">
          <li class="nav-item">
            <a href="https://descubra.watch.tv.br/para-provedores">Provedores</a>
          </li>
          <li class="nav-item" [ngClass]="{ selected: getCurrentPage() === 'try' }">
            <a href="/try">Plataforma para assinantes</a>
          </li>
          <li class="nav-item">
            <a href="https://descubra.watch.tv.br/sobre">Sobre a Watch</a>
          </li>
          <li class="nav-item">
            <a href="http://descubra.watch.tv.br/contato">Contato</a>
          </li>
        </ng-container>
        <li
          *ngIf="!['ligga', 'vero', 'watch', 'giga', 'voa'].includes(theme.client)"
          class="nav-item"
          [ngClass]="{ selected: getCurrentPage() === 'try' }"
        >
          <a (click)="goTo('try')">
            {{ getTryLink() }}
          </a>
        </li>
        <li class="nav-item categorias" *ngIf="showCategories">
          <a (click)="onCategoryClick()">Categorias</a>
          <app-dropdown-categories [active]="clickedCategory" (closeEvent)="clickedCategory = false"
            [isSelected]="false" [categories]="categories" [isUnloggedSession]="true"></app-dropdown-categories>
        </li>
      </ul>
    </div>
  </div>

  <ng-container>
    <div class="nav-links__container2" id="voucher">
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="theme.client === 'ligga'">
          <a (click)="goTo(theme.header.wantToBeLigga, false)">Quero ser usuário Ligga</a>
        </li>
        <li class="nav-item" *ngIf="theme.client === 'voa'">
          <a (click)="goTo(theme.header.wantToBe, false)">Quero ser usuário Voa</a>
        </li>
        <li class="nav-item" *ngIf="!['multilaser','giga','ligga', 'voa'].includes(theme.client)" [ngClass]="{ selected: theme.header.activeRouterHeader.includes(getCurrentPage()) }">
          <a
            class="blue-text"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="goTo(theme.header.activeRouterHeader, true)"
          >
            {{ theme.header.activeTextHeader }}</a
          >
        </li>
        <li *ngIf="!['multilaser','giga','ligga', 'voa'].includes(theme.client)" class="nav-item divider"></li>
        <li *ngIf="theme.client !== 'multilaser'" class="nav-item" [ngClass]="{ selected: getCurrentPage() === 'login' }">
          <a routerLinkActive="active" (click)="goTo('login', true)" [routerLinkActiveOptions]="{ exact: true }"
            class="login">Entrar</a>
        </li>
      </ul>
    </div>
  </ng-container>
</nav>
