<div id="modal-container">
  <div id="modal">
    <div id="modal--image">
      <img src="https://watchbr-resources.s3.amazonaws.com/first-access-app-image.png" alt="First Access App">
    </div>
    <div id="modal--content">
      <h1>Falta pouco!</h1>
      <p *ngIf="!['ligga', 'vero'].includes(theme.client)">
        Para acessar o seu perfil, é preciso completar o cadastro através de um
        computador.<br><br> Visite o nosso site em  <a href="https://{{url}}">{{url}}</a> e conclua agora!
      </p>
      <p *ngIf="['ligga', 'vero'].includes(theme.client)">
        Para acessar o seu perfil, é preciso<br/>
        completar o cadastro através de um<br/>
        computador. <br/><br/>
        Visite o nosso site em  <a href="https://{{url}}">{{url}}</a> <br/>
        e conclua agora!
      </p>
    </div>
  </div>
</div>
