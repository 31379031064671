import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { Profile } from '../../interfaces/profile';
import { ProfileStructure } from '../../interfaces/profile-structure';
import { Submenu } from '../../interfaces/submenu';
import { Theme } from 'src/assets/theme/theme';
import { CheckDeviceService } from '../../services/check-device.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-header-unlogged',
  templateUrl: './header-unlogged.component.html',
  styleUrls: ['./header-unlogged.component.scss']
})
export class HeaderUnloggedComponent implements OnInit {
  public environment: any;
  public iconPath: string;
  public isLoadingProfileList: boolean;
  public width: number;
  public currentRoute: string;
  public inDropDown: boolean;
  public showCategories: boolean = false;

  @Input() public profileStructure: ProfileStructure;
  @Input() public profileList: Profile[];
  @Input() public categories: Submenu[];
  @Input() public isLogged: boolean;
  @Input() public profile: Profile = {};
  @Input() public isSelected: boolean;
  @Input() public imageIsLoaded: boolean;
  @Input() public hasLiveChannels: boolean = false;
  @Input() public isAmericanet: boolean = false;
  @Input() public showHeaderOptions: boolean = true;
  @Input() public isRentScreen: boolean = false;

  @Output() public profileToEnterEmitter = new EventEmitter();

  public isSearchInputActive: boolean;
  public keyword: string;
  public keywordSize: number;
  public placeholderTerm: string;
  public clickedCategory: boolean;
  public isMobile: boolean = false;
  public profileToEnter: Profile = {};
  public checkMobile: boolean = false;
  public hideActions: boolean = false;

  @HostListener('window: resize', ['$event'])
  onResize() {
    this.width = window.innerWidth;
    window.innerWidth <= 900
      ? (this.iconPath = 'assets/icons/avatar-mobile-icon.svg')
      : (this.iconPath = 'assets/icons/avatar-icon.svg');
  }

  constructor(
    private router: Router,
    public theme: Theme,
    private checkDevice: CheckDeviceService,
    private checkDeviceService: CheckDeviceService,
    private loginService: LoginService
  ) {
    this.checkMobile = checkDevice.isMobile();
    this.width = 0;
    this.isLogged = false;
    this.iconPath = '';
    this.isSelected = false;
    this.profileStructure = {};
    this.categories = [];
    this.profileList = [];
    this.isLoadingProfileList = true;
    this.imageIsLoaded = false;
    this.isSearchInputActive = false;
    this.keyword = '';
    this.keywordSize = this.keyword.length;
    this.placeholderTerm = 'O que você está procurando?';
    this.clickedCategory = false;
    this.currentRoute = '';
    this.environment = environment;
    this.inDropDown = false;
  }

  ngAfterContentInit(): void {
    this.isLoadingProfileList = false;
    this.isMobile = this.checkDeviceService.isMobile();
  }

  ngOnInit(): void {
    this.width = window.innerWidth;
    window.innerWidth <= 900
      ? (this.iconPath = 'assets/icons/avatar-mobile-icon.svg')
      : (this.iconPath = 'assets/icons/avatar-icon.svg');

    if (this.getCurrentPage() === 'try') this.showCategories = this.theme.client !== 'watch';

    if (this.router.url.includes('/login/esqueci-minha-senha') && this.theme.client == 'vero') {
      this.hideActions = true;
    }
    if (this.router.url.includes('/login/criar-nova-senha') && this.theme.client == 'vero') {
      this.hideActions = true;
    }
  }

  public getProfileToEnter(event: any): void {
    this.profileToEnterEmitter.emit(event);
  }

  public onCategoryClick(): void {
    this.clickedCategory = !this.clickedCategory;
  }

  public onSubmit(): void {
    this.router.navigate(['/search'], {
      queryParams: { keyword: this.keyword }
    });
    this.keyword = '';
    this.keywordSize = this.keyword.length;
  }

  public doTheFirstName(name: string | undefined): string {
    const profileName = name!.split(' ')[0];
    return profileName;
  }

  public search(e: string): void {
    this.keyword = e;
    this.keywordSize = this.keyword.length;
  }

  public removeWhitespaces(value?: string): string {
    if (value) {
      return value.replace(/\s/g, '-').toLowerCase();
    }
    return '';
  }

  public changeDropDown(): void {
    if (this.isMobile) this.router.navigateByUrl('/profile/select');
    else this.inDropDown = !this.inDropDown;
  }

  getCurrentPage(): string {
    return this.router.url.split('/')[1];
  }

  public getCurrentUrl(): string {
    return this.router.url;
  }

  logoClick(): void {

    if (this.theme.client === 'multilaser') {
      return;
    }

    this.showCategories = false;
    let goToUrl = 'welcome';
    const clientUrls: Record<string, string> = {
      watch: 'https://descubra.watch.tv.br'
    };
    if (Object.keys(clientUrls).includes(this.theme.client)) {
      goToUrl = clientUrls[this.theme.client];
    }

    if (this.getCurrentPage() === goToUrl) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (goToUrl.includes('http')) {
      window.location.href = goToUrl;
      console.log('olá')
    } else {
      this.router.navigateByUrl(goToUrl);
      console.log('olá 2', goToUrl)
    }
  }

  public goTo(path: string, clearSession?: boolean): void {
    if (clearSession) this.loginService.clearUnloggedDetails();

    if (path === 'try') this.showCategories = true;
    else this.showCategories = false;

    if (path.includes('http')) {
      window.open(path);
    } else {
      this.router.navigateByUrl(path);
    }

    sessionStorage.setItem('isUnloggedSession', 'true');
    localStorage.setItem('tema', this.theme.client)
  }

  public goToPlans(): void {
    let _check = this.router.url === '/institucional/plans' ? '#' : '';
    this.router.navigateByUrl(`/institucional/plans${_check}`);
  }

  public headerLogo(): string {
    /*if (this.theme.client == 'vero') {
      if (this.getCurrentPage() == 'welcome' || this.getCurrentPage() == 'activate-account-sms') {
        logoUrl =
          'https://watchbr-resources-v3.s3.sa-east-1.amazonaws.com/assets/vero/logos/vero-streaming.svg';
      }
    }*/
    return this.theme.logo;
  }

  public getTryLink(){
    let client = this.theme.client;
    return (client === 'multilaser') ? 'Explore a Multi' :
            (client === 'vero') ? 'Conheça a Vero vídeo' :
            'Conheça a ' + this.capitalize(client)
  }

  private capitalize(word: string) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
}
