import { SeriesSeasons } from '../../interfaces/movie';
import { Device } from './../../../helpers/device';
import { Component, OnInit, Input } from '@angular/core';
import { MoviesAndSeriesComponent } from 'src/app/movies-and-series/movies-and-series.component';
import { environment } from '../../../../environments/environment';
import { CheckDeviceService } from '../../services/check-device.service';
import { PageType } from '../../enums/page-type.enum';
import { PageTypeConfig } from '../../enums/page-type-config.enum';

@Component({
  selector: 'app-season-selector',
  templateUrl: './season-selector.component.html',
  styleUrls: ['./season-selector.component.scss']
})
export class SeasonSelectorComponent implements OnInit {
  @Input() public seriesSeasons: SeriesSeasons[];
  @Input() public pageType: number = PageType.default;
  public isMobile: boolean;
  public environment: any;
  public dropdownActive: boolean;
  public seasonSelected: string;
  public seasonSelectedIndex: number;
  public checkMobile: boolean = false;
  public isApp: boolean = false;
  public chunkedEpisodes: any[] = [];

  constructor(
    private moviesAndSeriesComponent: MoviesAndSeriesComponent,
    public checkDevice: CheckDeviceService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.isApp = environment.app;
    this.seriesSeasons = [];

    this.isMobile = false;
    this.dropdownActive = false;
    this.seasonSelected = '';
    this.seasonSelectedIndex = 0;

    this.environment = environment;
  }

  ngOnInit(): void {
    this.isMobile = this.isMobileCheck();
    this.seasonSelected = this.isApp ? `Temporada ${this.seriesSeasons[0].order ?? 1}` : this.seriesSeasons[0].title!;

    if (this.isApp) {
      this.seriesSeasons.map(season => {
        season.title = `Temporada ${season.order ?? 1}`;
        return season;
      });
    }

    if (this.pageType != PageType.default) {
      for (let i = 0; i < this.seriesSeasons[0].videos.length; i += PageTypeConfig.byPage) {
        this.chunkedEpisodes.push(this.seriesSeasons[0].videos.slice(i, i + PageTypeConfig.byPage));
      }
    } else {
      this.seriesSeasons.map((season: SeriesSeasons, index: number) => {
        const seasonNumber = ++index;

        season.title = `Temporada ${season.order}`;
        return season;
      });
    }
  }

  public dropdownToggle(): void {
    this.dropdownActive = !this.dropdownActive;
  }

  public seasonToggle(index: number): void {
    this.dropdownActive = false;
    this.seasonSelected = this.isApp ? `Temporada ${this.seriesSeasons[index].order ?? 1}` : this.seriesSeasons[index].title!;
    this.seasonSelectedIndex = index;

    this.moviesAndSeriesComponent.loadSeasonSelected(index);
  }

  public episodePageToggle(index: number): void {
    //this.dropdownActive = false;
    //this.seasonSelected = this.seriesSeasons[index].title!;
    this.seasonSelectedIndex = index;

    this.moviesAndSeriesComponent.loadSeasonSelected(index);
  }

  private isMobileCheck(): boolean {
    if (Device.isMobile()) {
      return true;
    }
    return false;
  }

  public getEpisodesPages() {
    if (this.seriesSeasons.length > 0) {
      return this.chunkedEpisodes.map(chunk => chunk.length);
    }
    return [];
  }

  public getPageType() {
    return PageType;
  }

  public getPageTypeConfig() {
    return PageTypeConfig;
  }
}
