import { Injectable, OnDestroy } from '@angular/core';
import { MenuStepAction, StepForm, StepInput } from '../interfaces/form-multi-step';
import { BehaviorSubject } from 'rxjs';
import { GenericObject } from '../interfaces/generic';
import { removeMask } from '../utils';
import { ToastifyStatus } from '@src/app/shared/interfaces/generic';

@Injectable({
  providedIn: 'root'
})

export class FormMultiStepService implements OnDestroy {
  public isChangeStepBlocked: boolean = false;

  private maxSteps: number = 1;
  private steps: StepForm[] = [];
  private menuStepActions: MenuStepAction[] = [];

  private currentStep = new BehaviorSubject<number>(0);
  public currentStepObservable$ = this.currentStep.asObservable();

  private toastifyStatusSource = new BehaviorSubject<ToastifyStatus>('error');
  public toastifyStatusObservable$ = this.toastifyStatusSource.asObservable();

  private backendErrorMessageSource = new BehaviorSubject<string>('');
  public backendErrorMessageObservable$ = this.backendErrorMessageSource.asObservable();

  public get formSteps() {
    return this.steps;
  }

  public set newFormSteps(newFormSteps: StepForm[]) {
    this.steps = newFormSteps;
  }

  public set maxStep(newMaxSteps: number) {
    this.maxSteps = newMaxSteps;
  }

  public get currentMenuStepAction() {
    return this.menuStepActions[this.currentStepIndex];
  }

  public get menuStepActionsValues() {
    return this.menuStepActions;
  }

  public set menuStepAction(newMenuStepActions: MenuStepAction[]) {
    this.menuStepActions = newMenuStepActions;
  }

  public set menuStepActionSuccess(isSuccess: boolean) {
    this.currentMenuStepAction.success = isSuccess;
  }

  public get currentStepForm() {
    return this.steps[this.currentStepIndex];
  }

  public get currentStepIndex() {
    return this.currentStep.value;
  }

  private set newCurrentStep(newCurrentStepIndex: number) {
    this.currentStep.next(newCurrentStepIndex);
  }

  public set toastifyStatus(newToastifyStatus: ToastifyStatus) {
    this.toastifyStatusSource.next(newToastifyStatus);
  }

  public set backendErrorMessage(newBackendErrorMessage: string) {
    this.backendErrorMessageSource.next(newBackendErrorMessage);
  }

  constructor() {
    this.setAllToDefault();
  }

  ngOnDestroy(): void {
    this.setAllToDefault();
  }

  public setAllToDefault() {
    this.steps = [];
    this.menuStepActions = [];
    this.isChangeStepBlocked = false;
    this.newCurrentStep = 0;
    this.maxSteps = 1;
    this.backendErrorMessage = '';
  }

  private setFormValuesByName(formValues: GenericObject, key: string) {
    if (key in formValues) {
      const input = this.getInputByName(key) as StepInput | undefined;

      if (!input) {
        return;
      }

      let inputValue = input?.value ?? '';

      if (!!input.mask) {
        inputValue = removeMask(inputValue);
      }

      (formValues as GenericObject)[key] = inputValue;
    }
  }

  public validateStep() {
    if (!(this.currentStepIndex in this.steps)) {
      return false;
    }

    const currentStepFormGroup = this.currentStepForm.formGroup;

    if (currentStepFormGroup.invalid) {
      currentStepFormGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  public changeStep(stepAction: MenuStepAction, newStep: number) {
    const nextStep = this.currentStepIndex + 1;

    if (
      !this.isChangeStepBlocked &&
      (stepAction.success || this.validateStep()) &&
      newStep <= nextStep
    ) {
      this.newCurrentStep = newStep;
    }
  }

  public nextStep(): void {
    if (
      !this.isChangeStepBlocked &&
      this.currentStep.value < this.maxSteps &&
      this.validateStep()
    ) {
      this.menuStepActionSuccess = true;
      this.newCurrentStep = this.currentStep.value + 1;
    }
  }

  public previousStep(): void {
    if (!this.isChangeStepBlocked && this.currentStep.value > 1) {
      this.newCurrentStep = this.currentStep.value - 1;
    }
  }

  public getInputByName(name: string, byFormGroup: boolean = false) {
    if (byFormGroup) {
      return this.steps.map(step => step['formGroup']).map(formGroup => formGroup['controls']).find(control => {
        return control[name];
      })?.[name];
    }

    return this.steps.map(step => step['inputs']).flat().flat().find(input => {
      return input.name === name;
    });
  }

  public setInputByName(name: string, property: string, value: string | boolean, formValues: GenericObject) {
    const inputByName = this.getInputByName(name) as StepInput | undefined;

    if (inputByName && property in inputByName) {
      (inputByName as GenericObject)[property] = value;
      this.setFormValuesByName(formValues, name);
    }
  }

  public configureInputClasses(name: string, className: string, isToRemoveClass: boolean = false) {
    const input = this.getInputByName(name) as StepInput | undefined;

    if (input) {
      let allClasses = input.classList ?? '';

      if (isToRemoveClass) {
        allClasses = allClasses.replace(className, '');
      } else {
        allClasses += ` ${className}`;
      }

      input.classList = allClasses;
    }
  }

  public configureButtonIsLoading(type: 'button' | 'submit', isToRemoveClass: boolean = false) {
    const button = this.currentStepForm.buttons.find(button => button.type === type);

    if (button) {
      let allClasses = button.classList;

      if (isToRemoveClass) {
        allClasses = allClasses.replace('loading', '');
      } else {
        allClasses += ' loading';
      }

      button.classList = allClasses;
    }
  }

  public changeStepToError() {
    this.menuStepActions.map(stepAction => {
      stepAction.success = false;
    });

    this.newCurrentStep = 0;
  }
}
