import { Component, Input } from '@angular/core';
import { CardData } from '../../interfaces/card';
import { CardProperties } from 'src/app/shared/interfaces/card-default';

@Component({
  selector: 'app-top-most-watched',
  templateUrl: './top-most-watched.component.html',
  styleUrls: ['./top-most-watched.component.scss']
})
export class TopMostWatchedComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public properties: CardProperties;
  @Input() public topMostWatched: CardData[];

  constructor() {
    this.title = '';
    this.description = '';
    this.properties = {};
    this.topMostWatched = [];
  }
}
