import { Component, Input } from '@angular/core';
import { CheckDeviceService } from '@app/shared/services/check-device.service';
@Component({
  selector: 'app-libertadores-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() public img: String;
  public checkMobile: boolean = false;

  constructor(
    public checkDevice: CheckDeviceService
  ) {
    this.checkMobile = this.checkDevice.isMobile();
    this.img = this.checkMobile
    ? 'https://watchbr-resources.s3.amazonaws.com/conmebol/libertadores/images/libertadores_banner_interno.png'
    : 'https://watchbr-resources.s3.amazonaws.com/conmebol/libertadores/images/libertadores_banner_interno.svg';
  }
}
