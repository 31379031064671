<div class="modal-section">
    <div class="modal">
        <div class="close-wrapper">
            <img src="/assets/icons/close_small_icon.svg" alt="Close Modal Icon" class="close-modal"
                (click)="closeModal()" />
        </div>
        <p class="title">{{data?.title}}</p>
        <div class="options-wrapper">
            <app-selector-box [data]="data?.left" side="left" [selectedSide]="selectedSide"
                (click)="select('left')"></app-selector-box>
            <img src="https://watchbr-resources.s3.amazonaws.com/alacarte_v3/modal_arrow.svg" alt="Arrow"
                [ngClass]=" selectedSide == 'left' ? 'right' : 'left' " />
            <app-selector-box [data]="data?.right" side="right" [selectedSide]="selectedSide"
                (click)="select('right')"></app-selector-box>
        </div>
        <p class="description">{{data?.description}}</p>
        <div class="btns-section">
            <div class="confirm-btn" (click)="submit()">Confirme sua compra</div>
            <p class="back-txt" (click)="closeModal()">Voltar</p>
        </div>

    </div>
</div>
