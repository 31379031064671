import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-static-carousel',
  templateUrl: './promo-static-carousel.component.html',
  styleUrls: ['./promo-static-carousel.component.scss']
})
export class PromoStaticCarouselComponent {
  @Input() cards: string[] = [];
  
  constructor() {}
}
