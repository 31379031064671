import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor() {}

  public getQuestion(str: string, isMobile: boolean) {
    if (isMobile) return str;
    return str.toUpperCase();
  }

  public getFaqQuestions(mobile: boolean): any {
    return of([
      {
        question: this.getQuestion('Por que meus dados de cartão são solicitados?', mobile),
        answer: [
          'Para completar o seu cadastro,  precisaremos verificar os dados do seu cartão de crédito. Durante o período de experimentação, nenhuma cobrança será realizada. Após esse período, será realizado, mensalmente, o débito referente ao pacote de serviço contratado.',
          '<br/>',
          'Caso não queira mais continuar recebendo o serviço contratado, basta efetuar o cancelamento da sua assinatura, dentro do período de experimentação para que não ocorra nenhuma cobrança.',
          '<br/>',
          'Ao cancelar o serviço após o período de experimentação, a qualquer momento, será efetuado o débito no seu cartão de crédito, referente ao mês contratado.',
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Como eu recebo meu acesso?'
          : 'COMO EU RECEBO MEU ACESSO?',
        answer: [
          'Você receberá um e-mail com um código de voucher ou cupom para ativar a sua conta na Multi+. Ao preencher seus dados e a confirmação do cartão de crédito, você receberá o acesso através do e-mail cadastrado.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Como funciona o primeiro acesso'
          : 'COMO FUNCIONA O PRIMEIRO ACESSO?',
        answer: [
          'Para realizar o seu primeiro acesso, entre no site multimais.tv e faça o login com os dados cadastrados (e-mail e senha), validados através do seu e-mail. Você pode fazer o seu login através da Web ou pelo nosso app (disponível para iOS e Android). Depois disso, é só curtir o conteúdo disponível em nossa plataforma.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Como utilizar o voucher ou cupom'
          : 'COMO UTILIZAR O VOUCHER OU CUPOM?',
        answer: [
          `Ao receber o seu voucher, acesse multimais.tv, inclua seus dados pessoais e o código do voucher ou cupom para realizar o primeiro acesso.`
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Vou ser cobrado ao cadastrar o meu cartão de crédito'
          : 'VOU SER COBRADO AO CADASTRAR O MEU CARTÃO DE CRÉDITO?',
        answer: [
          'Não. No período de teste, quando o voucher ou o cupom estiver em utilização, não será realizado nenhum débito no cartão de crédito.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Quais são os dispositivos que posso acessar a Multi+?'
          : 'QUAIS SÃO OS DISPOSITIVOS QUE POSSO ACESSAR A MULTI+?',
        answer: [
          'O acesso pode ser realizado pelo app da Multi+ (Android e iOS) e computador/notebook.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Quais são os dispositiovs compatíveis com a Multi+?'
          : 'QUAIS SÃO OS DISPOSITIVOS COMPATÍVEIS COM A MULTI+?',
        answer: [
          '<b>Smarts TVs:</b>',
          '- Smart TV com sistema operacional Android TV a partir da versão 7 ou superior;',
          '- Smart TV Samsung com sistema operacional Tizen 2017 ou superior;',
          '- Smart TV LG com sistema operacioal WebOS 2018 ou superior.',
          '<br/>',
          '<b>Celulares e Tablets:</b>',
          '- iPhone com iOS com sistema operacional a partir da versão 9;',
          '- iPad com iOS com sistema operacional a partir da versão 9;',
          '- Smartphone Android com sistema operacional a partir da versão 8.0;',
          '- Tablet Androide com sistema operaciona a partir da versão 8.',
          '<br/>',
          '<b>STB/Portáteis:</b>',
          '- STB com sistema operacional Android TV a partir da versão 7.0 ou superior (Como: Box Elsys, Izy Play e ZTE (ML));',
          '- Fire TV Stick com FireOS baseado em Android TV a partir da versão 7.0.',
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Qual o hardware mínimo?'
          : 'QUAL O HARDWARE MÍNIMO?',
        answer: [
          'São de 10mb de espaço livre de memória. O processador recomendado é: Quadcore 1.5ghz à internet.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Em quantas telas simultâneas consigo assistir?'
          : 'EM QUANTAS TELAS SIMULTÂNEAS CONSIGO ASSISTIR?',
        answer: [
          '4 telas simultâneas com perfis distintos. Você poderá cadastrar até 8 perfis.',
          '* Nos canais da Paramount, é possível acessar até 3 dispositivos simultâneos com perfis distintos.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Como criar outros perfis para assistir?'
          : 'COMO CRIAR OUTROS PERFIS PARA ASSISTIR?',
        answer: [
          'Para criação de outros perfis em sua conta acesse a plataforma Multi+ via web, expanda o ícone do lado superior direito e clique em "Novo Perfil". Após inserir as informações do novo perfil basta clicar em "salvar".'
        ],
        showing: false
      },
      // {
      //   question: mobile
      //     ? 'Como faço o paremento do meu app na TV?'
      //     : 'COMO FAÇO O PAREAMENTO DO MEU APP NA TV?',
      //   answer: [
      //     'Nós possuímos um recurso de transmissão via Chromecast controlado pelo app da Multi+ para Android e iOS a partir da versão 2.'
      //   ],
      //   showing: false
      // },
      {
        question: mobile
          ? 'Posso baixar o aplicativo da Multi+ na minha TV?'
          : 'POSSO BAIXAR O APLICATIVO DA MULTI+ NA MINHA TV?',
        answer: [
          'Não. Atualmente estamos em desenvolvimento com a opção de download do nosso app para Android TV, Smart TV, Samsung (Tizen), LG para a plataforma webOS 2018 em diante e TV Toshiba. Em breve teremos novidades, aguarde!'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Posso baixar o conteúdo da Multi+ para assistir offline?'
          : 'POSSO BAIXAR O CONTEÚDO DA MULTI+ PARA ASSISTIR OFFLINE?',
        answer: [
          'Não. Para consumir o conteúdo da nossa plataforma, é necessário estar conectado à internet.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Qual a quantidade de conteúdo disponível na Multi+?'
          : 'QUAL A QUANTIDADE DE CONTEÚDO DISPONÍVEL NA MULTI+?',
        answer: [
          'Nossa plataforma de conteúdo já oferece mais de 30.000 horas de filmes, séries, desenhos, programas de humor, realities e muito mais!'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Quais são os estúdios parceiros da Multi+?'
          : 'QUAIS SÃO OS ESTÚDIOS PARCEIROS DA MULTI+?',
        answer: [
          'Atualmente são Paramount+, Warner, Universal, MTV, Comedy Central e Medialand.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Quanto tempo após eu efetuar a locação de um filme ele fica disponível para eu assistir?'
          : 'QUANTO TEMPO APÓS EU EFETUAR A LOCAÇÃO DE UM FILME ELE FICA DISPONÍVEL PARA EU ASSISTIR?',
        answer: ['Até 48 horas após a locação.'],
        showing: false
      },
      {
        question: mobile
          ? 'Qual o período para serem incluídos novos conteúdos na plataforma?'
          : 'QUAL O PERÍODO PARA SEREM INCLUÍDOS NOVOS CONTEÚDOS NA PLATAFORMA?',
        answer: ['Semanalmente temos novos conteúdos disponíveis na Multi+.'],
        showing: false
      },
      {
        question: mobile ? 'O que é Awesomeness' : 'O QUE É AWESOMENESS?',
        answer: [
          'Trata-se de uma família de marcas complementares, que inclui a Awesomeness TV e Awesomeness Films, com programação original e direcionada para o público da Geração Z mundial.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Existe alguma taxa de cancelamento?'
          : 'EXISTE ALGUMA TAXA DE CANCELAMENTO?',
        answer: [
          'Não há nenhuma taxa de cancelamento. Caso o débito mensal já tenha sido realizado no seu cartão de crédito, o cancelamento será a partir da próxima fatura e nesse período você poderá consumir o conteúdo da plataforma até o final do período vigente do contrato. Lembrando que não realizamos reembolso nesse caso.'
        ],
        showing: false
      },
      {
        question: mobile
          ? 'Como faço o cancelamento da assinatura na Multi+?'
          : 'COMO FAÇO O CANCELAMENTO DA ASSINATURA NA MULTI+?',
        answer: [
          'Para efetuar o cancelamento, acesse a plataforma multimais.tv pela web, no canto superior direito, siga o processo: conta > minhas assinaturas > cancelar.'
        ],
        showing: false
      }
    ]);
  }
}
